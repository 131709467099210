<template lang="html">
  <div class="ValuesBox">
    <div class="ValuesBox--Title" v-on:click="hide = !hide">
      <h2>{{getTitle()}}</h2>
    </div>
    <transition name="list">
      <div v-show="!hide">
        <div class="ValuesBox--Search" v-if="showFilterInput">
          <input type="text" v-model="text">
          <i class="fa fa-search"></i>
        </div>
        <div class="ValuesBox--Values">
          <CheckBox class="ValuesBox--ValueCheck" :text="'Seleccionar todos'" v-model="selectAll" v-on:change="selectAllOptions()"/>
          <CheckBox class="ValuesBox--ValueCheck" :text="v.value" v-model="v.selected" v-on:change="$emit('change',v)" :key="v.id" v-for="v in getValues()"/>
        </div>
        <div class="ValuesBox--Groups" v-show="getGroups().length > 0">
          <h3>{{$t('watmonitor_values_groups')}}</h3>
          <div class="ValuesBox--Values">
            <CheckBox class="ValuesBox--ValueCheck" :text="g.groupName" v-model="g.selected" v-for="g in getGroups()"/>
          </div>
        </div>
        <div class="ValuesBox--Advanced">
          <p v-on:click="$emit('openAdvancedOptions')">{{$t('watmonitor_advanced_options')}}</p>
        </div>
      </div>
    </transition>
  </div>
</template>
<script>
import Vue from 'vue'
import CheckBox from './CheckBox'
export default {
  name: 'ValuesBox',
  components: {
    CheckBox
  },
  props: {
    values:Array,
    groups: {
      type: Array,
      default: () => []
    },
    showFilterInput:{
      type: Boolean,
      default: true,
    },
    title:{
      type:String,
      default:''
    },
    hideOptions:{
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      text:'',
      hide:this.hideOptions,
      selectAll:false,
    }
  },
  methods: {
    selectAllOptions(){
      this.$emit('selectAllOptions',!this.selectAll)
    },
    getValues(){
      if(this.text == null || this.text == undefined || this.text.length == 0) return this.values
      let data = []
      for(let v of this.values){
        if (v.value.toUpperCase().includes(this.text.toUpperCase())) data.push(v)
      }
      return data
    },
    getTitle(){
      if(this.title != '') return this.title
      let string = []
      for(let v of this.values){
        if(string.length<2)string.push(v.value)
      }
      string = string.toString()
      string += "..."
      return string
    },
    getGroups(){
      return this.groups.filter(function(g,i){return !g.groupId })
    },
  },
  mounted(){
    let allSelected = true
    for(let v of this.values){
      if(!v.selected) allSelected = false
    }
    this.selectAll = allSelected
  }
}
</script>

<style lang="scss" scoped>
@import url('https://fonts.googleapis.com/css?family=Lato:400,700');

.ValuesBox{
  background: #FFFFFF;
  border: 1px solid #E1E1E1;
  box-shadow: 2px 2px 1px rgba(0, 0, 0, 0.07);
  padding-top: 15px;
  &--Title{
    position: relative;
    padding: 0 15px;
    &::before{
      border-style: solid;
      cursor: pointer;
      border-color: #404040;
      border-width: 1px 1px 0 0;
      content: '';
      display: inline-block;
      height: 7px;
      position: absolute;
      transform: rotate(-45deg);
      vertical-align: top;
      width: 7px;
      right: 15px;
      top:5px;
      transform: rotate(135deg);
      cursor: pointer;
    }
  }
  &--Search{
    position: relative;
    padding: 0 15px;
    input{
      background: #F5F5F5;
      border: 1px solid #D3D3D3;
      width: 100%;
      font-family: Roboto Condensed;
      font-weight: normal;
      font-size: 19px;
      letter-spacing: 0.01em;
      color: #969696;
      padding: 2px 10px;
      outline: none;
      &::placeholder{
        opacity: 0.6;
      }
    }
    i{
      position: absolute;
      top: 8px;
      right: 22px;
      font-size: 18px;
      color:#969696;
    }
  }
  &--Values{
    max-height: 200px;
    overflow-y: scroll;
    overflow-x: hidden;
    margin: 15px 0;
    display: flex;
    flex-direction: column;
    padding: 0 15px;
    &::-webkit-scrollbar-track{
      background-color: #e6e6e6;
    }
    &::-webkit-scrollbar {
      background: linear-gradient(90deg, #FF1564 0%, #FD7328 100%);
      width: 5px;
    }
    &::-webkit-scrollbar-thumb{
      border-radius: 20px;
      background: linear-gradient(90deg, #FF1564 0%, #FD7328 100%);
    }
  }
  &--ValueCheck{
    margin-bottom: 11px;
  }
  &--Advanced{
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    letter-spacing: 0.02em;
    text-transform: uppercase;
    color: #96C800;
    padding: 10px 16px;
    border-top: 1px solid #E0E0E0;
    p{
      margin:0;
      cursor: pointer;
      &:hover{
        font-weight: bold;
      }
    }
  }
  &--Groups{
    border-top: 1px solid #E0E0E0;
    h3{
      font-family: Lato;
      font-style: normal;
      font-weight: normal;
      font-size: 11px;
      letter-spacing: 0.02em;
      text-transform: uppercase;
      color: #939393;
      margin-top: 10px;
      padding: 0 15px;
    }
  }
  h2{
    font-family: Lato;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    letter-spacing: 0.01em;
    color: #404040;
    margin: 0 0 10px 0;
    word-break: break-word;
  }
}
.list-enter,
.list-leave-to {
  visibility: hidden;
  height: 0;
  margin: 0;
  padding: 0;
  opacity: 0;
}

.list-enter-active,
.list-leave-active {
  transition: all 0.3s;
}
</style>
