<template>
  <button
    class="CommunityButton"
    :type="btnType"
    :class="styleClasses"
    :disabled="disabled"
    :style="`background-color: ${color}; color: ${textColor}`"
    @click="handleClick( $event )">
    <slot></slot>
  </button>
</template>

<script>
  import { USER_AUTH_LOGOUT } from '@/store/user.module';
  import store                from '@/store';

  export default {
    props: {
      text: String,
      type: {
        type: String,
        default: 'primary',
        validate: function (value) {
          let allowedTypes = ['primary','EL']
          return allowedTypes.includes(value)
        }
      },
      size: {
        type: String,
        default: 'md',
        validate: function (value) {
          let allowedTypes = ['sm', 'md']

          return allowedTypes.includes(value)
        }
      },
      disabled: {
        type: Boolean,
        default: false
      },
      color: {
        type: String,
        default: '#96C800'
      },
      btnType: {
        type: String,
        default: 'button'
      },
      textColor: {
        type: String,
        default: 'white'
      }
    },

    computed: {
      styleClasses: function () {
        return {
          'Button--Primary': this.type == 'primary',
          'Button--EL': this.type == 'EL',
          'Button--Link': this.type == 'link',
          'Button--Sm': this.size == 'sm',
          'Button--Logout': this.type == 'logout',
          'Button--Save': this.type == 'save'
        }
      }
    },

    methods: {
      handleClick ( event ) {
        if ( this.type === 'logout' ) {
          store.dispatch( USER_AUTH_LOGOUT );
          this.$router.push( { name: 'login', params: { origin: this.$route.params.origin, }, } );
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
  .CommunityButton {
    padding: 30px;
    padding: .5em 1.5em;
    color: #FFF;
    font-family: 'Roboto condensed';
    font-weight: bold;
    border: none;
    display: inline-flex;
    flex-direction: row;
    align-items: center;
    transition: all 300ms;
    justify-content: center;
    cursor: pointer;
    font-size: 17px;
    box-shadow: 2px 2px 0px rgba(0, 0, 0, 0.14);
    text-align: center;
    letter-spacing: -0.01em;
    text-transform: uppercase;
    outline:none;
    position: relative;
    border-radius: 5px;
    @media only screen and (max-width: 600px) {
    width: 100%;
  }

    &:disabled {
      opacity: 0.7;
      cursor: not-allowed;
      background: gray !important;
      box-shadow: none;
    }

    &--Sm {
      font-size: 12px;
    }

    &--EL {
      cursor: pointer;
    }

    &--Save, &--Logout {
      padding: 1rem 1.5rem;
      font-size: .9rem;
      text-transform: uppercase;
    }

    &--Logout {
      flex-direction: column;
      width: 100%;
    }
  }
</style>
