<template>
  <div id="uxVideoInfoSideBar">
    <!-- <UxAnnotationMenu
      :selectedOption="selectedOption"
      @changeOption="changeOption"
    /> -->
    <!--Motion
      :key="selectedOption"
      :initial="{ opacity: 0, y: 0 }"
      :animate="{
        opacity: 1,
        y: 0,
      }"
      :exit="{ opacity: 0, y: 0 }"
      :transition="{ duration: 1, easing: 'ease-out' }"
      class="slide"
    -->
      <UxTranscription
        v-show="selectedOption == 'transcription'"
        :currentTime="currentTime"
        :transcription="transcription"
        :activityType="activityType"
        @goToSecond="goToSecond"
      />
      <!-- <UxNotes
        v-show="selectedOption == 'notes'"
        :notes="notes"
        :currentTime="currentTime"
        @goToSecond="goToSecond"
      />
      <UxUserInfo
        v-show="selectedOption == 'userInfo'"
        :device="device"
        :browser="browser"
      /> -->
    <!--/Motion-->
  </div>
</template>
<script setup>
import { ref } from 'vue'
//import { Motion, Presence } from 'motion/vue'
import UxAnnotationMenu from './UxAnnotationMenu.vue'
import UxTranscription from './UxTranscription.vue'
import UxNotes from './UxNotes.vue'
import UxUserInfo from './UxUserInfo.vue'
const props = defineProps({
  currentTime: String,
  notes: {
    type: Array
  },
  activityType:String,
  device: {
    type: String
  },
  browser: {
    type: String
  },
  transcription: {
    type: Array,
    default: null,
  }
})
const emit = defineEmits(['goToSecond'])
const selectedOption = ref('transcription')
const changeOption = (option) => {
  selectedOption.value = option
}
const goToSecond = (second) => {
  emit('goToSecond', second)
}
</script>
<style lang="scss" scoped>
#uxVideoInfoSideBar {
  width: 100%;
  height: 100%;
  background-color: #f8f8f8;
  display: flex;
  flex-direction: column;
  padding: 30px;
  border-radius: 10px;
}
</style>
