<template lang="html">
        <div class="dividepoints" v-if="question.type == 'DIVIDE_POINTS'">

          <p class="addedPoints" v-html="$t('activity_survey_divide_points_restant',{restantPoints:restantPoints,pointsNum:question.pointsNum})"></p>
          <button v-on:click="restartPoints()">{{$t('activity_survey_divide_points_restart')}}</button>
          <div class="divide-points__Wrapper">
            <div class="divide-points__input" v-for="(v,i) in question.options">
              <p class="valuename">{{v.value}}</p>
              <div class="divide-points__wrapper" v-if="answers[i]">
                <input type="range" :id="'range_'+question.identifier+'_'+(i)" data-thumbwidth="20" v-model="answers[i].points" :max="question.pointsNum" min="0" v-on:input="drag(i,question.identifier, false)">
                <output :style="{ background: theme.mainColor }" name="rangeVal" :id="'rangeValue_'+question.identifier+'_'+(i)">{{answers[i].points}}</output>
                <div class="progressDividepoints">
                  <div :style="{ background: theme.mainColor }" class="progressDividepoints--Color" :id="'progress_'+question.identifier+'_'+(i)">

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { map, flatMap } from 'lodash';

export default {
  name: 'dividePoints',

  props:{
    question:{
      type:Object,
    }
  },

  data(){
    return{
      addedPoints:0,
      restantPoints:0,
      answers:[],
    }
  },

  async mounted(){
    this.answers = _.map(this.question.options, function (o, i) {
      let data = {points : 0, option:parseInt(i)}
      return data
    })
    this.restantPoints = this.question.pointsNum
  },

  methods: {
    restartPoints: async function(){
      this.answers = []
      this.answers = _.map(this.question.options, function (o, i) {
        let data = {points : 0, option:parseInt(i)}
        return data
      })
      for (let i in this.question.options) {
        await this.drag(parseInt(i),this.question.identifier,true)
      }
    },
    drag: async function(ansId,qindex, restart){
      this.addedPoints = 0
      this.restantPoints = this.question.pointsNum
      for (let ans of this.answers) {
         this.addedPoints = this.addedPoints + parseInt(ans.points)
      }
      this.restantPoints = this.restantPoints - this.addedPoints
      if (this.addedPoints >=this.question.pointsNum) {
        this.restantPoints = 0
        let newValue = this.addedPoints - this.question.pointsNum
        this.answers[ansId].points = parseInt(this.answers[ansId].points - newValue)
        this.addedPoints = this.question.pointsNum
        let control =  $('#range_'+this.question.identifier+'_'+ansId)
        let  controlMin = control.attr('min')
        let  controlMax = control.attr('max')
        let  controlVal = this.answers[ansId].points
        let  controlThumbWidth = control.data('thumbwidth')

        let range = controlMax - controlMin;

        let position = ((controlVal - controlMin) / range) * 100;
        let positionOffset = Math.round(controlThumbWidth * position / 100) - (controlThumbWidth / 2);
        let output = $('#rangeValue_'+this.question.identifier+'_'+ansId)

        output.css('left', 'calc(' + position + '% - ' + positionOffset + 'px)')
        output.text(controlVal);
        positionOffset += 8
        $('#progress_'+this.question.identifier+'_'+ansId).css({"width": 'calc(' + position + '% - ' + positionOffset + 'px)'})
      }
      else{
        let control =  $('#range_'+this.question.identifier+'_'+ansId)
        let  controlMin = control.attr('min')
        let  controlMax = control.attr('max')
        let  controlVal = control.val()
        let  controlThumbWidth = control.data('thumbwidth')

        let range = controlMax - controlMin;

        let position = ((controlVal - controlMin) / range) * 100;
        let positionOffset = Math.round(controlThumbWidth * position / 100) - (controlThumbWidth / 2);
        let output = $('#rangeValue_'+this.question.identifier+'_'+ansId)
        if (restart) {
          position = 0;
          positionOffset = -10;
          controlVal = 0
        }
        output.css('left', 'calc(' + position + '% - ' + positionOffset + 'px)')
        output.text(controlVal);
        positionOffset += 8
        $('#progress_'+this.question.identifier+'_'+ansId).css({"width": 'calc(' + position + '% - ' + positionOffset + 'px)'})
      }
      let answerValid = true
      if (this.addedPoints == this.question.pointsNum){
        this.$emit('send-answers', {answer:this.answers,question:this.question,valid:true})
      }
      else{this.$emit('send-answers', {answer:this.answers,question:this.question, valid:false})
      }

    },




  },
}
</script>

<style lang="scss" scoped>
.dividepoints{
  button{
    border: none;
    background: none;
    padding: 10px 0;
    color: gray;
    cursor: pointer;
    outline: none;
    &:hover{
      color: var(--primary-color)
    }
  }
  .range-control {
    position: relative;
  }
  .valuename{
    margin:15px 0;
  }
  .addedPoints{
    background: #f6f6f6;
    color: black;
    text-transform: uppercase;
    padding: 2px 5px;
  }
  //
  input[type=range] {
    outline: none;
    -webkit-appearance: none;
    -ms-appearance: none;
    margin: 18px 0;
    width: 100%;
    position: relative;
    z-index: 3;
  }
  //
  input[type=range]::-ms-tooltip{
      display:none;
  }
  input::-ms-track {
    width: 100%;
    cursor: pointer;
    animate: 0.2s;
    height: 0px;
  }
  //
  input::-ms-thumb {
      height: 25px;
      width: 25px;
      border-radius: 25px;
      margin-top:-20px;
      background: transparent;
      cursor: pointer;
      -webkit-appearance: none;
  }
  input[type=range]::-webkit-slider-runnable-track{
    width: 100%;
    cursor: pointer;
    animate: 0.2s;
    height: 0px;
  }
  //
  input[type=range]::-webkit-slider-thumb {
    height: 25px;
    width: 25px;
    border-radius: 25px;
    margin-top:-20px;
    border:none;
    background: transparent;
    cursor: pointer;
    -webkit-appearance: none;
    -ms-appearance : none;
  }

  output {
    position: absolute;
    top: 0px;
    left: calc(0% - -10px);
    color: white;
    display: flex!important;
    align-items: center!important;
    justify-content: center!important;
    transform: translateX(-50%);
    text-align: center;
    font-size: 12px;
    font-weight: bold;
    z-index: 2;
    height: 25px;
    width: 25px;
    border-radius: 25px;
    margin-top:-8px;
    cursor: pointer;
  }
  //
  input[type=range]:active + output {
    display: block;
    transform: translateX(-50%);
  }
  .divide-points{
    display: flex;
    flex-direction: column;
    align-items: baseline;

    // &__Wrapper{
    //   display: flex;
    //   flex-wrap: wrap;
    //   align-items: flex-end;
    // }
    &__wrapper{
      position:relative;
      .progressDividepoints{
        height: 6px;
        width: 100%;
        background: #f6f6f6;
        z-index:1;
        position: absolute;
        top: 0px;
        border-radius: 6px;
        &--Color{
          height: 6px;
          width: 0;
          border-radius: 6px;
        }
      }
    }
    &__input{
      width: 100%;
    }
  }
}
</style>
