<template lang="html">
  <div class="MonitorPage">
    <h1 style="font-size: 25px;line-height: 36px;">{{$t('monitor_results_text')}}</h1>
    <div class="MonitorHeader">
      <div class="MonitorFilters" :key="formatedFiltersKey">
        <h1 v-show="getFilterFormated().length > 0">{{$t('monitor_applied_filter')}}</h1>
        <FilterTag :text="f.text" v-for="f in getFilterFormated()" :allowDelete="true" :allowEdit="true" v-on:remove="removeFilter(f)" v-on:edit="editFilter(f)"/>
      </div>
      <div class="MonitorDownloads" v-on:click="fetchActivityExcel()">
        <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M9.37019 0.6531C9.24906 0.553225 9.08862 0.511787 8.9335 0.540475L0.4335 2.13423C0.181687 2.18097 0 2.39985 0 2.65591V14.3434C0 14.5984 0.181687 14.8183 0.4335 14.8651L8.9335 16.4588C8.96537 16.4652 8.99938 16.4684 9.03125 16.4684C9.15344 16.4684 9.27456 16.4259 9.37019 16.3462C9.49238 16.2453 9.5625 16.0944 9.5625 15.9372V1.06216C9.5625 0.90385 9.49238 0.754037 9.37019 0.6531ZM8.5 15.2965L1.0625 13.9025V3.09685L8.5 1.70285V15.2965Z" fill="#939393"/>
          <path d="M16.4688 2.65591H9.03125C8.738 2.65591 8.5 2.89391 8.5 3.18716C8.5 3.48041 8.738 3.71841 9.03125 3.71841H15.9375V13.2809H9.03125C8.738 13.2809 8.5 13.5189 8.5 13.8122C8.5 14.1054 8.738 14.3434 9.03125 14.3434H16.4688C16.762 14.3434 17 14.1054 17 13.8122V3.18716C17 2.89391 16.762 2.65591 16.4688 2.65591Z" fill="#939393"/>
          <path d="M11.1562 4.78091H9.03125C8.738 4.78091 8.5 5.01891 8.5 5.31216C8.5 5.60541 8.738 5.84341 9.03125 5.84341H11.1562C11.4495 5.84341 11.6875 5.60541 11.6875 5.31216C11.6875 5.01891 11.4495 4.78091 11.1562 4.78091Z" fill="#939393"/>
          <path d="M11.1562 6.90591H9.03125C8.738 6.90591 8.5 7.14391 8.5 7.43716C8.5 7.73042 8.738 7.96842 9.03125 7.96842H11.1562C11.4495 7.96842 11.6875 7.73042 11.6875 7.43716C11.6875 7.14391 11.4495 6.90591 11.1562 6.90591Z" fill="#939393"/>
          <path d="M11.1562 9.03093H9.03125C8.738 9.03093 8.5 9.26893 8.5 9.56218C8.5 9.85543 8.738 10.0934 9.03125 10.0934H11.1562C11.4495 10.0934 11.6875 9.85543 11.6875 9.56218C11.6875 9.26893 11.4495 9.03093 11.1562 9.03093Z" fill="#939393"/>
          <path d="M11.1562 11.1559H9.03125C8.738 11.1559 8.5 11.3939 8.5 11.6872C8.5 11.9804 8.738 12.2184 9.03125 12.2184H11.1562C11.4495 12.2184 11.6875 11.9804 11.6875 11.6872C11.6875 11.3939 11.4495 11.1559 11.1562 11.1559Z" fill="#939393"/>
          <path d="M14.3438 4.78091H13.2812C12.988 4.78091 12.75 5.01891 12.75 5.31216C12.75 5.60541 12.988 5.84341 13.2812 5.84341H14.3438C14.637 5.84341 14.875 5.60541 14.875 5.31216C14.875 5.01891 14.637 4.78091 14.3438 4.78091Z" fill="#939393"/>
          <path d="M14.3438 6.90591H13.2812C12.988 6.90591 12.75 7.14391 12.75 7.43716C12.75 7.73042 12.988 7.96842 13.2812 7.96842H14.3438C14.637 7.96842 14.875 7.73042 14.875 7.43716C14.875 7.14391 14.637 6.90591 14.3438 6.90591Z" fill="#939393"/>
          <path d="M14.3438 9.03093H13.2812C12.988 9.03093 12.75 9.26893 12.75 9.56218C12.75 9.85543 12.988 10.0934 13.2812 10.0934H14.3438C14.637 10.0934 14.875 9.85543 14.875 9.56218C14.875 9.26893 14.637 9.03093 14.3438 9.03093Z" fill="#939393"/>
          <path d="M14.3438 11.1559H13.2812C12.988 11.1559 12.75 11.3939 12.75 11.6872C12.75 11.9804 12.988 12.2184 13.2812 12.2184H14.3438C14.637 12.2184 14.875 11.9804 14.875 11.6872C14.875 11.3939 14.637 11.1559 14.3438 11.1559Z" fill="#939393"/>
          <path d="M7.30666 10.2751L3.58791 6.0251C3.39241 5.80304 3.05773 5.78179 2.83779 5.97516C2.61679 6.16854 2.59448 6.50429 2.78785 6.72423L6.5066 10.9742C6.61179 11.0943 6.75841 11.1559 6.9061 11.1559C7.03042 11.1559 7.15473 11.1124 7.25673 11.0242C7.47773 10.8308 7.50004 10.4961 7.30666 10.2751Z" fill="#939393"/>
          <path d="M7.23282 5.4248C7.0012 5.24311 6.66757 5.28668 6.48695 5.51724L2.7682 10.2985C2.58863 10.5301 2.63007 10.8648 2.8617 11.0444C2.95945 11.1198 3.0742 11.1559 3.18788 11.1559C3.34513 11.1559 3.50238 11.0858 3.60651 10.9519L7.32526 6.17067C7.50588 5.93799 7.46445 5.60436 7.23282 5.4248Z" fill="#939393"/>
        </svg>
        <p>{{$t('monitor_export_excel')}}</p>
      </div>
    </div>
    <div v-for="(q,i) in report.questions" :key="q.questionKey" :class="{'MonitorQuestion--separate':q.taskNum == -1 && q.questionId == 1}">
      <div :id="'MonitorQuestion--'+(i)" class="MonitorQuestion">
        <Chart
          :id="'WatChart-'+i"
          :ref="'WatChart'"
          :test="test"
          :question="q"
          :index="i+1"
          :filters="globalFilters"
          :reportGlobalFilters="report.globalFilters"
          :questionsToFilter="questionsToFilter"
          :personalization="personalization"
          :nielsen="nielsen"
          :regions="regions"
          :allowComments="false"
          :showSocioDemographics="false"
          :modalCreateInstance="true"
          :isQuestionLoaded="true"
          v-on:changePage="changeChartPage({event:$event,chartIndex:i,showLoaderTimes:1})"
          v-on:fetchQuestion="fetchQuestion($event,i,1)"/>
      </div>
    </div>
  </div>
</template>

<script>
import Swal from 'sweetalert2'
import { inject, onMounted } from "vue";
import {mount} from '@/utils'
import vueApp from '@/main';
import {defineComponent,createApp} from 'vue'
import { mapGetters, mapMutations  } from 'vuex';
import store from '@/store';
import Chart from '~/components/WatMonitor/Chart';
import QuestionAdvancedModal from '~/components/WatMonitor/QuestionAdvancedModal'
import MonitorAllFiltersModal from '~/components/WatMonitor/MonitorAllFiltersModal'
import FilterTag from '~/components/WatMonitor/FilterTag'
import LoadingModal from '~/components/WatMonitor/LoadingModal'
import FileSaver from 'file-saver';

import {
  MISC_FETCH_COUNTRY_CODE,
  MISC_FETCH_PROVINCES,
  MISC_FETCH_REGIONS,
  MISC_FETCH_NIELSEN,
} from '@/store/misc/provinces.module';
import {
  FETCH_TEST,
  FETCH_PUBLIC_FILTERS_DASHBOARD,
  FETCH_MONITOR_REPORT_BASIC,
  FETCH_CLIENT_DASHBOARD_TABLE,
  FETCH_CLIENT_DASHBOARD_TEXT,
  SAVE_MONITOR_REPORT
} from '@/store/monitor.module';

import {
  COMMUNITY_FETCH_ACTIVITY_EXCEL,
} from '@/store/community/activities.module';
export default {
  name: 'Monitor',

  components: {
    Chart,
    MonitorAllFiltersModal,
    QuestionAdvancedModal,
    FilterTag,
    LoadingModal

  },
  props:{
    testId:Number,
    dashboardLink:String,
  },
  computed: {
    ...mapGetters([
      'communityCurrentActivityId',
    ]),
  },
  data(){
    return{
      formatedFiltersKey:0,
      globalFilters:[],
      questionsToFilter:[],
      filtersQuestion:[],
      regions:[],
      provinces:[],
      nielsen:[],
      test:{},
      report:{},
      generatedQuestionsNum:0,
      showLoadingModal:false,
      LoadingModalInstance:{},
      MonitorAllFiltersModalInstance: null,
      MonitorAdvancedOptionsInstance: null,
      showAdvancedModal:false,
      personalization:{
        font:'Roboto condensed'
      },
      hash:"",
    }
  },

  methods:{
    fetchActivityExcel: async function(){
      var vm = this;
      Swal.fire({
        title: vm.$t('msg_confirmation'),
        text: vm.$t('msg_file_download'),
        icon: 'warning',
        cancelButtonText: vm.$t('action_cancel'),
        confirmButtonText: vm.$t('action_accept'),
        showCancelButton: true,
      }).then((result) => {
        if (result.isConfirmed) {
          (async () => {
            await store.dispatch(COMMUNITY_FETCH_ACTIVITY_EXCEL,{activityId:vm.communityCurrentActivityId})
            .then((data) => {
              var file = new Blob([data],  {type:'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'});
              FileSaver.saveAs(file,vm.$t('activity')+" "+vm.communityCurrentActivityId+".xlsx");
            })
          })();
        }
      })
    },
    async fetchTest(){
      await store.dispatch(FETCH_TEST, { testId: this.hash })
      .then((data) => {
        for(let t of data.testTasks){
          for (let q of t.questions) {
            q.text = q.text.replace(/(<([^>]+)>)/ig, '');
            q.text = q.text.replace(/(&.*;)/, '');
          }
        }
        this.test = data
      })
    },
    async fetchFilterQuestions(){
      await store.dispatch(FETCH_PUBLIC_FILTERS_DASHBOARD, { testId: this.hash })
      .then((data) => {
        let filtersData = data
        let questions = []
        for (var i = 0; i < filtersData.length; i++) {
          for (var x = 0; x < filtersData[i].questions.length; x++) {
            filtersData[i].questions[x].taskNum = filtersData[i].taskNum
            questions.push(filtersData[i].questions[x])
          }
        }
        for (var i = 0; i < questions.length; i++) {
          questions[i].text = questions[i].text.replace(/(<([^>]+)>)/ig, '');
          questions[i].text = questions[i].text.replace(/(&.*;)/, '');
          for(let v of questions[i].values){
            v.selected = false
          }
          this.filtersQuestion.push({questionText:questions[i].text,questionNum:questions[i].id,taskNum:questions[i].taskNum,values:questions[i].values})
        }
        this.questionsToFilter = JSON.parse(JSON.stringify(questions))
      })
    },
    async fetchBasicReport(){
      await store.dispatch(FETCH_MONITOR_REPORT_BASIC,{testId:this.hash})
      .then(({data}) => {
        this.report = data
        this.setGlobalFilters()
        this.$emit('send-monitor-questions',this.report.questions)
      })
      .catch(({response}) => {
        Swal.fire("",this.$t('monitor_error'),"error")
      })
      let index = 0
      for(let q of this.report.questions){
        q.questionText = q.questionText.replace( /(<([^>]+)>)/ig, '')
        if(q.questionType == 'NUMBER') q.ranges = [{from:q.numMin!= undefined ? q.numMin : 0,to:q.numMax != undefined ? q.numMax : 100}]
        if(q.graphType == "LIST") q.page = 0
        this.fetchQuestionService({question:q,chartIndex:index,showLoaderTimes:this.report.questions.length})
        index ++
      }
    },
    async fetchQuestionService(e){
      if(e.question.graphType == "LIST") await this.fetchList(e.question, e.question.page != undefined ? e.question.page : 0 , 10)
      else if(e.question.questionType != "LABEL") await this.fetchChart(e.question)
      this.generateChart(e.chartIndex,e.showLoaderTimes)
    },
    async fetchChart(q){
      await store.dispatch(FETCH_CLIENT_DASHBOARD_TABLE, {testId:this.hash,data:q})
      .then((result) => {
        if(result == undefined) {
          Swal.fire("",this.$t('monitor_error_2'),"warning")
          q.chart = {values: [],xLabels: [],xTotals: [],yLabels: [],yTotals: []}
        }
        else q.chart = result
      })
    },
    async fetchList(q, page, pageSize){
      q.page = page;
      q.pageSize = pageSize;
      await store.dispatch(FETCH_CLIENT_DASHBOARD_TEXT, {testId:this.hash,data:q})
      .then((result) => {
        if(result == undefined) Swal.fire("",this.$t('monitor_error_2'),"warning")
        else q.chart = result
      })
    },
    async changeChartPage(event){
      await this.fetchList(event.event.question, event.event.page, 10)
      this.generateChart(event.chartIndex,1)
    },
    generateChart(index,showLoaderTimes){
      this.$refs.WatChart[index].generate()
      this.generatedQuestionsNum++
      this.showLoadingModal = !(showLoaderTimes == this.generatedQuestionsNum)
      if(!this.showLoadingModal){
        this.LoadingModalInstance.destroy()
        // this.LoadingModalInstance.$el.remove()
        this.generatedQuestionsNum = 0
      }
    },
    getProvincesByParentId(id){
      let provinces = []
      for(let p of this.provinces){
        if(p.parentId == id) provinces.push(p)
      }
      return provinces
    },
    getProvincesByNielsenId(id){
      let provinces = []
      for(let p of this.provinces){
        if(p.nielsenId == id) provinces.push(p)
      }
      return provinces
    },
    getQuestionByIdAndTaskNum(qId,tNum){
      if(tNum == -1){
        if(qId == 3) return {taskNum:-1,questionNum:3,values:[{id:0,value:"Hombre", selected:false},{id:1,value:"Mujer",selected:false}]}
        else if(qId == 1) return {taskNum:-1,questionNum:1,values:JSON.parse(JSON.stringify(this.provinces))}
        else return {}
      }
      for(let t of this.test.testTasks){
        if(tNum == t.taskNum){
          for(let q of t.questions){
            if(q.id == qId){
              return q
            }
          }
        }
      }
      return {}
    },
    async saveReport(){
      let report = {
        questions:this.report.questions,
        globalFilters:this.getGlobalFilters(this.filtersQuestion)
      }
      await store.dispatch(SAVE_MONITOR_REPORT, { data:report, testId:this.hash, reportId:this.report.identifier })
      .then((data) => {
        Swal.fire({
          title: this.$t('monitor_msg_success'),
          text: this.$t('monitor_msg_success_text'),
          icon: "success",
          showConfirmButton: true,
          timer: 2000,
          customClass:{
            popup:'swal-customSuccess'
          }
        })
      })
    },
    getGlobalFilters(filters){
      let data = []
      for(let f of filters){
        let y = {taskNum : f.taskNum, questionNum: f.questionNum}
        if(f.values){
          y.values = []
          for(let v of f.values){
            if(v.selected) y.values.push(v.id)
          }
        }
        if(f.groups){
          y.groups = []
          for(let g of f.groups){
            if(g.selected)y.groups.push({groupName:g.groupName, values:g.values.map(function(v,i){return v.id})})
          }
        }
        if(f.ranges && f.ranges.length > 0) y.ranges = f.ranges
        if((y.values && y.values.length > 0) || y.ranges || (y.groups && y.groups.length > 0)){
          data.push(y)
        }
      }
      return data
    },
    fetchQuestion(event,chartIndex,showLoaderTimes){
      this.showLoadingModal = true
      if(showLoaderTimes == 1) this.loadingModalTitle = this.$t('monitor_modal_loading_chart')
      else this.loadingModalTitle = this.$t('monitor_modal_loading_report')
      let xDimensions = this.generateXDimensions(event.chartType,event.question,event.values,event.groups,event.value)
      let yDimensions = this.generateYDimensions(event.chartType,event.question)
      event.question.resultType = this.getResultType(event.chartType,event.question)
      event.question.xDimensions = xDimensions
      event.question.yDimensions = yDimensions != null ? yDimensions : []
      if(this.filtersQuestion.length > 0) this.generateFilters(this.filtersQuestion,event)
      if(this.$refs.WatChart[chartIndex].questionFilters.length > 0) this.generateFilters(this.$refs.WatChart[chartIndex].questionFilters,event)
      if(event.question.graphType == 'LIST'){
        let textDimension = {taskNum:event.question.xDimensions[0].taskNum,questionNum:event.question.xDimensions[0].questionNum,text:''}
        if(event.question.textDimension) textDimension.text = event.question.textDimension.text
        event.question.textDimension = textDimension
      }
      if(event.question.axisSwitched){
        let y = event.question.xDimensions
        let x = event.question.yDimensions
        event.question.xDimensions = x
        event.question.yDimensions = y
      }
      this.fetchQuestionService({chartType:event.chartType,question:event.question, chartIndex:chartIndex,showLoaderTimes:showLoaderTimes})
    },
    saveReportFilters(){
      this.report.globalFilters = this.filtersQuestion
      this.showLoadingModal = true
      this.createLoadingModalInstance()
      for(let i in this.report.questions){
       this.fetchQuestion({chartType:this.$refs.WatChart[i].chartType,question:this.report.questions[i]}, i, this.report.questions.length)
      }
    },
    getResultType(chart,question){
      let questionType = question.questionType
      if(chart == "VALUE_TABLE") return "VALUE"
      if(chart == "AVERAGE_TABLE") return "AVERAGE"
      if(chart == "PERCENT_TABLE") return "PERCENT_TOTAL"
      if(chart == "COLUMN" || chart == "PIE" || chart == "STACKED_BARS"){
        if(questionType == "SELECTION" || questionType == "MULTISELECTION" || questionType == "IMAGE_CHOICE" || questionType == "MULTIPLEIMAGE_CHOICE" || questionType == "INTERNAL_VARIABLE" || questionType == "SORTING" || questionType == "NUMBER" || questionType == "DECIMAL_NUMBER" || questionType == "MULTINUMBER" || questionType == "MATRIX") return "PERCENT_XAXIS"
        if(questionType == "DIVIDE_POINTS") return "AVERAGE"
        return "VALUE"
      }
      if((questionType == "RANKING" || questionType == "RATING_FACES" || questionType == "SORTING" || questionType == "DIVIDE_POINTS" || questionType == "NUMBER" || questionType == "DECIMAL_NUMBER" || questionType == "MATRIX" || questionType == "MULTINUMBER") && chart == "AVERAGE") return chart
      if(questionType == "TEXT" || questionType == "TEXTAREA" || questionType == "MULTITEXT" || questionType == "DATE" || questionType == "IMAGE_URL") return chart
    },
    generateFilters(filters,event){
      for(let f of filters){
        let y = {taskNum : f.taskNum, questionNum: f.questionNum}
        if(f.values){
          y.values = []
          for(let v of f.values){
            if(v.selected) y.values.push(v.id)
          }
        }
        if(f.groups){
          y.groups = []
          for(let g of f.groups){
            if(g.selected)y.groups.push({groupName:g.groupName, values:g.values.map(function(v,i){return v.id})})
          }
        }
        if(f.ranges && f.ranges.length > 0) y.ranges = f.ranges
        if((y.values && y.values.length > 0) || y.ranges || (y.groups && y.groups.length > 0)){
          if(event.chartType == "PIE") event.question.xDimensions.push(y)
          else event.question.yDimensions.push(y)
        }
      }
    },
    generateXDimensions(chart, question, values, groups, value){
      let questionType = question.questionType
      let data = [{taskNum:question.taskNum, questionNum:Math.abs(parseInt(question.questionId))}]
      if(questionType == "MATRIX" || questionType == "DIVIDE_POINTS") data[0].opt = "all"
      if(questionType == "MATRIX" && chart == 'AVERAGE') data[0].opt = 'average'
      if(questionType == "MATRIX" && question.matrixType == 'RADIO_BUTTONS') data[0].opt = "matrix"
      if((questionType == "RANKING" || questionType == "RATING_FACES") && chart == "AVERAGE"){
        data[0].opt = "ranking-average"
      }
      if(questionType == "SORTING" && chart == "AVERAGE") data[0].opt = "sorting-estimated"
      if(questionType == "SORTING" && (chart == "STACKED_BARS" || chart == "COLUMN" || chart == "VALUE_TABLE" || chart == "PERCENT_TABLE")) data[0].opt = "all"
      if((questionType == "NUMBER" || questionType == "DECIMAL_NUMBER") && chart == "AVERAGE") data[0].opt = "number-average"
      if((questionType == "NUMBER" || questionType == "DECIMAL_NUMBER" || questionType == "MULTINUMBER") && chart != "AVERAGE" && chart != "LIST") data[0].ranges = question.ranges
      if(questionType == "MULTINUMBER" && chart != "AVERAGE" && chart != "LIST"){
        data[0].opt = value
      }
      if(questionType == "MULTINUMBER" && chart == "AVERAGE"){
        data[0].opt = "number-average-"+value
      }
      if(values && values.length > 0) {
        data[0].values = []
        for(let v of values){
          if(v.selected) data[0].values.push(v.id)
        }
      }
      if(groups && groups.length > 0){
        data[0].groups = []
        for(let g of groups){
          data[0].groups.push({groupName:g.groupName,values:g.values.map(function(v,i){return v.id})})
        }
      }
      return data
    },
    generateYDimensions(chart,question){
      let questionType = question.questionType
      let data = [{taskNum:question.taskNum, questionNum:Math.abs(parseInt(question.questionId))}]
      if(questionType == "MATRIX" && chart != "AVERAGE" && question.matrixType == 'REGULAR'){
        data[0].opt = "matrix"
        return data
      }
      if(questionType == "MATRIX" && chart == "AVERAGE"){
        data[0].opt = "all"
        return data
      }
      if(questionType == "MATRIX" && question.matrixType == 'RADIO_BUTTONS'){
        data[0].opt = "all"
        return data
      }
      if(questionType == "SORTING" && chart == "AVERAGE"){
        data[0].opt = "estimated"
        return data
      }
      if(questionType == "SORTING" && (chart == "STACKED_BARS" || chart == "COLUMN" || chart == "VALUE_TABLE" || chart == "PERCENT_TABLE")){
        data[0].opt = "sorting"
        return data
      }
      if(questionType == "DIVIDE_POINTS"){
        data[0].opt = "average"
        return data
      }
      return null
    },
    setGlobalFilters(){
      for(let f of this.report.globalFilters){
        for(let q of this.questionsToFilter){
          if(f.taskNum == q.taskNum && f.questionNum == q.id){
            let filterToAdd = {questionText:q.text,questionNum:q.id,taskNum:q.taskNum,values:JSON.parse(JSON.stringify(q.values))}
            for(let v of filterToAdd.values){
              if (f.values.includes(v.id)) v.selected = true
            }
            if(f.groups){
              filterToAdd.groups = []
                for(let g of f.groups){
                  let group = {groupName:g.groupName,selected:g.selected != undefined ? g.selected : true,values:[] }
                  for(let v of q.values){
                    if(g.values.includes(v.id)) group.values.push(v)
                  }
                  filterToAdd.groups.push(group)
                }
            }
            for(let i in this.filtersQuestion){
              if(this.filtersQuestion[i].questionNum == filterToAdd.questionNum && this.filtersQuestion[i].taskNum == filterToAdd.taskNum){
                this.filtersQuestion[i] = filterToAdd
              }
            }
          }
        }
      }
      this.formatedFiltersKey++
    },
    getFilterFormated(){
      let data = []
      for(let f of this.filtersQuestion){
        let added = false
        for(let v of f.values){
          if(v.selected) added = true
        }
        let text = f.questionNum + ". " + f.questionText
        if(added)data.push({text:text, questionNum:f.questionNum, taskNum:f.taskNum, isQuestion:true})
      }
      return data
    },
    getFilteredRealQuestion(filter){
      for(let q of this.questionsToFilter){
        if(filter.taskNum == q.taskNum && filter.questionNum == q.id) return q
      }
    },
    editFilter(filter){
      let filterToEdit = {}
      for(let i in this.filtersQuestion){
        if(this.filtersQuestion[i].questionNum == filter.questionNum) filterToEdit = this.filtersQuestion[i]
      }
      this.openAdvancedOptions({filter:filterToEdit},null)
    },
    removeFilter(filter){
      for(let i in this.filtersQuestion){
        if(this.filtersQuestion[i].questionNum == filter.questionNum && this.filtersQuestion[i].taskNum == filter.taskNum){
          this.filtersQuestion[i].values = this.filtersQuestion[i].values.map(function(v,i){
            v.selected = false
            return v
          })
          this.filtersQuestion[i].groups = []
        }
      }
      this.saveReportFilters()
    },
    openFiltersModal(){
      const { el, vNode, destroy } = mount(MonitorAllFiltersModal, {  props: { filtersQuestion: this.filtersQuestion, questionsToFilter: this.questionsToFilter },app: vueApp})
      vueApp._container.appendChild(el);
      this.MonitorAllFiltersModalInstance = {
        el: el,
        vNode: vNode,
        destroy: destroy
      }
    },
    openAdvancedOptions(event,filtersModalInstance){
      const { el, vNode, destroy } = mount(QuestionAdvancedModal, {
        props: {
          breadcrumbs: filtersModalInstance!=null,
          breadcrumbsList:[{title:'Filtros'}],
          title: 'Opciones avanzadas',
          subTitle: this.getFilteredRealQuestion(event.filter).id+'. '+ this.getFilteredRealQuestion(event.filter).text,
          filter:event.filter
        },
        app: vueApp
      })
      vueApp._container.appendChild(el);
      this.MonitorAdvancedOptionsInstance = {
        el: el,
        vNode: vNode,
        destroy: destroy
      }
    },
    createLoadingModalInstance(){
      const { el, vNode, destroy } = mount(LoadingModal,
            {
                props: {
                  title:this.loadingModalTitle
                 },
                 app: vueApp
            },
      )
      vueApp._container.appendChild(el);
      this.LoadingModalInstance = {
        el: el,
        vNode: vNode,
        destroy: destroy
      }
    },
  },
  async mounted(){
    const emitter = inject("emitter")
    let eventFilters = {}
    emitter.on("close-monitor-filters-modal", (event) => {
      this.MonitorAllFiltersModalInstance.destroy()
      this.MonitorAllFiltersModalInstance = null
    })
    emitter.on("save-monitor-filters-modal", (event) => {
      this.filtersQuestion = event
      this.saveReportFilters()
      this.MonitorAllFiltersModalInstance.destroy()
      this.MonitorAllFiltersModalInstance = null
    })
    emitter.on("open-advanced-options", (event) => {
      eventFilters = event
      this.openAdvancedOptions(eventFilters,this.MonitorAllFiltersModalInstance)
      this.MonitorAllFiltersModalInstance.el.style.visibility = 'hidden'
    })

    emitter.on("close-monitor-advanced-filters-modal", (event) => {
      if(this.MonitorAdvancedOptionsInstance != null){
        this.MonitorAdvancedOptionsInstance.destroy()
        this.MonitorAdvancedOptionsInstance = null
        if(this.MonitorAllFiltersModalInstance != null ) this.MonitorAllFiltersModalInstance.el.style.visibility = 'visible'
      }
    })
    emitter.on("lastStep-monitor-advanced-filters-modal", (event) => {
      if(this.MonitorAdvancedOptionsInstance != null){
        this.MonitorAdvancedOptionsInstance.destroy()
        this.MonitorAdvancedOptionsInstance = null
        if(this.MonitorAllFiltersModalInstance != null ) this.MonitorAllFiltersModalInstance.el.style.visibility = 'visible'
      }
    })
    emitter.on("save-monitor-advanced-filters-modal", (event) => {
      if(this.MonitorAdvancedOptionsInstance != null){
        eventFilters.filter.values = event.values
        eventFilters.filter.groups = event.groups
        this.MonitorAdvancedOptionsInstance.destroy()
        this.MonitorAdvancedOptionsInstance = null
        if(this.MonitorAllFiltersModalInstance != null ) this.MonitorAllFiltersModalInstance.el.style.visibility = 'visible'
      }
    })

    this.hash = this.dashboardLink
    await this.fetchTest();
    await this.fetchFilterQuestions();
    this.createLoadingModalInstance()
    await this.fetchBasicReport();
  }
}
</script>

<style lang="scss">

</style>
<style scoped lang="scss">
.MonitorHeader{
  display: flex;
  justify-content: space-between;
}
.MonitorDownloads{
  display: flex;
  align-items: center;
  cursor: pointer;
  svg{
    margin-right: 5px;
  }
  p{
    text-transform: uppercase;
    font-size: 11px;
    color: #939393;
  }
}
.MonitorQuestion{
  margin: 25px 0px 25px 0px;
  border-bottom: 1px solid #E0E0E0;
}
.MonitorFilters{
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  h1{
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 11px;
    letter-spacing: 0.02em;
    text-transform: uppercase;
    color: #939393;
    margin: 0 10px 5px 0;
  }
}
</style>
