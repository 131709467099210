<template lang="html">
  <div class="LoadingModal">
    <div class="LoadingModal--Box">
      <h1>{{title}}</h1>
      <div class="loading">
        <div :class="'loading-'+x" v-for="x in 6">
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Vue from 'vue'

export default {
  name: 'LoadingModal',
  props: {
    title:{
      type: String,
      default:'Generando informe...',
    }
  }
}
</script>

<style lang="scss" scoped>
@use "sass:math";
@import url('https://fonts.googleapis.com/css?family=Lato:400,700');
.LoadingModal{
  position: fixed;
  width: 100%;
  height: 100%;
  top:0;
  left:0;
  background: rgba(0,0,0,0.35);
  z-index: 999;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: progress;
  h1{
    letter-spacing: 0.01em;
    color: #404040;
    margin: 0 0 9px 0;
    font-size: 19px;
    font-family: 'Roboto Condensed';
    text-transform: uppercase;
    font-weight:bold;
  }
  &--Box{
    background: #FFFFFF;
    padding: 20px 30px;
    width: 350px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}

.loading {
  height: 120px;
  width: 215px;
  border-left:1px solid #FF1564;
  border-bottom:1px solid #FF1564;
  padding: 10px;
  margin-top:30px;
}
@keyframes loading {
  0% { background-color: #8134ff; }

  30% { background-color: #FF1564; }
  50% { height: 100px; margin-top: 0px; }
  80% { background-color: #FD7328;  }
  100% { background-color: #361466; }
}
/*@-moz-keyframes loading {
  50% { height: 100px; margin-top: 0px; }
}
@-o-keyframes loading {
  50% { height: 100px; margin-top: 0px; }
}
@keyframes loading {
  50% { height: 100px; margin-top: 0px; }
}*/

@mixin inner() {
  height: 10px;
  width: 30px;
  background-color: #fff;
  display: inline-block;
  margin-top: 90px;
  margin-right: 2px;
  -webkit-animation: loading 2.5s infinite;
  -moz-animation: loading 2.5s infinite;
  -o-animation: loading 2.5s infinite;
  animation: loading 2.5s infinite;
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;

}
@mixin loading() {
	@for $i from 1 through 10 {
		.loading-#{$i} { @include inner(); -webkit-animation-delay: math.div($i, 4)+s; animation-delay: math.div($i, 4)+s; }
	}
}
.loading { @include loading(); }
</style>
