<template>
    <div class="AnalysisModal">
        <transition name="fade">
            <div class="AnalysisModal--Content" v-if="isOpen">
                <h1>Desbloquea la <span>versión oro</span> para ver este contenido</h1>
                <p>Estás intentando acceder a los comentarios de topics creados por el administrador de la comunidad. Para poder verlos, es necesario la versión oro.</p>
                <div class="AnalysisModal--Actions">
                    <button v-on:click="$emit('close')">Entendido</button>
                </div>
            </div>
        </transition>
    </div>
  </template>
  <script>
  export default {
    name: "AnalysisModal",
    data(){
        return{
            isOpen: false,
        }
    },
    mounted(){
        this.isOpen = true
    }
  };
  </script>
  <style scoped lang="scss">
  .AnalysisModal{
    width: 100%;
    height: 100%;
    position: absolute;
    top:0;
    left:0;
    background: rgba(0,0,0,0.7);
    z-index: 9999;
    display: flex;
    align-items: center;
    justify-content: center;
    &--Content{
        border-radius: 20px;
        background: white;
        padding: 24px;
        width: 500px;
        display: flex;
        flex-direction: column;
        position: absolute;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 12px;
    }
    &--Actions{
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 20px;
    }
  }
  h1{
    font-family: 'Bariol';
    font-weight: 700;
    font-size: 24px;
    span{
        color: var(--primary-color);
    }
  }
  p{
    font-family: 'Lato';
    font-weight: 400;
    font-size: 16px;
    color: #626262;
  }
  button{
        padding: 12px 16px;
        height: 41px;
        background: var(--accent-color);
        color: white;
        border: 1px solid #EAEAEA;
        border-radius: 4px;
        text-transform: uppercase;
        font-family: 'Lato';
        font-weight: 900;
        font-size: 14px;
        cursor: pointer;
    }

  .fade-enter-active,
  .fade-leave-active {
    transition: all 0.5s;
    bottom:0;
    max-height: 1000px;
    position: relative;
  }
  .fade-enter-from,
  .fade-leave-to
  {
    bottom:-1200px;
    max-height: 0;
  }
  </style>
  