<template>
  <svg
    width="10"
    height="15"
    viewBox="0 0 10 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8 0.5H2C1.60218 0.5 1.22064 0.658035 0.93934 0.93934C0.658035 1.22064 0.5 1.60218 0.5 2V13C0.5 13.3978 0.658035 13.7794 0.93934 14.0607C1.22064 14.342 1.60218 14.5 2 14.5H8C8.39782 14.5 8.77936 14.342 9.06066 14.0607C9.34196 13.7794 9.5 13.3978 9.5 13V2C9.5 1.60218 9.34196 1.22064 9.06066 0.93934C8.77936 0.658035 8.39782 0.5 8 0.5ZM2 1.5H8C8.13261 1.5 8.25979 1.55268 8.35355 1.64645C8.44732 1.74021 8.5 1.86739 8.5 2V2.5H1.5V2C1.5 1.86739 1.55268 1.74021 1.64645 1.64645C1.74021 1.55268 1.86739 1.5 2 1.5ZM8 13.5H2C1.86739 13.5 1.74021 13.4473 1.64645 13.3536C1.55268 13.2598 1.5 13.1326 1.5 13V12.5H8.5V13C8.5 13.1326 8.44732 13.2598 8.35355 13.3536C8.25979 13.4473 8.13261 13.5 8 13.5Z"
      fill="#8134FF"
    />
  </svg>
</template>
