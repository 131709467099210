<template>
    <div class="VideoPlayer">
        <div class="VideoPlayer--Expand" v-on:click="$emit('expand')" v-if="!isExpandView" v-show="!hideOption">
            <i class="fas fa-expand-arrows-alt"></i>
            {{ $t('transcription_open_expand_view') }}
        </div>
        <video ref="video" :src="video.url" id="video"></video>
        <div class="VideoPlayer--Controls">
            <i v-show="!isRunning" v-on:click="play($event)" class="fa fa-play"></i>
            <i v-show="isRunning" v-on:click="stop($event)" class="fa fa-stop"></i>
            <div class="bar" ref="bar" v-on:click="goToFrame($event)">
                <div class="inner" :style="{ 'width': progress }"></div>
            </div>
            <div class="sound">
                <div>
                    <input type="range" v-model="volume" v-on:change="updateVolume()">
                </div>
                <i :class="'fas fa-volume-' + volumeType"></i>
            </div>
            <div class="VideoPlayer--SpeedControls">
                <i class="fas fa-cog" @click="toogleSpeedControls()"></i>
                <div class="speed-options" :class="{ 'show': showSpeedControl }">
                    <button v-on:click="changeSpeed(1), toogleSpeedControls()">1x</button>
                    <button v-on:click="changeSpeed(1.5), toogleSpeedControls()">1.5x</button>
                    <button v-on:click="changeSpeed(2), toogleSpeedControls()">2x</button>
                    <button v-on:click="changeSpeed(2.5), toogleSpeedControls()">2.5x</button>
                </div>
            </div>
            <div class="VideoPlayer--Time">
                {{ currentTime }}
                <span></span>
                {{ videoDuration }}
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "VideoPlayer",
    props: {
        video: Object,
        isExpandView: {
            type: Boolean,
            default: false
        },
        hideOption: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        volumeType() {
            if (this.volume >= 50) return 'up'
            else if (this.volume < 50 && this.volume > 0) return 'down'
            else return 'off'
        }
    },
    data() {
        return {
            progress: '0%',
            isRunning: false,
            currentTime: '00:00:00',
            videoDuration: '00:00:00',
            volume: 100,
            playbackSpeed: 1,
            showSpeedControl: false
        }
    },
    methods: {
        toogleSpeedControls() {
            this.showSpeedControl = !this.showSpeedControl
        },
        play(e) {
            const video = this.$refs.video
            this.isRunning = true
            video.play()
        },
        stop() {
            const video = this.$refs.video
            this.isRunning = false
            video.pause()
        },
        goToFrame(e) {
            const bar = this.$refs.bar
            const rect = e.target.getBoundingClientRect();
            const clickPosition = e.clientX - rect.left;
            const progressPercent = (clickPosition * 100) / bar.getBoundingClientRect().width
            const video = this.$refs.video
            const currentSeconds = (video.duration / 100) * progressPercent
            this.updateTime(currentSeconds)
        },
        updateTime(seconds) {
            const video = this.$refs.video
            video.currentTime = seconds
        },
        createTime(duration) {
            const minutes = Math.floor(duration / 60);
            const seconds = Math.floor(duration % 60);
            return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
        },
        updateVolume() {
            const video = this.$refs.video
            video.volume = this.volume / 100
        },
        // Nueva funcionalidad: Cambiar la velocidad de reproducción
        changeSpeed(speed) {
            const video = this.$refs.video
            this.playbackSpeed = speed
            video.playbackRate = speed
        }
    },
    mounted() {
        const video = this.$refs.video
        video.addEventListener("loadeddata", () => {
            this.$emit('load-video')
            this.videoDuration = this.createTime(video.duration)
            this.currentTime = this.createTime(video.currentTime)
        })
        video.addEventListener("timeupdate", () => {
            this.currentTime = this.createTime(video.currentTime)
            let currentProgress = (video.currentTime / video.duration) * 100
            if (isNaN(currentProgress)) currentProgress = 0
            this.progress = `${currentProgress}%`
            this.$emit('time-updated', video.currentTime)
        })
    }
};
</script>

<style scoped lang="scss">
.VideoPlayer {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-end;
    background-color: black;
    border-radius: 10px;
    height: fit-content;

    &--Controls {
        display: flex;
        align-items: center;
        gap: 20px;
        background: var(--primary-color);
        padding: 10px 20px;
        width: 90%;
        border-radius: 20px;
        height: 44px;
        margin: 10px 5%;
    }

    &--SpeedControls {
        position: relative;
        display: flex;
        align-items: center;
        cursor: pointer;

        i {
            font-size: 20px;
            color: white;
        }

        /* Ocultar los botones de velocidad por defecto */
        .speed-options {
            position: absolute;
            display: none;
            /* Oculto por defecto */
            flex-direction: column;
            gap: 10px;
            background: rgba(0, 0, 0, 0.8);
            padding: 10px;
            border-radius: 10px;
            bottom: calc(100% + 5px);
            /* Ajusta esto según la altura de tus botones */
            left: 50%;
            /* Alinea el centro del contenedor respecto a la tuerca */
            transform: translateX(-50%);
            /* Centra horizontalmente */
            z-index: 1;
            /* Para asegurarse de que aparezca encima */

            button {
                background-color: rgba(255, 255, 255, 0.2);
                color: white;
                border: none;
                border-radius: 5px;
                padding: 5px 10px;
                cursor: pointer;
                transition: background-color 0.3s ease;
            }

            button:hover {
                background-color: rgba(255, 255, 255, 0.5);
            }
        }

        /* Mostrar los botones al hacer hover sobre la tuerca */
        .speed-options.show {
            display: flex;
            flex-direction: column-reverse;
            /* Mostrar en hover */
        }
    }

    &--Time {
        color: white;
        font-family: 'Lato';
        font-weight: 700;
        font-size: 13px;
        display: flex;
        align-items: center;
        gap: 4px;

        span {
            height: 12px;
            border-left: 2px solid white;
        }
    }

    &--Expand {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 8px;
        background: var(--primary-color);
        padding: 10px 20px;
        border-radius: 20px;
        height: 44px;
        color: white;
        margin: 10px 5%;
        cursor: pointer;
    }
}

video {
    max-height: 46vh;
    width: 100%;
}

i {
    color: white;
    cursor: pointer;
}

.bar {
    background: rgba(255, 255, 255, 0.2);
    height: 7px;
    width: 100%;
    flex: 1;
    border-radius: 10px;
    overflow: hidden;
    cursor: pointer;

    &:hover {
        box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
    }
}

.inner {
    background: white;
    width: 0%;
    height: 7px;
}

.sound {
    padding: 8px;
    position: relative;
    cursor: pointer;
    border-top-right-radius: 50px;
    border-bottom-right-radius: 50px;

    div {
        background: rgba(0, 0, 0, 0.2);
        position: absolute;
        right: 100%;
        top: 0%;
        height: 35px;
        border-top-left-radius: 50px;
        border-bottom-left-radius: 50px;
        display: flex;
        align-items: center;
        padding: 0 8px;
        cursor: pointer;
        z-index: 1;
        width: 0px;
        transition: 0.5s;
        opacity: 0;

        input {
            width: 100%;
        }
    }

    &:hover {
        div {
            opacity: 1;
            width: 100px;
        }

        background: rgba(0, 0, 0, 0.2);
    }
}

input[type=range]::-webkit-slider-runnable-track {
    cursor: pointer;
}

video {
    max-height: 46vh;
    width: 100%;
}

i {
    color: white;
    cursor: pointer;
}

.bar {
    background: rgba(255, 255, 255, 0.2);
    height: 7px;
    width: 100%;
    flex: 1;
    border-radius: 10px;
    overflow: hidden;
    cursor: pointer;

    &:hover {
        box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
    }
}

.inner {
    background: white;
    width: 0%;
    height: 7px;
}

.sound {
    padding: 8px;
    position: relative;
    cursor: pointer;
    border-top-right-radius: 50px;
    border-bottom-right-radius: 50px;

    div {
        background: rgba(0, 0, 0, 0.2);
        position: absolute;
        right: 100%;
        top: 0%;
        height: 35px;
        border-top-left-radius: 50px;
        border-bottom-left-radius: 50px;
        display: flex;
        align-items: center;
        padding: 0 8px;
        cursor: pointer;
        z-index: 1;
        width: 0px;
        transition: 0.5s;
        opacity: 0;

        input {
            width: 100%;
        }
    }

    &:hover {
        div {
            opacity: 1;
            width: 100px;
        }

        background: rgba(0, 0, 0, 0.2);
    }
}

input[type=range]::-webkit-slider-runnable-track {
    cursor: pointer;
}
</style>
