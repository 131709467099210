<template lang="html">
  <div class="notesTags">
    <div class="notesTags__info">
      <h4>{{$t('comment_action_edit_tags')}}</h4>
      <p v-on:click="$emit('comment-closeTagsNotes')"><b>x</b></p>
    </div>
    <div class="notesTags__tags">
      <label>{{$t('tags')}}</label>
      <div class="notesTags__tags__list" v-on:click="focusTagsTextarea">
        <p class="notesTags__tags__list__element" v-for="(tag,key) in mutableComment.moderatorTags" v-on:click="deleteTag(key)">
          {{tag}}
        </p>
        <textarea id="tagTextarea" v-model="tagTextarea" v-on:input="beforeAddTag()"></textarea>
      </div>
    </div>
    <div class="notesTags__notes">
      <label>{{$t('comment_note')}}</label>
      <textarea v-model="mutableComment.moderatorNote"></textarea>
    </div>
    <w-button v-on:click="$emit('comment-addTagsNotes', { comment: comment })">{{$t('add')}}</w-button>
  </div>
</template>

<script>
import Button from '../Button'
import $ from "jquery";

export default {
  name: 'CommentTags',

  props: {
    comment:{
      type:Object
    }
  },

  components: {
    'w-button': Button,
  },

  data() {
    return {
      currentComment:{},
      tagTextarea:"",
      mutableComment: this.comment
    }
  },

  methods: {
    focusTagsTextarea: function(){
      $("#tagTextarea").focus();
    },

    beforeAddTag: function(){
      if (this.tagTextarea.endsWith(',')) {
        this.tagTextarea = this.tagTextarea.slice(0, -1);
        if (this.tagTextarea.length > 0) this.addTag();
      }
    },

    addTag: function(){
      if (this.mutableComment.moderatorTags == undefined) {
        this.mutableComment.moderatorTags = []
      }
      this.tagTextarea = this.tagTextarea.replace(/(\r\n\t|\n|\r\t)/gm,"")
      this.mutableComment.moderatorTags.push(this.tagTextarea)
      this.tagTextarea = ""
    },

    deleteTag: function(tagKey){
      let comment = this.comment
      this.mutableComment = {}
      comment.moderatorTags.splice(tagKey,1)
      this.mutableComment = comment
    }
  }
}
</script>

<style lang="scss" scoped>
.notesTags{
  border-radius:6px;
  box-shadow:0 0 10px lightgray;
  background: white;
  margin:0 0 10px 50%;
  width: 50%;
  padding: 10px;
  display:flex;
  flex-direction:column;
  &__info{
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    p{
      cursor:pointer;
    }
  }
  &__tags{
    margin-bottom:30px;
    &__list{
      display:flex;
      flex-wrap:wrap;
      border:1px solid #cccccc;
      &__element{
        cursor:pointer;
        margin: 5px;
        padding: 5px;
        background: #f8f8f8;
        border-radius: 12px;
        box-shadow: 0 0 10px #cccccc;
        &::after{
          content:"x";
          font-weight:bold;
        }
      }
      textarea{
        border-style: none;
        border-color: Transparent;
        overflow: auto;
        outline: none;
        border:none;
        resize:none;
        margin-top:10px;
        background: transparent;
        &:focus{
          outline:none;
          border:none!important;
          box-shadow:none;
        }
      }
    }
  }
  &__notes{
    margin-bottom:30px;
    display:flex;
    flex-direction:column;
    textarea{
      resize:none;
    }
  }
}
</style>
