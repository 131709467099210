<template>
  <svg
    width="23"
    height="23"
    viewBox="0 0 23 23"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M19.7212 19.308L14.2529 13.8398M16.0757 9.28289C16.0757 10.1207 15.9107 10.9503 15.59 11.7243C15.2694 12.4983 14.7995 13.2016 14.2071 13.794C13.6147 14.3864 12.9114 14.8563 12.1374 15.1769C11.3634 15.4975 10.5338 15.6625 9.69604 15.6625C8.85825 15.6625 8.02867 15.4975 7.25466 15.1769C6.48065 14.8563 5.77736 14.3864 5.18496 13.794C4.59255 13.2016 4.12263 12.4983 3.80203 11.7243C3.48142 10.9503 3.31641 10.1207 3.31641 9.28289C3.31641 7.59091 3.98854 5.96822 5.18496 4.77181C6.38137 3.5754 8.00405 2.90326 9.69604 2.90326C11.388 2.90326 13.0107 3.5754 14.2071 4.77181C15.4035 5.96822 16.0757 7.59091 16.0757 9.28289Z"
      stroke="#8134FF"
      stroke-width="1.82275"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
