export const formatCompanyQuestions = function(questions: any){
    let companyQuestions = questions
    for (var i = 0; i < companyQuestions.length; i++) {
        companyQuestions[i].id = -companyQuestions[i].id;
    }
    let ids = []
    companyQuestions = companyQuestions.filter(function(q){
        if(!ids.includes(q.id)){
            ids.push(q.id)
            return q
        }
    })
    for(let q of companyQuestions){
        q.questionText = q.text
        q.questionId = Math.abs(q.id)
        q.questionType = q.type
        q.taskNum = -1
        q.order = "DEFAULT"
        q.resultType = "PERCENT_XAXIS"
        q.graphType = "VERTICAL_BARS"
        q.xDimension = {taskNum: -1, questionNum: q.questionId, values: q.values.map(function(v,i){return v.id})}
        q.xFilter = []
    }
    return companyQuestions
  }

export const formatTestQuestionsByTasks = function(testTasks) {
    const tasks = testTasks
    for (let task of tasks) {
        if(task.questions != undefined && task.questions != null){
            for (let question of task.questions) {
                question = formatQuestion(question, task.taskNum, false)
            }
        }
    }
    return tasks
};

export const formatQuestion = function(question: any, taskNum: any, isFilterQuestion: any){
  question.questionType = question.type;
  question.questionText = removeHTML(question.text);
  question.questionId = question.id
  question.taskNum = taskNum
  if(isFilterQuestion) question.combination = 'OR'
  if(isFilterQuestion) question.split = false
  if(isRankingQuestion(question)){
    question.values = []
    for (let i = question.rankingMin; i <= question.rankingMax; i++) {
      question.values.push({ value: i, id: i });
  }
  }
  delete question.type;
  return question
}

  export const formatFilterQuestions = function(testData){
    let filtersData = testData
    let questions = []
    for (var i = 0; i < filtersData.length; i++) {
        for (var x = 0; x < filtersData[i].questions.length; x++) {
            questions.push(formatQuestion(filtersData[i].questions[x], filtersData[i].taskNum, true))
        }
    }
    return questions
  }

  export const removeHTML = function(textWithHTML: any){
    let text = textWithHTML.replace(/(<([^>]+)>)/ig, '');
    text = text.replace(/(&.*;)/, '');
    return text
  }

  export const generateXDimensions = function(question, value) {
    const questionType = question.questionType
    let data: any = {taskNum:question.taskNum, questionNum: parseInt(question.questionId)}
    if(questionType == 'MATRIX' || questionType == 'SORTING' || questionType == 'DIVIDE_POINTS') data.opt = 'all'
    if(questionType == 'NUMBER' || questionType == "DECIMAL_NUMBER" || questionType == "MULTINUMBER") data.ranges = question.ranges
    if(questionType == 'NUMBER' && !isUxQuestion(question)) data.opt = 'number'
    if(question.taskNum == -1 && question.questionId == 1) data.groups = question.xDimension.groups

    /*if(values && values.length > 0) {
      data[0].values = []
      for(let v of values){
        if(v.selected) data[0].values.push(v.id)
      }
    }
    if(groups && groups.length > 0){
      data[0].groups = []
      for(let g of groups){
        data[0].groups.push({groupName:g.groupName,values:g.values.map(function(v,i){return v.id})})
      }
    }*/
    return data
  }

  export const generateFilters = function(filters, question){
    for(let f of filters){
      const questionNum = f.questionNum || f.questionId
      let y: any = {taskNum : f.taskNum, questionNum: parseInt(questionNum), combination:f.combination || undefined}
      if(questionNum == 2 && f.taskNum == -1 && !isUxQuestion(question)) y.opt = 'number'
      if(f.values){
        y.values = []
        for(let v of f.values){
          if(v.selected) y.values.push(v.id)
        }
      }
      if(f.groups){
        y.groups = []
        for(let g of f.groups){
          if(g.selected){
            y.groups.push({groupName:g.groupName, values: g.values})
            y.values = y.values == undefined ? g.values : y.values.concat(g.values)
          }
        }
      }
      if(f.ranges && f.ranges.length > 0 && (f.ranges[0].from != 16 || f.ranges[0].to != 100)) y.ranges = f.ranges
      if((y.values && y.values.length > 0) || y.ranges || (y.groups && y.groups.length > 0)){
        question.xFilter.push(y)
      }
    }
  }

  export const generateYdimensions = function(filters, question, defaultRangesForSplit){
    question.yDimension = {}
    for(let f of filters){
      const questionNum = f.questionNum || f.questionId
      if(f.split){
        let yDimension: any = {taskNum : f.taskNum, questionNum: parseInt(questionNum)}
        if(questionNum == 2 && f.taskNum == -1 && !isUxQuestion(question)) yDimension.opt = 'number'
        if(f.ranges && f.ranges.length > 0) yDimension.ranges = defaultRangesForSplit
        if(f.groups){
          yDimension.groups = []
          for(let g of f.groups){
            yDimension.groups.push({groupName:g.groupName, values:g.values})
          }
        }
        question.yDimension = yDimension
      }
    }
  }

  export const isMatrixChart = function(question: any){
    return question.questionType == 'MATRIX'
  }

  export const isMatrixRadioButtonChart = function(question: any){
    return isMatrixChart(question) && question.matrixType == 'RADIO_BUTTONS' 
  }

  export const isSortingChart = function(question: any){
    return question.questionType == 'SORTING'
  }

  export const isDividePointsChart = function(question: any){
    return question.questionType == 'DIVIDE_POINTS'
  }

  export const isNumberChart = function(question: any){
    return question.questionType == 'NUMBER' || question.questionType == 'DECIMAL_NUMBER'
  }

  export const isRankingQuestion = function(question: any){
    return question.questionType == 'RANKING' || question.questionType == 'RATING_FACES'
  }

  export const isQuestionWithValues = function(question: any){
    return question.questionType == 'SELECTION' || question.questionType == 'MULTISELECTION' || question.questionType == 'SORTING' || question.questionType == 'IMAGE_CHOICE' || question.questionType == 'MULTIPLEIMAGE_CHOICE'
  }

  export const isUxQuestion = function(question: any){
    return question.taskNum > 0 && question.questionId < 0
  }