<template>
  <label class="CheckBox">
    <input type="checkbox" v-model="componentValue">
    <span></span>
    <p>{{text}}</p>
  </label>
</template>

<script>
  export default {
    name: 'CheckBox',
    props:{
      text:{
        type:String,
        default:''
      },
      value : {
        type: Boolean,
        default: false,
      },
    },
    data(){
      return{
        componentValue: this.value,
      }
    },
    watch: {
     componentValue (newValue) {
       this.$emit('input', newValue)
     },
     value (newValue) {
       this.componentValue = newValue
     }
    },
  }
</script>

<style scoped lang="scss">
.CheckBox {
  display: block;
  position: relative;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }
  span {
    position: absolute;
    top: 0;
    left: 0;
    height: 17px;
    width: 17px;
    border: 1px solid #E1E1E1;
    &:after {
      content: "";
      position: absolute;
      display: none;
    }
  }
  &:hover input ~ span {
    background-color: #ccc;
  }
  input:checked ~ span {
    background-color: var(--primary-color);
  }
  input:checked ~ span:after {
    display: block;
  }
  span:after {
    left: 5px;
    top: 1px;
    width: 6px;
    height: 11px;
    border: solid white;
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
}
p{
  margin-left: 29px;
  font-weight: bold;
  font-size: 16px;
  letter-spacing: 0.01em;
  color: #404040;
}
</style>
