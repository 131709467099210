<template lang="html">
  <div class="FilterTag" :class="{'FilterTag--Chart':type == 'Chart'}">
    <div v-if="allowDelete" v-on:click="$emit('remove')"></div>
    <p class="FilterTag--Text" v-on:click="editFilterTagData(allowEdit)">{{text}}</p>
    <!--
    <p class="FilterTag--Text">{{text}}</p>
    <img v-on:click="$emit('edit')" v-if="allowEdit" src="#/assets/img/WatMonitor/smallActionPencil.svg" alt="">
    -->
    <p class="FilterTag--Tooltip"><span>{{text}}</span></p>
  </div>
</template>

<script>

export default {
  name: 'FilterTag',

  props: {
    text:String,
    type:{
      type:String,
      default:"GENERAL"
    },
    allowDelete:{
      type:Boolean,
      default:false,
    },
    allowEdit:{
      type: Boolean,
      default:true,
    },
  },
  components: {
  },

  data() {
    return {
    }
  },
  methods:{
    editFilterTagData(allowEdit) {
      if (allowEdit) this.$emit('edit')
    }
  },
}
</script>

<style lang="scss" scoped>
@import url('https://fonts.googleapis.com/css?family=Lato:400,700');

.FilterTag{
  background: linear-gradient(70.59deg, #361466 5.34%, #895AD2 96.14%);
  border-radius: 30px;
  width: fit-content;
  height: 21px;
  display: flex;
  align-items: center;
  padding: 5px 10px;
  position: relative;
  cursor: pointer;
  &--Chart{
    background: linear-gradient(90deg, #FF1564 0%, #FD7328 100%);
    .FilterTag--Tooltip{
      background: linear-gradient(90deg, #FF1564 0%, #FD7328 100%);
    }
    &::after{
      border-color: transparent #FF1564 #FD7328 transparent!important;
    }
  }
  div{
    background: white;
    height: 15px;
    width: 15px;
    border-radius: 50%;
    position: relative;
    margin:0 7px 0 -6px;
    &:before, &:after {
      position: absolute;
      content: ' ';
      height: 10px;
      width: 1.5px;
      background-color: #96C800;
      left: 7px;
      top: 2px;
    }
    &:before {
      transform: rotate(50deg);
    }
    &:after {
      transform: rotate(-50deg);
    }
  }
  &--Text{
    font-family: Roboto Condensed;
    font-style: normal;
    font-weight: bold;
    font-size: 11px;
    letter-spacing: 0.03em;
    color: #FFFFFF;
    margin: 0;
    text-transform: capitalize;
    max-width: 130px;
    overflow: hidden;
    display: inline-block;
    text-overflow: ellipsis;
    white-space: nowrap;
    span{
      text-transform: uppercase;
    }
  }
  p.filterQuestion{
    span{
      text-transform: lowercase;
    }
  }
  img{
    margin-left: 7px;
  }
  &::after{
    content: '';
    position: absolute;
    left: 50%;
    width: 12px;
    height: 12px;
    top: calc(100% + 10px);
    transform: translate(-50%, -50%) rotate(225deg);
    background-color: white;
    border: 1px solid;
    border-color: transparent #361466 #895AD2 transparent;
    visibility: hidden;
    opacity: 0;
    transition: opacity 0.8s;
  }
  &:hover p, &:hover::after{
    visibility: visible;
    opacity: 1;
  }
  &--Tooltip{
    min-width: 106px;
    background: linear-gradient(70.59deg, #361466 5.34%, #895AD2 96.14%);
    font-size: 11px;
    left: 50%;
    font-family: 'Open Sans', Helvetica, Arial, sans-serif;
    top: calc(100% + 10px);
    transform: translate(-50%, 0%);
    color: #444444;
    border-radius: 6px;
    z-index: 1;
    position: absolute;
    visibility: hidden;
    opacity: 0;
    transition: opacity 0.8s;
    display: flex;
    align-items: center;
    justify-content: center;
    span{
      width: 100%;
      height: 100%;
      margin:1px;
      background: white;
      border-radius: 6px;
      padding: 6px 9px;
      text-align: center;
      font-family: 'Roboto Condensed';
      font-weight: 600;
      text-transform: uppercase;
    }
  }
}
</style>
