<template>
  <div class="ProgressBar">
    <div class="ProgressBar__Progress" :style="{ width: value + '%' }">
      <div class="ProgressBar__Progress--opacity">
      </div>
     </div>
  </div>
</template>

<script>
export default {
  name: 'ProgressBar',
  props: {
    value:{
      type: Number,
      default: 0,
    },
  }
}
</script>

<style lang="scss" scoped>
.ProgressBar {
  width: 100%;
  height: 100%;
  border: 1px solid #f6f6f6;
  background: #f6f6f6;
  border-radius: 10px;

  &__Progress {
    height: 100%;
    border-radius: 10px;
    max-width: 100%;
    transition: 0.5s;
    z-index:0;
    background: var(--primary-color);
    &--opacity{
      background: linear-gradient(197.66deg, rgba(0,0,0,0.4) 10.29%, transparent 89.71%);
      z-index:1;
      height: 100%;
      width: 100%;
      border-radius: 10px;
      transition: 0.5s
    }
  }
}
</style>
