<template>
  <svg
    width="47"
    height="48"
    viewBox="0 0 47 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x="0.00012207"
      y="0.819458"
      width="47"
      height="47"
      rx="23.5"
      fill="#96C800"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M36.3001 29.1195V16.3195C36.3001 15.4708 35.9629 14.6568 35.3628 14.0567C34.7627 13.4566 33.9488 13.1195 33.1001 13.1195H13.9001C13.0514 13.1195 12.2374 13.4566 11.6373 14.0567C11.0372 14.6568 10.7001 15.4708 10.7001 16.3195V29.1195C10.7001 29.9682 11.0372 30.7821 11.6373 31.3822C12.2374 31.9823 13.0514 32.3195 13.9001 32.3195H18.7001L23.5001 37.1195L28.3001 32.3195H33.1001C33.9488 32.3195 34.7627 31.9823 35.3628 31.3822C35.9629 30.7821 36.3001 29.9682 36.3001 29.1195ZM15.5001 19.5195C15.5001 19.0951 15.6686 18.6881 15.9687 18.3881C16.2688 18.088 16.6757 17.9195 17.1001 17.9195H29.9001C30.3244 17.9195 30.7314 18.088 31.0314 18.3881C31.3315 18.6881 31.5001 19.0951 31.5001 19.5195C31.5001 19.9438 31.3315 20.3508 31.0314 20.6508C30.7314 20.9509 30.3244 21.1195 29.9001 21.1195H17.1001C16.6757 21.1195 16.2688 20.9509 15.9687 20.6508C15.6686 20.3508 15.5001 19.9438 15.5001 19.5195ZM17.1001 24.3195C16.6757 24.3195 16.2688 24.488 15.9687 24.7881C15.6686 25.0881 15.5001 25.4951 15.5001 25.9195C15.5001 26.3438 15.6686 26.7508 15.9687 27.0508C16.2688 27.3509 16.6757 27.5195 17.1001 27.5195H21.9001C22.3244 27.5195 22.7314 27.3509 23.0314 27.0508C23.3315 26.7508 23.5001 26.3438 23.5001 25.9195C23.5001 25.4951 23.3315 25.0881 23.0314 24.7881C22.7314 24.488 22.3244 24.3195 21.9001 24.3195H17.1001Z"
      fill="white"
    />
  </svg>
</template>
