<template>
  <svg
    width="14"
    height="15"
    viewBox="0 0 14 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.00065 0.833313C10.6827 0.833313 13.6673 3.81798 13.6673 7.49998C13.6673 11.182 10.6827 14.1666 7.00065 14.1666C3.31865 14.1666 0.333984 11.182 0.333984 7.49998C0.333984 6.52465 0.543318 5.59731 0.920651 4.76265C1.23132 4.13665 1.74265 3.51331 2.15398 3.29998C1.71865 4.15531 1.50532 4.99331 1.46065 5.55531C1.76332 4.55531 2.33732 3.71731 3.14065 3.12598C4.51798 2.11331 6.37265 2.06798 7.11132 2.71265C5.74065 3.18398 4.24732 5.07798 4.57598 7.29665C4.62932 7.66331 4.73932 8.01865 4.90198 8.35131C4.64598 7.67798 4.62332 6.72931 5.03665 6.11265C5.49798 5.42598 6.15532 5.28065 6.50732 5.35531C6.36865 5.32598 6.06198 5.91265 6.01665 6.01598C5.90121 6.27837 5.84411 6.5627 5.84932 6.84931C5.85916 7.44824 6.10609 8.01883 6.53598 8.43598C7.81732 9.68331 9.88465 9.19265 10.81 7.76798C11.446 6.78731 11.5233 5.12265 10.7073 3.79998C10.5037 3.47791 10.261 3.18233 9.98465 2.91998C8.75132 1.74265 6.99798 1.20665 5.32998 1.44998C4.59265 1.56798 3.96532 1.78065 3.44732 2.08731C4.17065 1.36531 5.60865 0.833313 7.00065 0.833313Z"
      fill="#8134FF"
    />
  </svg>
</template>
