<template>
  <svg
    width="12"
    height="13"
    viewBox="0 0 12 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5 7.49999V10.7275C5 10.8601 4.94732 10.9873 4.85355 11.081C4.75979 11.1748 4.63261 11.2275 4.5 11.2275C4.47003 11.2273 4.44014 11.2246 4.41062 11.2194L0.410625 10.4919C0.295417 10.4709 0.19121 10.4102 0.116169 10.3203C0.0411293 10.2305 1.69232e-05 10.1171 0 9.99999V7.49999C0 7.36738 0.0526785 7.2402 0.146447 7.14643C0.240215 7.05266 0.367392 6.99999 0.5 6.99999H4.5C4.63261 6.99999 4.75979 7.05266 4.85355 7.14643C4.94732 7.2402 5 7.36738 5 7.49999ZM4.82062 1.88874C4.76438 1.84177 4.6985 1.80774 4.62765 1.78906C4.5568 1.77038 4.48271 1.76749 4.41062 1.78061L0.410625 2.50811C0.295417 2.52904 0.19121 2.58974 0.116169 2.67963C0.0411293 2.76952 1.69232e-05 2.88289 0 2.99999V5.49999C0 5.6326 0.0526785 5.75977 0.146447 5.85354C0.240215 5.94731 0.367392 5.99999 0.5 5.99999H4.5C4.63261 5.99999 4.75979 5.94731 4.85355 5.85354C4.94732 5.75977 5 5.6326 5 5.49999V2.27249C5.00001 2.19929 4.98395 2.12699 4.95296 2.06068C4.92197 1.99437 4.87679 1.93567 4.82062 1.88874ZM11.5 6.99999H6.5C6.36739 6.99999 6.24021 7.05266 6.14645 7.14643C6.05268 7.2402 6 7.36738 6 7.49999V11.0906C5.99987 11.2078 6.04092 11.3213 6.11597 11.4114C6.19102 11.5014 6.29531 11.5622 6.41063 11.5831L11.4106 12.4919C11.4401 12.4974 11.47 12.5001 11.5 12.5C11.6326 12.5 11.7598 12.4473 11.8536 12.3535C11.9473 12.2598 12 12.1326 12 12V7.49999C12 7.36738 11.9473 7.2402 11.8536 7.14643C11.7598 7.05266 11.6326 6.99999 11.5 6.99999ZM11.8206 0.616236C11.7644 0.569273 11.6985 0.535245 11.6277 0.51656C11.5568 0.497876 11.4827 0.494991 11.4106 0.508111L6.41063 1.41749C6.29542 1.43842 6.19121 1.49912 6.11617 1.58901C6.04113 1.67889 6.00002 1.79227 6 1.90936V5.49999C6 5.6326 6.05268 5.75977 6.14645 5.85354C6.24021 5.94731 6.36739 5.99999 6.5 5.99999H11.5C11.6326 5.99999 11.7598 5.94731 11.8536 5.85354C11.9473 5.75977 12 5.6326 12 5.49999V0.999986C12 0.926791 11.984 0.854486 11.953 0.788177C11.922 0.721868 11.8768 0.663173 11.8206 0.616236Z"
      fill="#8134FF"
    />
  </svg>
</template>
