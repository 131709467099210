<template lang="html">
  <div class="SurveyExecution">
    <div class="SurveyExecution__question" :class="{'disabledQuestion':q.disableQuestion}" v-for="(q,qi) in questions">
      <h1 class="SurveyExecution__question--title" v-html="q.questionText"></h1>
      <div class="SurveyExecution__question--images">
        <MediaViewer :images="$filters.formatMedia(q.images)"/>
      </div>
      <div class="SurveyExecution__question--options">
        <div class="SurveyExecution__question--input" v-for="(option, index) in q.options" v-if="q.type == 'MULTI_SELECT'">
          <label class="checkbox">
              <input type="checkbox" :name="'check_'+q.identifier" :value="index" v-model="option.selected" :disabled="q.disableQuestion" v-on:change="checkConditional(q,index,qi)"/>
              <span></span>
              <p>{{option.value}}</p>
          </label>
        </div>
        <div class="SurveyExecution__question--input" v-for="(option, index) in q.options" v-if="q.type == 'SINGLE_SELECT'">
          <label class="radio">
            <input type="radio" :name="'radio_'+q.identifier" :value="index" v-model="q.selected" :disabled="q.disableQuestion" v-on:change="checkConditional(q,index,qi)">
            <span></span>
            <p>{{option.value}}</p>
          </label>
        </div>
        <div class="SurveyExecution__question--ranking" v-if="q.type == 'RANKING'">
          <label
            :class="{'selected':index <= q.selected}"
            v-for="(value,index) in q.maxValue+1"
            v-if="index<=q.maxValue && index>=q.minValue"
            :key="index">
            <input type="radio" :name="'radio_'+q.identifier" :value="index" v-model="q.selected" :disabled="q.disableQuestion">
            <!-- <input type="radio" :name="'radio_'+q.identifier" v-model="q.selected"> -->
            <p> {{index}}</p>
          </label>
        </div>
        <div class="SurveyExecution__question--text" v-if="q.type == 'TEXT'">
          <input type="text" v-model="q.text" :disabled="q.disableQuestion">
        </div>
        <dividePoints :question="q" v-on:send-answers="handleDividepointsAnswers($event)"/>
      </div>
      <div class="SurveyExecution__question--space"></div>
    </div>
    <div class="SurveyExecution__Action">
      <Button id="submitButtonSurvey" color="accent" v-on:click="sendResponse()">{{$t('activity_survey_send_answer')}}</Button>
    </div>
  </div>
</template>

<script>
import Swal from 'sweetalert2'
import { mapGetters } from 'vuex';
import { map, flatMap } from 'lodash';
import Button from '~/components/Button';
import MediaViewer from '~/components/MediaViewer';
import dividePoints from '@/components/Survey/dividePoints'
export default {
  name: 'ActivityInfoPage',

  computed: {
    ...mapGetters([
      'theme',
      'communityCurrentActivityId',
      'isClient',
      'isUser',
      'isObservator',
      'isModerator',
    ]),
  },

  components:{
    Button,
    MediaViewer,
    dividePoints
  },

  props:{
    questions:{
      type:Array,
    },
  },

  data(){
    return{
      dividePointsAnswers:[],
    }
  },
  async created(){
    for (let i in this.questions) {
      if(this.questions[i].type == "DIVIDE_POINTS") this.questions[i].valid = false
      for(let i2 in this.questions[i].options){
        this.checkConditional(this.questions[i],i2,i)
      }
    }
  },
  methods: {
    handleDividepointsAnswers(e){
      for (let q of this.questions) {
        if(q.identifier == e.question.identifier){
          q.valid = e.valid
          this.dividePointsAnswers[q.identifier] = e.answer
          this.disableSubmitButton()
        }
      }
    },
    disableQuestion: function(q){
      if (q.conditionedTo != undefined) {
        if (this.questions[q.conditionedTo].type == "MULTI_SELECT") return !this.questions[q.conditionedTo].options[q.conditionedVal].selected
        else if (this.questions[q.conditionedTo].type == "SINGLE_SELECT") return this.questions[q.conditionedTo].selected != q.conditionedVal
      }
      return false
    },

    checkConditional: function(q, vi, qi){
      for (let o of this.questions) {
        if (o.conditionedTo == qi) {
          if(q.type == "MULTI_SELECT"){
            for(let index in q.options) {
              if(o.conditionedVal == index){
                if(q.options[index].selected){
                  o.disableQuestion = false
                }
                else{
                  o.disableQuestion = true
                }
              }
            }
          }
          else if(q.type == "SINGLE_SELECT"){
            o.disableQuestion = q.selected != o.conditionedVal
          }
        }
      }
    },

    disableSubmitButton: function(){
      for (let q of this.questions) {
        if (!this.disableQuestion(q)) {
          if (q.type == "MULTI_SELECT") {
            let optionsSelected = []
            for (let o in q.options) {
              if (q.options[o].selected) {
                optionsSelected.push(o)
              }
            }
            if (optionsSelected.length == 0) return true
          }
          else if(q.type == "RANKING" || q.type == "SINGLE_SELECT"){
            if (q.selected ==undefined) return true

          }
          else if(q.type == "TEXT"){
            if (q.text == undefined || q.text.length < 2) return true
          }
          else if(q.type == "DIVIDE_POINTS"){
            if(!q.valid){
              return true
            }
          }
        }
      }
      return false
    },
    checkCanSendAnswers(){
      for (let q of this.questions) {
        if (!this.disableQuestion(q)) {
          if (q.type == "MULTI_SELECT") {
            let optionsSelected = []
            for (let o in q.options) {
              if (q.options[o].selected) {
                optionsSelected.push(o)
              }
            }
            if (optionsSelected.length == 0){
              Swal.fire(this.$t('msg_activity_task_answer_not_answered',{questionNum:"'"+q.questionText+"'"}))
              return false
            }
          }
          else if(q.type == "RANKING" || q.type == "SINGLE_SELECT"){
            if (q.selected ==undefined){
              Swal.fire(this.$t('msg_activity_task_answer_not_answered',{questionNum:"'"+q.questionText+"'"}))
              return false
            }

          }
          else if(q.type == "TEXT"){
            if (q.text == undefined || q.text.length < 2){
              Swal.fire(this.$t('msg_activity_task_answer_not_answered',{questionNum:"'"+q.questionText+"'"}))
              return false
            }
          }
          else if(q.type == "DIVIDE_POINTS"){
            if(!q.valid){
              Swal.fire(this.$t('msg_activity_task_answer_not_answered',{questionNum:"'"+q.questionText+"'"}))
              return false
            }
          }
        }
      }
      return true
    },
    sendResponse: function(){
      if(this.checkCanSendAnswers()){
        let formatedQuestions = []

        for (let q of this.questions) {
          let questionFormated = {}
          questionFormated.questionId = q.identifier
          questionFormated.values = []
            if (q.type == "MULTI_SELECT") {
              if (!q.disableQuestion) {
                for (let o in q.options) {
                  if (q.options[o].selected) {
                    questionFormated.values.push(o)
                  }
                }
              }
            }
            else if(q.type == "SINGLE_SELECT"){
              if (q.disableQuestion) {
                questionFormated.values[0] = null
              }
              else{
                questionFormated.values.push(q.selected)
              }
            }
            else if(q.type == "RANKING"){
              if (q.disableQuestion) {
                questionFormated.values[0] = null
              }
              else{
                questionFormated.values.push(q.selected)
              }
            }
            else if(q.type == "TEXT") {
              if (!q.disableQuestion) {
                questionFormated.text = q.text
              }
            }
            else if(q.type == "DIVIDE_POINTS"){
              questionFormated.points = this.dividePointsAnswers[q.identifier]
              delete questionFormated.values
            }


          formatedQuestions.push(questionFormated)
        }
        this.$emit('send-survey-response',formatedQuestions)
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.SurveyExecution{
  margin: 10px;
  &__question{
    &.disabledQuestion{
      opacity: 0.2;
      filter: blur(3px);
      cursor: not-allowed;
    }
    &--title{
      font-size: 18px;
      font-weight: 600;
      margin:10px 0;
      color: #696969;
      word-wrap: break-word;
    }
    &--options{
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
    }
    &--ranking{
      display: flex;
      width: 100%;
      label{
        cursor: pointer;
        padding: 10px;
        p{
          font-size:25px;
          font-weight: bold;
        }
        &.selected, &:hover{
          color:var(--primary-color);
        }
        input{
          display: none;
        }
      }
    }
    &--input{
      width: 49%;
      font-size: 14px;
      font-weight: 600;
      background: #fafafa;
      margin: 5px 0;
      padding: 5px;
      color: #696969;
      position:relative;
      display: flex;
      align-items: center;
      p{
        margin-left: 15px;
      }
      .checkbox, .radio {
        width: 100%;
        display: flex;
        align-items: center;
        input{
          position: relative;
          z-index: -9999;
          &:checked + span{
            background: var(--primary-color);
          }
        }
        span{
          width: 15px;
          height: 15px;
          min-width: 15px;
          padding: 5px;
          display: block;
          background: white;
          border: 1px solid lightgray;
        }
      }
      .radio{
        span{
          width: 15px;
          height: 15px;
          min-width: 15px;
          border-radius:10px;
          display: block;
          background: white;
          border: 1px solid lightgray;
        }
      }
    }
    &--text{
      width: 100%;
      input{
        background: #f1f1f1;
        border: 0;
        border-bottom: 1px solid lightgray;
        padding: 5px 5px;
        outline: none;
        width: 100%;
        &:focus{
          border-bottom: 1px solid var(--primary-color);
        }
      }
    }
    &--space{
      background: #f1f1f1;
      padding: 0.5px;
      margin: 20px 80px;
    }
  }
  &__Action{
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
    margin-bottom: 30px;
    button{
      border-radius: 20px;
    }
  }
  @media (max-width: 768px) {
    &__question{
      &--options{
        flex-direction: column;
        width:100%;
      }
      &--input{
        width: 100%;
      }
      &--ranking{
        label{
          padding:5px;
        }
      }
    }
  }
}
</style>
