<template>
  <svg
    width="14"
    height="15"
    viewBox="0 0 14 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.68102 2.375C7.81969 1.83794 8.13302 1.36225 8.57169 1.02281C9.01037 0.683364 9.54947 0.499444 10.1041 0.500001H10.1666C10.2993 0.500001 10.4264 0.55268 10.5202 0.646448C10.614 0.740216 10.6666 0.867393 10.6666 1C10.6666 1.13261 10.614 1.25979 10.5202 1.35355C10.4264 1.44732 10.2993 1.5 10.1666 1.5H10.1041C9.77158 1.49997 9.44843 1.61046 9.1855 1.81411C8.92258 2.01775 8.73479 2.303 8.65164 2.625C8.61849 2.75347 8.53567 2.8635 8.42139 2.93089C8.30711 2.99829 8.17073 3.01753 8.04227 2.98438C7.9138 2.95122 7.80377 2.8684 7.73638 2.75412C7.66898 2.63984 7.64974 2.50347 7.68289 2.375H7.68102ZM13.6229 11.0994C13.5854 11.0137 13.5248 10.9401 13.4479 10.8869C12.3873 10.1581 12.1666 8.915 12.1666 8C12.1666 6.89563 13.0085 5.93375 13.5104 5.45813C13.5598 5.4114 13.5991 5.3551 13.6259 5.29267C13.6528 5.23023 13.6667 5.16297 13.6667 5.095C13.6667 5.02703 13.6528 4.95977 13.6259 4.89734C13.5991 4.8349 13.5598 4.7786 13.5104 4.73188C12.7179 3.98375 11.4054 3.5 10.1666 3.5C9.2768 3.5008 8.40705 3.76455 7.66664 4.25813C6.80297 3.67898 5.76448 3.41888 4.72978 3.52256C3.69508 3.62625 2.72885 4.08724 1.99727 4.82625C1.55992 5.27275 1.21676 5.80258 0.988177 6.38429C0.759595 6.966 0.650252 7.58771 0.666643 8.2125C0.691179 9.26709 0.925676 10.3062 1.35643 11.2691C1.78718 12.232 2.40555 13.0994 3.17539 13.8206C3.63902 14.2587 4.25319 14.5019 4.89102 14.5H10.371C10.712 14.5006 11.0495 14.4313 11.3626 14.2961C11.6756 14.161 11.9576 13.963 12.191 13.7144C12.6234 13.2491 12.9974 12.7327 13.3048 12.1769C13.7435 11.375 13.6873 11.25 13.6229 11.0994Z"
      fill="#8134FF"
    />
  </svg>
</template>
