<template lang="html">
    <div class="CommentsList">
      <div
        class="CommentsList__Item"
        v-for="(comment, i) in comments"
        v-bind:key="comment.identifier"
      >
        <Comment
          :comment="comment"
          :hideGamification="hideGamification"
          :class="{navigatedComment:navigatedComment== comment.parentCommentId}"
          v-on:comment-reaction="$emit('comment-reaction', $event)"
          v-on:comment-delete="$emit('comment-delete', $event)"
          v-on:comment-tags="handleTagsComment"
          v-on:comment-reply="handleReplyComment"
          v-on:comment-medal="$emit('comment-medal',$event)"
          v-on:pin-comment="$emit('pin-comment',$event)"
          v-on:open-chat="$emit('open-chat',$event)"
          v-on:goActivity="$emit('goActivity',$event)"
          v-on:addMember="addMember"
          :savedMembers="savedMembers"
          v-bind="{
            allowLike, allowDislike, allowReply, isObservator, allowReplyParent:comments.length-1 == i && !allowReply, allowDelete,
            allowTag, allowAddMedals, allowPinComment, allowCommentLink, allowChatButton,allowUnseenCheck, theme, forExporting, debateTopics, allowMenu
          }">
        </Comment>
        <CommentTags
          v-if="comment.identifier == tagCommentId"
          :comment="comment"
          v-on:comment-addTagsNotes="handleTagsAdd"
          v-on:comment-closeTagsNotes="closeTagsNotes"
        >
        </CommentTags>
        <div class="CommentsList__Item__Answers" v-if="(comment.answers && comment.answers.length) > 0 || replyingCommentId == comment.identifier">
          <span class="answerToggler" @click="toggleComment( i )" v-if="!forExporting">
            <div v-if="!hiddenComments.includes( i )">
              <small>{{$t('comment_action_hide')}}</small>
              <i class="fas fa-eye-slash"></i>
              <small>{{$t('answers')}}</small>
            </div>
            <div v-if="hiddenComments.includes( i )">
              <small>{{$t('comment_action_show')}}</small>
              <i class="fas fa-eye"></i>
              <small>{{$t('answers')}}</small>
            </div>
          </span>
          <CommentsList
            :ref="'answers-' + i"
            :comments="comment.answers"
            v-on:comment-reaction="$emit('comment-reaction', $event)"
            v-on:comment-tags="handleTagsComment"
            v-on:comment-addTagsNotes="handleTagsAdd"
            v-on:comment-delete="$emit('comment-delete', $event)"
            v-on:comment-medal="$emit('comment-medal',$event)"
            v-on:comment-reply="handleReplyComment"
            v-on:pin-comment="$emit('pin-comment',$event)"
            v-on:open-chat="$emit('open-chat',$event)"
            v-bind="{
              allowLike, allowDislike, allowReply:allowReplyOnReplies, isObservator, allowAttach, allowDelete,
              allowTag, allowAddMedals, allowPinComment, allowChatButton, allowCommentLink, allowUnseenCheck, theme, user, forExporting
            }"
            :fileUploadPath="fileUploadPath"
            :videoUploadPath="videoUploadPath"
            :fileUploadHeaders="fileUploadHeaders"
            :hideGamification="hideGamification"
            :navigatedComment="navigatedComment" />
            <CommentInput
            v-if="replyingCommentId == comment.identifier"
            class="CommentsList__ReplyCommentInput"
            :user="user"
            v-on:submit-comment="handleAddReply"
            :allowAttach="allowAttach"
            :fileUploadPath="fileUploadPath"
            :videoUploadPath="videoUploadPath"
            :fileUploadHeaders="fileUploadHeaders"
            :hideGamification="hideGamification"
            :fetchRecomendationUsers="fetchRecomendationUsers"
            :commentId="comment.identifier"
            :nickname="replyingNickname" 
          />
            
        </div>
        <div :id="'reply_'+comment.identifier">
  
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import Comment from '../Comment'
  import CommentInput from '../CommentInput'
  import CommentsList from '../CommentsList'
  import CommentTags from '../CommentTags'
  import $ from "jquery";
  
  export default {
    name: 'CommentsList',
  
    props: {
      comments: {
        type: Array
      },
  
      allowReply: {
        type: Boolean,
        default: true,
      },
  
      allowReplyOnReplies: {
        type: Boolean,
        default: false,
      },
  
      allowLike: {
        type: Boolean,
        default: true,
      },
  
      allowDislike: {
        type: Boolean,
        default: true,
      },
  
      allowDelete: {
        type: Boolean,
        default: false,
      },
  
      allowTag: {
        type: Boolean,
        default: false,
      },
  
      allowAttach: {
        type: Boolean,
        default: false,
      },
  
      allowAddMedals: {
        type: Boolean,
        default: false,
      },
  
      allowPinComment: {
        type: Boolean,
        default: false,
      },
  
      allowChatButton: {
        type: Boolean,
        default: false,
      },
  
      allowCommentLink: {
        type: Boolean,
        default: false,
      },
  
      allowUnseenCheck:{
        type: Boolean,
        default: false,
      },
  
      fileUploadPath: {
        type: String,
        default: null,
      },
  
      videoUploadPath: {
        type: String,
        default: null,
      },
  
      fileUploadHeaders: {
        type: Object,
        default: null,
      },
  
      user: {
        type: Object,
      },
  
      isObservator:{
        type:Boolean,
        default:false,
      },
  
      hideGamification: {
        type: Boolean,
        default: false,
      },
  
      navigatedComment: {
        type: Number,
        default: 0,
      },
  
      forExporting:{
        type: Boolean,
        default: false,
      },
      debateTopics: {
        type: Array,
        default:null,
      },
      allowMenu:{
        type: Boolean,
        default: false
      },
      theme: {
        type: Object
      },
      fetchRecomendationUsers: {
        type: Function,
        default: null
      }
    },
  
    components: {
      Comment,
      CommentInput,
      CommentTags,
      CommentsList
    },
  
    data() {
      return {
        replyingCommentId: null,
        replyingNickname: null,
        tagCommentId: null,
        foldAnswers: false,
        hiddenComments: [],
        savedMembers: []
      }
    },
  
    methods: {
      addMember: function (e) {
        this.savedMembers.push({ id: e.identifier, nickname: e.nickname, gender: e.gender, age: e.age, province: e.province.text, tags: e.tags, publicTags: e.publicTags });
      },
      toggleComment ( index ) {
        const commentsComponent = this.$refs['answers-' + index];
        const commentsDiv = commentsComponent[0].$el;
  
        if ( commentsDiv && commentsDiv.classList.contains( 'hidden' ) ) {
          commentsDiv.classList.remove( 'hidden' );
          commentsDiv.classList.remove( 'gone' );
          this.hiddenComments = this.hiddenComments.filter( i => i !== index );
        } else {
          commentsDiv.classList.add( 'hidden' );
          setTimeout( ( ) => {
            commentsDiv.classList.add( 'gone' );
          }, 250 );
          this.hiddenComments.push( index );
        }
      },
  
      handleReplyComment: function (e) {
        this.$emit('stop-comments-update')
        if ( e.last ) {
          this.$emit('comment-reply', { commentId: e.commentId, last:false })
          return
        }
        this.replyingCommentId = e.commentId
        this.replyingNickname = e.nickname;  
        $('html, body').animate({
          scrollTop: ($('#reply_'+e.commentId).offset().top-250)
        },500);
      },
  
      handleAddReply: function (e) {
        let { comment, files, images, video } = e
  
        this.$emit('submit-reply', {
          commentId: this.replyingCommentId,
          comment,
          files,
          images,
          video,
        })
  
        this.replyingCommentId = null
      },
  
      handleTagsComment: function (e) {
        $(".notesTags").hide()
        this.tagCommentId = e.comment.identifier
      },
  
      handleTagsAdd: function (e){
        this.$emit('comment-addTagsNotes', e)
        this.tagCommentId = null
      },
  
      closeTagsNotes: function (e){
        this.tagCommentId = null
      }
    },
  }
  </script>
  

<style lang="scss" scoped>
.CommentsList {
  opacity: 1;
  display: block;
  transition: opacity 200ms;
  background: #FFFFFF;

  &.hidden {
    opacity: 0;
  }

  &.gone {
    display: none;
  }

  .navigatedComment{
    // border:1px solid var(--primary-color);
    box-shadow: 0 0 8px lightgray;
  }

  .Comment {
    margin-bottom: 1em;

    @media (max-width: 768px) {
      margin-bottom: .5em;
    }
  }

  &__Item {
    @media only screen and (max-width: 600px) {
      margin-top: 25px;
      }

    .answerToggler {
      display: block;
      width: 100%;
      text-align: center;
      border-top: 1px dashed #e3e3e3;
      border-bottom: 1px dashed #e3e3e3;
      margin-bottom: 1rem;
      background: #f2f2f2;
      color: #3e3e3e;
      cursor: pointer;

      div {
        font-size: .8rem;
      }

      i {
        padding: .5rem;
      }
    }

    &__Answers {
      padding-left: 80px;
      margin-bottom: 1em;

      .CommentsList__Item{

        @media only screen and (max-width: 600px) {
        padding-left: 40px;
        margin-bottom: 2em;
        border-left: 1px solid var(--primary-color);
      

      }
      }

      @media (max-width: 768px) {
        padding-left: 50px;
        margin-bottom: 1.5em;
      }
      @media only screen and (max-width: 600px) {
      padding-left: 0px;
      margin-bottom: 2em;
      

      }
    }
  }
}
</style>
