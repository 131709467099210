<template>
    <div class="AnalysisThemes">
        <div class="AnalysisThemes--Left">
            <h1>Todos los temas tratados</h1>
            <p>Accede a todos los comentarios y filtra por topics según qué tipo de comentarios quieres ver</p>
            <button v-on:click="$emit('open-detail')">Abrir los comentarios</button>
        </div>
        <div class="AnalysisThemes--Right">
            <span v-for="t in topics">{{ t.name }}</span>
        </div>
    </div>
  </template>
  <script>
  export default {
    name: "AnalysisThemes",
    props: {
      topics: Array
    }
  };
  </script>
  <style scoped lang="scss">
  .AnalysisThemes{
    background:url('../../assets/img/waves-bg.png'),var(--primary-color);
    border-radius: 20px;
    padding: 40px 0 40px 40px;
    display: grid;
    grid-template-columns: 40% 60%;
    height: 340px;
    align-items: flex-start;
    gap: 60px;
    overflow: hidden;

    &--Left{
        display: flex;
        align-items: flex-start;
        justify-content: center;
        flex-direction:column;
    }
    &--Right{
        display: flex;
        justify-content: flex-start;
        flex-wrap: wrap;
        gap: 10px;
        overflow: hidden;
        max-height: 265px;
        span{
            display: flex;
            align-items: center;
            justify-content: center;
            background: white;
            font-family: 'Lato';
            font-style: normal;
            font-weight: 400;
            font-size: 15px;
            color: #626262;
            height: 44px;
            border-radius: 20px;
            padding: 0 15px;
        }
    }
  }

  h1{
    font-family: 'Bariol';
    font-style: normal;
    font-weight: 700;
    font-size: 40px;
    color: white;
    margin: 0;
  }

  p{
    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    color: white;
    opacity: 0.7;
    margin: 12px 0 32px;
  }

  button{
    padding: 10px 16px;
    height: 37px;
    background: var(--accent-color);
    border-radius: 4px;
    color: white;
    font-family: 'Lato';
    font-weight: 900;
    font-size: 14px;
    text-transform: uppercase;
    border: none;
    cursor: pointer;
  }

  @media screen and (max-width:1080px){
        .AnalysisThemes{
          padding: 20px 0 20px 20px;
          gap: 30px;
          grid-template-columns: 50% 50%;
        }
    }

  </style>
  