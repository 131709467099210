<template lang="html">
  <button
    class="IconButton"
    type="button">
    <icon :name="icon"></icon>
  </button>
</template>

<script>
import Icon from '../Icon'

export default {
  name: 'IconButton',

  props: [
    'icon',
  ],

  components: {
    Icon,
  },
}
</script>

<style lang="scss">
.IconButton {
  width: 32px;
  height: 32px;
  border: none;
  padding: 0;
  border-radius: 50%;
  background-color: transparent;
  outline: none;

  .Icon {
    border-radius: 50%;
  }
}
</style>
