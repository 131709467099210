<template>
  <div class="Analysis">
    <AnalysisHeader v-if="analysisEnabled" :AIsummary="activityAnalysis.AIsummary"
      :AIsummaryLong="activityAnalysis.AIsummaryLong" :isLoading="loadingSummary"
      :AIsummaryIsLong="activityAnalysis.AIsummaryIsLong" v-on:renew-summary="changeSummary()" />
    <h1 v-if="analysisEnabled">Y cabe destacar estos comentarios</h1>
    <div v-if="analysisEnabled" class="Analysis--Comments">
      <AnalysisComment v-for="c of activityAnalysis.comments" :comment="c" />
    </div>
    <AnalysisThemes v-if="analysisEnabled" :topics="activityAnalysis.topics"
      v-on:open-detail="openAnalysisDetailModalInstance()" />
    <div v-if="!analysisEnabled" class="Analysis--Empty">
      <AnalysisMessage :status="status" :title="$t('activity_analysis_finished_title')"
        :text="$t('activity_analysis_finished_text')" />
    </div>
  </div>
</template>
<script>
import { inject, ref, reactive } from "vue";
import { mapGetters } from "vuex";
import { useRoute, useRouter } from 'vue-router'
import vueApp from '@/main';
import { mount } from '@/utils'
import store from "@/store";
import AnalysisHeader from '../ActivityAnalysis/AnalysisHeader'
import AnalysisComment from '../ActivityAnalysis/AnalysisComment'
import AnalysisThemes from '../ActivityAnalysis/AnalysisThemes'
import AnalysisDetail from '../ActivityAnalysis/AnalysisDetail'
import AnalysisMessage from '../ActivityAnalysis/AnalysisMessage'
import {
  ACTIVITY_FETCH_ANALYSIS_DATA,
  ACTIVITY_ADD_MODERATOR_TOPICS,
  ACTIVITY_FETCH_MODERATOR_TOPICS,
  ACTIVITY_FETCH_ANALYSIS_DATA_COMPLETE,
  ACTIVITY_DELETE_MODERATOR_TOPICS,
  ACTIVITY_RENEW_ANALYSIS_CHANGE_SUMMARY
} from '@/store/community/activities.module';
export default {
  name: "Analysis",
  components: {
    AnalysisHeader,
    AnalysisComment,
    AnalysisThemes,
    AnalysisDetail,
    AnalysisMessage
  },
  computed: {
    ...mapGetters([
      'communityCurrentActivityId',
      'activityAnalysis'
    ])
  },
  props: {
    status: {
      type: String,
      default: "ACTIVE",
    }
  },
  data() {
    return {
      loadingSummary: false
    }
  },
  methods: {
    async changeSummary() {
      const route = this.$route;
      this.loadingSummary = true;
      await store.dispatch(ACTIVITY_RENEW_ANALYSIS_CHANGE_SUMMARY, { activityId: route.params.id })
        .then((data) => {
          this.fetchAnalysisData()
            .then(() => {
              this.loadingSummary = false;
            })
        })
    },
    openAnalysisDetailModalInstance() {
      const { el, vNode, destroy } = mount(AnalysisDetail, {
        app: vueApp
      })
      vueApp._container.appendChild(el);
      const AnalysisModalInstance = {
        el: el,
        vNode: vNode,
        destroy: destroy
      }
      this.saveInstance(AnalysisModalInstance)
    }
  },
  mounted() {
    this.fetchAnalysisData()
  },
  setup(props, context) {
    let AnalysisInstance = reactive({})
    let analysisEnabled = ref(false)
    const route = useRoute()
    const router = useRouter()
    const emitter = inject("emitter")
    const saveInstance = (instance) => {
      AnalysisInstance = instance
    }
    const fetchAnalysisData = async () => {
      await store.dispatch(ACTIVITY_FETCH_ANALYSIS_DATA_COMPLETE, { activityId: route.params.id })
        .then((data) => {
          if (data.AIsummary == undefined) analysisEnabled.value = false
          else analysisEnabled.value = true
        })
        .catch((response) => {
          analysisEnabled.value = false
        })
    }
    emitter.on("analysis-redirect-to-comment", (event) => {
      AnalysisInstance.destroy()
      context.emit('redirect-to-comment', event)
    })
    emitter.on("close-analysis-detail", (event) => {
      AnalysisInstance.destroy()
    })
    emitter.on("analysis-save-topic", async (event) => {
      await store.dispatch(ACTIVITY_ADD_MODERATOR_TOPICS, { activityId: route.params.id, data: event })
        .then((data) => {
          fetchAnalysisData()
        })
    })
    emitter.on("analysis-delete-topic", async (event) => {
      await store.dispatch(ACTIVITY_DELETE_MODERATOR_TOPICS, { activityId: route.params.id, topicId: event })
        .then((data) => {
          fetchAnalysisData()
        })
    })
    return {
      saveInstance,
      fetchAnalysisData,
      analysisEnabled
    }
  }
};
</script>
<style scoped lang="scss">
.Analysis {
  display: flex;
  flex-direction: column;
  gap: 32px;

  h1 {
    font-family: 'Bariol';
    font-style: normal;
    font-weight: 700;
    font-size: 31px;
  }

  &--Empty {
    padding: 35px;
    display: flex;
    justify-content: center;
  }

  &--Comments {
    display: grid;
    grid-template-columns: auto auto;
    grid-column-gap: 10px;
    grid-row-gap: 18px;
  }
}
</style>
