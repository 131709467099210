<template>
    <div class="VideoComments">
        <div class="VideoComments--Header" v-if="comments.length > 0 && isExpandView">
            <h1>{{ $t('transcription_notes_list_text') }}</h1>
            <p>{{ $t('transcription_total_notes', { total: comments.length }) }}</p>
        </div>
        <SearchBar class="SearchBarFocusAnalysis" v-if="comments.length > 0"
            :placeholder="$t('transcription_find_notes')" v-on:item-selected="itemSelected($event)"
            :autocompleteListItems="autoCompleteList" />
        <div class="VideoComments--Content WatScroll" v-if="comments.length > 0">
            <div class="VideoComments--Element"
                :class="{ 'active': currentSeconds >= c.secondsFromStart && (index + 1 == comments.length || currentSeconds < comments[index + 1].secondsFromStart) }"
                v-for="(c, index) in comments" v-on:click="$emit('click-frame', c.secondsFromStart)">
                <svg width="10" height="17" viewBox="0 0 10 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M0.0959473 2.641C0.0959473 2.13341 0.297588 1.6466 0.656512 1.28768C1.01544 0.928753 1.50224 0.727112 2.00984 0.727112H7.7515C8.2591 0.727112 8.7459 0.928753 9.10483 1.28768C9.46375 1.6466 9.66539 2.13341 9.66539 2.641V16.0382L4.88067 13.6459L0.0959473 16.0382V2.641Z"
                        fill="url(#paint0_linear_1331_249)" />
                    <defs>
                        <linearGradient id="paint0_linear_1331_249" x1="0.0959473" y1="8.38267" x2="9.66539"
                            y2="8.38267" gradientUnits="userSpaceOnUse">
                            <stop stop-color="#FF1564" />
                            <stop offset="1" stop-color="#FD7328" />
                        </linearGradient>
                    </defs>
                </svg>
                <div>
                    <p>{{ c.comment }}</p>
                    <span>{{ formatTimeFromSeconds(c.secondsFromStart) }}</span>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import SearchBar from '../SearchBar/SearchBar.vue';
export default {
    name: "VideoComments",
    components: {
        SearchBar
    },
    props: {
        comments: {
            type: Array,
            default: () => []
        },
        currentSeconds: {
            type: Number,
            default: 0
        },
        isExpandView: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        autoCompleteList() {
            return this.comments.map(el => el.comment)
        }
    },
    watch: {
        currentSeconds(newValue) {
            this.scrollToElement()
        }
    },
    data() {
        return {
            currentActiveElement: null
        }
    },
    methods: {
        itemSelected(event) {
            const selectedItem = this.comments.find(x => x.comment === event.text);
            if (selectedItem != undefined || selectedItem != null) {
                this.$emit('click-frame', selectedItem.secondsFromStart)
            }
        },
        scrollToElement() {
            const activeElement = document.querySelector('.VideoComments--Element.active')
            if (activeElement && activeElement != this.currentActiveElement) {
                this.currentActiveElement = activeElement
                activeElement.scrollIntoView({ behavior: 'smooth', block: 'center' })
            }
        },
        formatTimeFromSeconds(duration){
            const time = new Date(duration * 1000);
            let hours = (time.getHours() - 1).toString()
            let minutes = time.getMinutes().toString()
            let seconds = time.getSeconds().toString()
            if(hours.length < 2) hours = '0' + hours
            if(minutes.length < 2) minutes = '0' + minutes
            if(seconds.length < 2) seconds = '0' + seconds
            return `${hours}:${minutes}:${seconds}`
        }
    }
};
</script>
<style scoped lang="scss">
.VideoComments {
    height: 100%;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    gap: 20px;

    &--Header {
        display: flex;
        flex-direction: column;
        gap: 18px;

        h1 {
            font-family: 'Bariol Bold';
            font-weight: 700;
            font-size: 25px;
        }

        p {
            font-family: 'Bariol Bold';
            font-weight: 700;
            font-size: 18px;
        }

        span {
            background: linear-gradient(90deg, #FF1564 0%, #FD7328 100%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            background-clip: text;
        }
    }

    &--Content {
        height: 100%;
        font-family: 'Lato';
        font-weight: 700;
        display: flex;
        flex-direction: column;
        gap: 17px;
        padding-left: 3px;
    }

    .WatScroll{
        overflow-y: scroll;
        &::-webkit-scrollbar-track{
            background-color: #e6e6e6;
        }
        &::-webkit-scrollbar {
            width: 3px;
        }
        &::-webkit-scrollbar-thumb{
            border-radius: 10px;
            background:#8134FF;
        }
    }

    &--Element {
        display: flex;
        gap: 12px;
        padding: 15px;
        background: #FFFFFF;
        border-radius: 7px;
        align-items: flex-start;
        cursor: pointer;

        &:hover p,
        &.active p {
            color: var(--primary-color);
        }

        p {
            font-weight: 400;
            font-size: 16px;
            line-height: 16px;
            color: #626262;
            opacity: 0.8;
        }

        svg {
            min-width: fit-content;
        }

        span {
            font-family: 'Lato';
            font-weight: 700;
            font-size: 12px;
            background: linear-gradient(90deg, #FF1564 0%, #FD7328 100%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            background-clip: text;
        }
    }
}
</style>
