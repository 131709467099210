<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    height="20"
    viewBox="0 -960 960 960"
    width="20"
  >
    <path
      d="M578-135v-92q85-25 138.5-95T770-480q0-88-54-157t-138-97v-92q122 26 200.5 123T857-481q0 125-78 222.5T578-135ZM103-351v-260h164l239-239v738L267-351H103Zm475 33v-326q49 23 80 66t31 97q0 54-31 97t-80 66Z"
      fill="white"
    />
  </svg>
</template>
