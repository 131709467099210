
<template lang="html">
  <div class="ChartTypes">
    <div class="ChartTypes--Box" :class="{'selected':chartType == 'AVERAGE'}" v-show="showChart('AVERAGE')" v-on:click="changed('AVERAGE')">
      <img src="../../assets/img/WatMonitor/column.svg" style="transform:rotate(90deg);">
      <p><span>Promedio</span></p>
    </div>
    <div  class="ChartTypes--Box" :class="{'selected':chartType == 'COLUMN'}" v-show="showChart('COLUMN')" v-on:click="changed('COLUMN')">
      <img src="../../assets/img/WatMonitor/column.svg">
      <p><span>Columna</span></p>
    </div>
    <div class="ChartTypes--Box" :class="{'selected':chartType == 'PIE'}" v-show="showChart('PIE')" v-on:click="changed('PIE')">
      <img src="../../assets/img/WatMonitor/pie.svg" >
      <p><span>Tarta</span></p>
    </div>
    <div class="ChartTypes--Box" :class="{'selected':chartType == 'LIST'}" v-show="showChart('LIST')" v-on:click="changed('LIST')">
      <img class="ChartTypes--Box" style="padding: 4px;" src="../../assets/img/WatMonitor/listicon.svg">
      <p><span>Lista</span></p>
    </div>
    <div class="ChartTypes--Box" :class="{'selected':chartType == 'STACKED_BARS'}" v-show="showChart('STACKED_BARS')" v-on:click="changed('STACKED_BARS')">
      <img class="ChartTypes--Box" style="padding: 4px;" src="../../assets/img/WatMonitor/stackedicon.svg">
      <p><span>Columnas apiladas</span></p>
    </div>
    <div class="ChartTypes--Box" :class="{'selected':chartType == 'PERCENT_TABLE'}" v-show="showChart('PERCENT_TABLE')" v-on:click="changed('PERCENT_TABLE')">
      <img src="../../assets/img/WatMonitor/tablepercent.svg" >
      <p><span>Tabla de porcentajes</span></p>
    </div>
    <div class="ChartTypes--Box" :class="{'selected':chartType == 'VALUE_TABLE'}" v-show="showChart('VALUE_TABLE')" v-on:click="changed('VALUE_TABLE')">
      <img src="../../assets/img/WatMonitor/tablevalue.svg" >
      <p><span>Tabla de valores</span></p>
    </div>
    <div class="ChartTypes--Box" :class="{'selected':chartType == 'AVERAGE_TABLE'}" v-show="showChart('AVERAGE_TABLE')" v-on:click="changed('AVERAGE_TABLE')">
      <img src="../../assets/img/WatMonitor/tableaverage.svg" >
      <p><span>Tabla de promedio</span></p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ChartTypes',

  props: {
    question:Object,
    chartType:String,
  },
  methods:{
    changed(type){
      this.$emit('changed',type)
    },
    showChart(type){
      let averageQuestions  = ["RANKING","RATING_FACES","SORTING","DECIMAL_NUMBER","NUMBER","MULTINUMBER","DIVIDE_POINTS"]
      if(this.question.matrixType == 'REGULAR') averageQuestions.push('MATRIX')
      let columnQuestions   = ["INTERNAL_VARIABLE","SELECTION","RANKING","RATING_FACES","MULTISELECTION","IMAGE_CHOICE","MULTIPLEIMAGE_CHOICE","SORTING","MATRIX","DECIMAL_NUMBER","NUMBER","MULTINUMBER"]
      let pieQuestions      = ["INTERNAL_VARIABLE","SELECTION","RANKING","RATING_FACES","MULTISELECTION","IMAGE_CHOICE","MULTIPLEIMAGE_CHOICE","DECIMAL_NUMBER","NUMBER","MULTINUMBER"]
      let listQuestions     = ["TEXT","TEXTAREA","MULTITEXT","DATE","IMAGE_URL","DECIMAL_NUMBER","NUMBER","MULTINUMBER"]
      let tableQuestions    = ["INTERNAL_VARIABLE","SELECTION","RANKING","RATING_FACES","MULTISELECTION","IMAGE_CHOICE","MULTIPLEIMAGE_CHOICE","SORTING","MATRIX","DECIMAL_NUMBER","NUMBER","MULTINUMBER"]
      let stackedQuestions  = ["SORTING","MATRIX"]
      let taQuestions       = ["DIVIDE_POINTS"]

      if (type == "AVERAGE" && averageQuestions.includes(this.question.questionType)) return true
      if (type == "COLUMN" && columnQuestions.includes(this.question.questionType)) return true
      if (type == "PIE" && pieQuestions.includes(this.question.questionType)) return true
      if (type == "LIST" && listQuestions.includes(this.question.questionType)) return true
      if (type == "STACKED_BARS" && stackedQuestions.includes(this.question.questionType)) return true
      if (type == "PERCENT_TABLE" && tableQuestions.includes(this.question.questionType)) return true
      if (type == "VALUE_TABLE" && tableQuestions.includes(this.question.questionType)) return true
      if (type == "AVERAGE_TABLE" && taQuestions.includes(this.question.questionType)) return true
      return false
    },
  }

}
</script>

<style lang="scss" scoped>
.ChartTypes{
  display: flex;
  grid-template-columns: repeat(auto-fill, 37px);
  grid-gap: 8px;
  &--Box{
    width: 37px;
    height: 37px;
    background: #FFFFFF;
    border: 1px solid #E1E1E1;
    box-shadow: 2px 2px 1px rgba(0, 0, 0, 0.07);
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    cursor: pointer;
    &.selected{
      background: linear-gradient(90deg, #FF1564 0%, #FD7328 100%);
      img{
        filter: brightness(0) invert(1);
      }
    }
    &::after{
      content: '';
      position: absolute;
      left: 50%;
      width: 12px;
      height: 12px;
      top: -10px;
      transform: translate(-50%, -50%) rotate(45deg);
      background-color: white;
      border: 1px solid;
      border-color: transparent #FF1564 #FD7328 transparent;
      visibility: hidden;
      opacity: 0;
      transition: opacity 0.8s;
    }
    &:hover p, &:hover::after{
      visibility: visible;
      opacity: 1;
    }
    p{
      min-width: 90px;
      background: linear-gradient(90deg, #FF1564 0%, #FD7328 100%);
      font-size: 13px;
      left: 50%;
      font-family: 'Open Sans', Helvetica, Arial, sans-serif;
      top: -10px;
      transform: translate(-50%, -100%);
      color: #444444;
      border-radius: 6px;
      z-index: 1;
      position: absolute;
      visibility: hidden;
      opacity: 0;
      transition: opacity 0.8s;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0;
      span{
        width: 100%;
        height: 100%;
        margin:1px;
        background: white;
        border-radius: 6px;
        padding: 6px 9px;
        text-align: center;
        font-family: 'Roboto Condensed';
        font-weight: 600;
        text-transform: uppercase;
      }
    }
  }
}
</style>
