<template>
    <div class="AnalysisAddTopicModal">
        <transition name="fade">
            <div class="AnalysisAddTopicModal--Content" v-if="isOpen">
                    <h1>Añadir nuevo topic</h1>
                    <div class="AnalysisAddTopicModal--Info">
                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M10 6V10L13 13M19 10C19 11.1819 18.7672 12.3522 18.3149 13.4442C17.8626 14.5361 17.1997 15.5282 16.364 16.364C15.5282 17.1997 14.5361 17.8626 13.4442 18.3149C12.3522 18.7672 11.1819 19 10 19C8.8181 19 7.64778 18.7672 6.55585 18.3149C5.46392 17.8626 4.47177 17.1997 3.63604 16.364C2.80031 15.5282 2.13738 14.5361 1.68508 13.4442C1.23279 12.3522 1 11.1819 1 10C1 7.61305 1.94821 5.32387 3.63604 3.63604C5.32387 1.94821 7.61305 1 10 1C12.3869 1 14.6761 1.94821 16.364 3.63604C18.0518 5.32387 19 7.61305 19 10Z" stroke="var(--primary-color)" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                        <p>Al añadir un nuevo topic, el sistema necesita un tiempo de 1 día para recalcular los comentarios asociados.</p>
                    </div>
                    <div class="AnalysisAddTopicModal--Inputs">
                        <Input :label="'Nombre del topic'" :placeholder="'Ej.: Practicar deporte'" v-model="name" :error="nameError" />
                        <Input :label="'Palabras asociadas'" :placeholder="'Separa las palabras que quieres incluir por comas'" v-model="topicWords" :error="wordError" />
                    </div>
                    <div class="AnalysisAddTopicModal--Actions">
                        <button v-on:click="$emit('cancel')">Cancelar</button>
                        <button class="save" v-on:click="saveNewTopic()">Añadir topic</button>
                    </div>
            </div>
        </transition>
    </div>
  </template>
  <script>
  import Input from '@/components/CommunityConfig/Input.vue'
  export default {
    name: "AnalysisDetail",
    components:{
        Input
    },
    data(){
        return{
            name: '',
            topicWords: '',
            isOpen: false,
            nameError: false,
            wordError: false
        }
    },
    methods:{
        saveNewTopic(){
            this.nameError = this.name.length == 0
            this.wordError = this.topicWords.length == 0
            if(this.nameError || this.wordError) return
            this.$emit('save',{
                name: this.name,
                topicWords: this.topicWords.split(",")
            })
        }
    },
    mounted(){
        this.isOpen = true
    }
  };
  </script>
  <style lang="scss">
  .AnalysisAddTopicModal{
    .Input legend{
        color:var(--primary-color);
        -webkit-text-fill-color:inherit;
        background: transparent;
    }
  }
  </style>
  <style scoped lang="scss">
  .AnalysisAddTopicModal{
    width: 100%;
    height: 100%;
    position: absolute;
    top:0;
    left:0;
    background: rgba(0,0,0,0.7);
    z-index: 9999;
    display: flex;
    align-items: center;
    justify-content: center;
    &--Content{
        border-radius: 20px;
        background: white;
        padding: 24px;
        width: 500px;
        display: flex;
        flex-direction: column;
        position: absolute;
    }
    &--Info{
        padding: 16px;
        display: flex;
        background: #F6F0FF;
        border-radius: 4px;
        gap: 15px;
        font-family: 'Lato';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        margin: 12px 0 30px 0;
        p{
            width: fit-content;
        }
    }
    &--Inputs{
        display: flex;
        flex-direction: column;
        gap: 24px;
    }
    &--Actions{
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 30px;
    }
  }
  h1{
    font-family: 'Bariol';
    font-weight: 700;
    font-size: 24px;
  }
  button{
        padding: 12px 16px;
        height: 41px;
        background: #FFFFFF;
        border: 1px solid #EAEAEA;
        border-radius: 4px;
        text-transform: uppercase;
        font-family: 'Lato';
        font-weight: 900;
        font-size: 14px;
        cursor: pointer;
        &.save{
            color: white;
            background: var(--accent-color);
        }
    }
  .fade-enter-active,
  .fade-leave-active {
    transition: all 0.5s;
    bottom:0;
    max-height: 1000px;
    position: relative;
  }
  .fade-enter-from,
  .fade-leave-to
  {
    bottom:-1200px;
    max-height: 0;
  }
  </style>
  