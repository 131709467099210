import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, vModelDynamic as _vModelDynamic, withDirectives as _withDirectives, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["type", "placeholder", "disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("fieldset", {
    class: _normalizeClass(["Input", {'Input--Disabled':_ctx.disabled, 'Input--Error': _ctx.error}])
  }, [
    _createElementVNode("legend", null, _toDisplayString(_ctx.label), 1),
    _withDirectives(_createElementVNode("input", {
      type: _ctx.type,
      placeholder: _ctx.placeholder,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.value) = $event)),
      disabled: _ctx.disabled,
      onBlur: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('blur')))
    }, null, 40, _hoisted_1), [
      [_vModelDynamic, _ctx.value]
    ])
  ], 2))
}