<template>
  <div id="uxTranscriptionCommentList">
    <template v-for="(comment, index) in transcription">
      <UxTranscriptionCommentVue
        :id="'comment' + index"
        :index="index"
        :comment="comment.text"
        :second="comment.seconds"
        :isCurrentComment="
          currentTime >= comment.seconds &&
          (index + 1 == transcription.length ||
            currentTime < transcription[index + 1].seconds)
        "
        @goToSecond="goToSecond"
        @scrollToComment="scrollToComment"
      />
    </template>
  </div>
</template>
<script setup>
import UxTranscriptionCommentVue from "./UxTranscriptionComment.vue";

const props = defineProps({
  currentTime: String,
  transcription: {
    type: Array,
    default: null,
  }
});
const emit = defineEmits(["goToSecond","update-name"]);
const goToSecond = (second) => {
  emit("goToSecond", second);
};
const scrollToComment = (id) => {
  document
    .getElementById(id)
    .scrollIntoView({ behavior: "smooth", block: "center" });
};
</script>
<style lang="scss" scoped>
#uxTranscriptionCommentList {
  max-height: 55vh;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  gap: 20px;
  /* width */
  &::-webkit-scrollbar {
    width: 5px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px #626262;
    border-radius: 10px;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    //background: linear-gradient(90deg, #ff1564 0%, #fd7328 100%);
    background-color: #8134ff;
    border-radius: 10px;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: #8134ff;
  }
}
</style>
