<template>
  <div class="ActivityStatus" :class="{ active: status == 'ACTIVE',inactive: status == 'FINISHED' || status == 'NEW' || status == 'CONFIGURING' }">
    <div class="ActivityStatus--Circle"></div>
    <p class="ActivityStatus--Text">{{getStatusText()}}</p>
  </div>
</template>

<script>
export default {
  name: 'ActivityStatus',

  props: {
    status: {
      type: String,
      default: "ACTIVE",
    },
  },
  methods:{
    getStatusText(){
      let txt = ""
      switch (this.status) {
        case "ACTIVE":
          txt = this.$t('activity_stat_in_progress')
          break;
        case "NEW":
          txt = this.$t('activity_stat_coming_soon')
          break;
        case "FINISHED":
          txt = this.$t('activity_stat_finished')
          break;
        case "CONFIGURING":
          txt = this.$t('activity_stat_creating')
          break;
      }
      return txt
    },
  }
}
</script>

<style scoped lang="scss">
.ActivityStatus{
  display: flex;
  align-items: baseline;
  &--Circle{
    width: 10px;
    height: 10px;
    min-width: 10px;
    border-radius: 15px;
    margin-right: 5px;
  }
  &--Text{
    text-transform: uppercase;
    font-size: 14px;
  }
  &.active{
    color: #a1de5f;
    .ActivityStatus--Circle{
      background: #a1de5f;
    }
  }
  &.inactive{
    .ActivityStatus--Circle{
      background: #b8b8b8;
    }
    color: #b8b8b8;
  }
}
</style>
