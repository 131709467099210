<template lang="html">
  <div class="MonitorActions">
    <div class="MonitorActions--Wrapper" ref="questions">
      <div class="MonitorActions--Icon" :class="{'active':showMonitorQuestions.tab}" v-on:click="showMonitorQuestions.tab = !showMonitorQuestions.tab; showMonitorQuestions.list = false">
        <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M8.20088 14.7615H10.9891V16.4016H8.20088V14.7615ZM5.12551 14.5565C4.5593 14.5565 4.10044 15.0154 4.10044 15.5816C4.10044 16.1477 4.5593 16.6067 5.12551 16.6067C5.69172 16.6067 6.15058 16.1477 6.15058 15.5816C6.15058 15.0154 5.69172 14.5565 5.12551 14.5565ZM16.4836 9.55392H8.20088V11.1941H16.4836V9.55392ZM5.12551 9.349C4.5593 9.349 4.10044 9.80786 4.10044 10.3741C4.10044 10.9403 4.5593 11.3991 5.12551 11.3991C5.69172 11.3991 6.15058 10.9403 6.15058 10.3741C6.15058 9.80786 5.69172 9.349 5.12551 9.349ZM16.4836 4.63348H8.20088V6.27363H16.4836V4.63348ZM5.12551 4.42841C4.5593 4.42841 4.10044 4.88743 4.10044 5.45348C4.10044 6.01968 4.5593 6.47871 5.12551 6.47871C5.69172 6.47871 6.15058 6.01968 6.15058 5.45348C6.15058 4.88743 5.69172 4.42841 5.12551 4.42841ZM1.64014 18.5339V2.4603C1.64014 2.008 2.008 1.64014 2.4603 1.64014H18.5339C18.9861 1.64014 19.3539 2.008 19.3539 2.4603V11.7273H20.9941V2.4603C20.9941 1.10374 19.8905 0 18.5339 0H2.4603C1.10374 0 0 1.10374 0 2.4603V18.5339C0 19.8905 1.10374 20.9941 2.4603 20.9941H11.9322V19.3539H2.4603C2.008 19.3539 1.64014 18.9861 1.64014 18.5339ZM19.8402 21L18.2069 19.3667C17.6543 19.697 17.0088 19.887 16.3197 19.887C14.2848 19.887 12.6293 18.2314 12.6293 16.1967C12.6293 14.1618 14.2848 12.5062 16.3197 12.5062C18.3545 12.5062 20.01 14.1618 20.01 16.1967C20.01 16.9495 19.7831 17.6503 19.3946 18.2348L21 19.8402L19.8402 21ZM16.3197 18.2468C17.4502 18.2468 18.3699 17.3272 18.3699 16.1967C18.3699 15.0662 17.4502 14.1464 16.3197 14.1464C15.1892 14.1464 14.2694 15.0662 14.2694 16.1967C14.2694 17.3272 15.1892 18.2468 16.3197 18.2468Z" fill="url(#paint0_linear)"/>
          <defs>
          <linearGradient id="paint0_linear" x1="7.82311e-08" y1="10.5" x2="21" y2="10.5" gradientUnits="userSpaceOnUse">
          <stop stop-color="#FF1564"/>
          <stop offset="1" stop-color="#FD7328"/>
          </linearGradient>
          </defs>
        </svg>
        <p>{{$t('questions')}}</p>
      </div>
      <div class="MonitorActions--QuestionSelector" :class="{'show':showMonitorQuestions.tab}">
        <div class="MonitorActions--Selector" v-on:click="showMonitorQuestions.list = !showMonitorQuestions.list">
          <p>{{$t('monitor_go_question')}}</p>
        </div>
        <div class="MonitorActions--Questions" :class="{'show':showMonitorQuestions.list}">
          <p v-for="(q,i) of monitorQuestions" v-on:click="scrollWatMonitor(i)">{{i+1}}. {{q.questionText}}</p>
        </div>
      </div>
    </div>
    <div class="MonitorActions--Wrapper" v-on:click="$emit('open-filters')">
      <div class="MonitorActions--Icon">
        <svg width="21" height="19" viewBox="0 0 21 19" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M19.1543 1.50195H3.69141V0.271484H0V6.42383H3.69141V5.19336H19.1543C20.172 5.19336 21 4.36538 21 3.34766C21 2.32994 20.172 1.50195 19.1543 1.50195ZM2.46094 5.19336H1.23047V1.50195H2.46094V5.19336ZM19.1543 3.96289H3.69141V2.73242H19.1543C19.4935 2.73242 19.7695 3.00842 19.7695 3.34766C19.7695 3.6869 19.4935 3.96289 19.1543 3.96289Z" fill="url(#paint0_linear)"/>
          <path d="M17.3086 13.8066H1.8457C0.827982 13.8066 0 14.6346 0 15.6523C0 16.6701 0.827982 17.498 1.8457 17.498H17.3086V18.7285H21V12.5762H17.3086V13.8066ZM1.8457 16.2676C1.50646 16.2676 1.23047 15.9916 1.23047 15.6523C1.23047 15.3131 1.50646 15.0371 1.8457 15.0371H17.3086V16.2676H1.8457ZM18.5391 13.8066H19.7695V17.498H18.5391V13.8066Z" fill="url(#paint1_linear)"/>
          <path d="M19.1543 7.6543H12.3457V6.42383H8.6543V7.6543H1.8457C0.827982 7.6543 0 8.48228 0 9.5C0 10.5177 0.827982 11.3457 1.8457 11.3457H8.6543V12.5762H12.3457V11.3457H19.1543C20.172 11.3457 21 10.5177 21 9.5C21 8.48228 20.172 7.6543 19.1543 7.6543ZM1.8457 10.1152C1.50646 10.1152 1.23047 9.83924 1.23047 9.5C1.23047 9.16076 1.50646 8.88477 1.8457 8.88477H8.6543V10.1152H1.8457ZM11.1152 11.3457H9.88477V7.6543H11.1152V11.3457ZM19.1543 10.1152H12.3457V8.88477H19.1543C19.4935 8.88477 19.7695 9.16076 19.7695 9.5C19.7695 9.83924 19.4935 10.1152 19.1543 10.1152Z" fill="url(#paint2_linear)"/>
          <defs>
          <linearGradient id="paint0_linear" x1="7.82311e-08" y1="3.34766" x2="21" y2="3.34766" gradientUnits="userSpaceOnUse">
          <stop stop-color="#FF1564"/>
          <stop offset="1" stop-color="#FD7328"/>
          </linearGradient>
          <linearGradient id="paint1_linear" x1="7.82311e-08" y1="15.6523" x2="21" y2="15.6523" gradientUnits="userSpaceOnUse">
          <stop stop-color="#FF1564"/>
          <stop offset="1" stop-color="#FD7328"/>
          </linearGradient>
          <linearGradient id="paint2_linear" x1="7.82311e-08" y1="9.5" x2="21" y2="9.5" gradientUnits="userSpaceOnUse">
          <stop stop-color="#FF1564"/>
          <stop offset="1" stop-color="#FD7328"/>
          </linearGradient>
          </defs>
        </svg>
        <p>{{$t('monitor_filters')}}</p>
      </div>
    </div>
    <div class="MonitorActions--Wrapper" v-on:click="$emit('save')">
      <div class="MonitorActions--Icon">
        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M26.3125 25.375H5.6875C4.18437 25.375 2.76288 24.7921 1.68488 23.7335C0.598375 22.6667 0 21.2519 0 19.75C0 18.2481 0.598375 16.8333 1.68488 15.7665C2.53575 14.931 3.60063 14.3918 4.75075 14.2016C4.75025 14.1762 4.75 14.1507 4.75 14.125C4.75 9.9895 8.1145 6.625 12.25 6.625C13.7836 6.625 15.2574 7.08669 16.5121 7.96019C17.3954 8.57506 18.1287 9.36288 18.6738 10.2754C19.5538 9.72956 20.5687 9.4375 21.625 9.4375C24.4304 9.4375 26.7626 11.5016 27.1826 14.191C28.3582 14.371 29.4478 14.9148 30.3152 15.7665C31.4016 16.8333 32 18.248 32 19.75C32 21.252 31.4016 22.6667 30.3152 23.7335C29.2371 24.7921 27.8156 25.375 26.3125 25.375ZM5.6875 16C3.58525 16 1.875 17.6823 1.875 19.75C1.875 21.8177 3.58525 23.5 5.6875 23.5H26.3125C28.4147 23.5 30.125 21.8177 30.125 19.75C30.125 17.6823 28.4147 16 26.3125 16H25.375V15.0625C25.375 12.9948 23.6927 11.3125 21.625 11.3125C20.6396 11.3125 19.7075 11.695 19.0003 12.3896L17.9912 13.3807L17.4715 12.0651C16.6159 9.89944 14.5664 8.5 12.25 8.5C9.14837 8.5 6.625 11.0234 6.625 14.125C6.625 14.3465 6.65975 14.5843 6.6965 14.8361L6.86444 16H5.6875Z" fill="#96C800"/>
          <rect x="12" y="18" width="14" height="12" fill="white"/>
          <path d="M21.1094 27.8906H16.8906C16.6965 27.8906 16.5391 28.048 16.5391 28.2422C16.5391 28.4363 16.6965 28.5938 16.8906 28.5938H21.1094C21.3035 28.5938 21.4609 28.4363 21.4609 28.2422C21.4609 28.048 21.3035 27.8906 21.1094 27.8906Z" fill="url(#paint0_linear)"/>
          <path d="M21.1094 25.0781H16.8906C16.6965 25.0781 16.5391 25.2355 16.5391 25.4297C16.5391 25.6238 16.6965 25.7812 16.8906 25.7812H21.1094C21.3035 25.7812 21.4609 25.6238 21.4609 25.4297C21.4609 25.2355 21.3035 25.0781 21.1094 25.0781Z" fill="url(#paint1_linear)"/>
          <path d="M21.1094 26.4844H16.8906C16.6965 26.4844 16.5391 26.6418 16.5391 26.8359C16.5391 27.0301 16.6965 27.1875 16.8906 27.1875H21.1094C21.3035 27.1875 21.4609 27.0301 21.4609 26.8359C21.4609 26.6418 21.3035 26.4844 21.1094 26.4844Z" fill="url(#paint2_linear)"/>
          <path d="M20.7578 18H15.8359V21.0469H20.7578V18Z" fill="url(#paint3_linear)"/>
          <path d="M24.897 19.978L23.022 18.103C22.9561 18.0371 22.8667 18 22.7734 18H21.4609V21.3984C21.4609 21.5926 21.3035 21.75 21.1094 21.75H15.4844C15.2902 21.75 15.1328 21.5926 15.1328 21.3984V18H13.3516C13.1574 18 13 18.1574 13 18.3516V29.6484C13 29.8426 13.1574 30 13.3516 30C13.4604 30 24.5022 30 24.6484 30C24.8426 30 25 29.8426 25 29.6484V20.2266C25 20.1333 24.9629 20.0439 24.897 19.978ZM22.1641 29.2969H15.8359V24.375H22.1641V29.2969Z" fill="url(#paint4_linear)"/>
          <defs>
          <linearGradient id="paint0_linear" x1="16.5391" y1="28.2422" x2="21.4609" y2="28.2422" gradientUnits="userSpaceOnUse">
          <stop stop-color="#FF1564"/>
          <stop offset="1" stop-color="#FD7328"/>
          </linearGradient>
          <linearGradient id="paint1_linear" x1="16.5391" y1="25.4297" x2="21.4609" y2="25.4297" gradientUnits="userSpaceOnUse">
          <stop stop-color="#FF1564"/>
          <stop offset="1" stop-color="#FD7328"/>
          </linearGradient>
          <linearGradient id="paint2_linear" x1="16.5391" y1="26.8359" x2="21.4609" y2="26.8359" gradientUnits="userSpaceOnUse">
          <stop stop-color="#FF1564"/>
          <stop offset="1" stop-color="#FD7328"/>
          </linearGradient>
          <linearGradient id="paint3_linear" x1="15.8359" y1="19.5234" x2="20.7578" y2="19.5234" gradientUnits="userSpaceOnUse">
          <stop stop-color="#FF1564"/>
          <stop offset="1" stop-color="#FD7328"/>
          </linearGradient>
          <linearGradient id="paint4_linear" x1="13" y1="24" x2="25" y2="24" gradientUnits="userSpaceOnUse">
          <stop stop-color="#FF1564"/>
          <stop offset="1" stop-color="#FD7328"/>
          </linearGradient>
          </defs>
        </svg>
        <p>{{$t('action_save')}}</p>
      </div>
    </div>
    <div class="MonitorActions--Wrapper" v-on:click="scrollWatMonitor(null)">
      <div class="MonitorActions--Icon">
        <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M11 0C4.93423 0 0 4.93423 0 11C0 17.0658 4.93423 22 11 22C17.0658 22 22 17.0649 22 11C22 4.93509 17.0658 0 11 0ZM11 20.2959C5.8749 20.2959 1.7041 16.126 1.7041 11C1.7041 5.87404 5.8749 1.7041 11 1.7041C16.126 1.7041 20.2959 5.87404 20.2959 11C20.2959 16.126 16.1251 20.2959 11 20.2959Z" fill="url(#paint0_linear)"/>
          <path d="M15.8653 12.1758L11.605 7.91555C11.2727 7.58495 10.7359 7.58495 10.4036 7.91555L6.14335 12.1758C5.86986 12.4945 5.86986 12.9657 6.14335 13.2843C6.44925 13.6414 6.98774 13.6831 7.34476 13.3772L11.0001 9.72192L14.6639 13.3772C14.9962 13.7078 15.533 13.7078 15.8653 13.3772C16.1959 13.0449 16.1959 12.5081 15.8653 12.1758Z" fill="url(#paint1_linear)"/>
          <defs>
          <linearGradient id="paint0_linear" x1="8.19564e-08" y1="11" x2="22" y2="11" gradientUnits="userSpaceOnUse">
          <stop stop-color="#FF1564"/>
          <stop offset="1" stop-color="#FD7328"/>
          </linearGradient>
          <linearGradient id="paint1_linear" x1="5.93823" y1="10.6464" x2="16.1133" y2="10.6464" gradientUnits="userSpaceOnUse">
          <stop stop-color="#FF1564"/>
          <stop offset="1" stop-color="#FD7328"/>
          </linearGradient>
          </defs>
        </svg>
        <p>{{$t('monitor_go_up')}}</p>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, getCurrentInstance } from "vue";
import { onClickOutside } from "@vueuse/core";
export default {
  name: "MonitorActions",
  props: {
    monitorQuestions: Array,
  },

  methods: {
    scrollWatMonitor(questionIndex) {
      if (questionIndex == null) $(".Activity").animate({ scrollTop: 0 }, 500);
      else {
        $("#MonitorQuestion--" + questionIndex)[0].scrollIntoView({
          block: "center",
          behavior: "smooth",
        });
      }
    },
  },
  setup() {
    const showMonitorQuestions = ref({
      tab: false,
      list: false,
    });
    const questions = ref(null);
    onClickOutside(questions, (event) => {
      showMonitorQuestions.value.tab = false;
    });
    return {
      questions,
      showMonitorQuestions,
    };
  },
};
</script>
<style lang="scss" scoped>
.MonitorActions {
  position: fixed;
  right: 0;
  top: 220px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  svg {
    margin: 8px 0 5px 0;
  }
  p {
    margin: 0 0 2px 0;
  }
  &--Wrapper {
    display: flex;
    align-items: center;
    box-shadow: 0px 0px 14px rgba(0, 0, 0, 0.25);
    margin-bottom: 3px;
    height: 50px;
  }
  &--QuestionSelector {
    background: white;
    position: relative;
    width: 0px;
    visibility: hidden;
    opacity: 0;
    transition: visibility 0.5s, width 0.5s, opacity 0.5s linear;
    &.show {
      width: 255px;
      visibility: visible;
      opacity: 1;
    }
  }
  &--Questions {
    position: absolute !important;
    top: 41px;
    width: calc(100% - 28px);
    right: 14px;
    border: 1px solid #e1e1e1;
    background: white;
    visibility: hidden;
    opacity: 0;
    height: 0px;
    transition: visibility 0.5s, height 0.5s, opacity 0.5s linear;
    &::-webkit-scrollbar-track {
      background-color: #e6e6e6;
    }
    &::-webkit-scrollbar {
      background-color: #8134ff;
      width: 3px;
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 10px;
      background: var(--primary-color);
    }
    &.show {
      max-height: 160px;
      height: fit-content;
      overflow-y: auto;
      visibility: visible;
      opacity: 1;
    }
    p {
      cursor: pointer;
      font-family: Lato;
      font-style: normal;
      font-weight: normal;
      font-size: 13px;
      letter-spacing: 0.02em;
      color: #939393;
      padding: 10px;
      &:hover {
        background: #f2f2f2;
      }
    }
  }
  &--Icon {
    background: #ffffff;
    box-shadow: 0px 0px 14px rgba(0, 0, 0, 0.25);
    width: 48px;
    font-family: Roboto Condensed;
    font-style: normal;
    font-weight: bold;
    font-size: 11px;
    text-align: center;
    letter-spacing: -0.01em;
    color: #404040;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    cursor: pointer;
    height: 100%;
    &.active {
      background: #f2f2f2;
      box-shadow: 0px 0px 0px rgba(0, 0, 0, 0.25);
    }
  }
  &--Selector {
    border: 1px solid #e1e1e1;
    box-shadow: 2px 2px 1px rgba(0, 0, 0, 0.07);
    margin: 9px 14px;
    cursor: pointer;
    &:before {
      border-style: solid;
      cursor: pointer;
      border-color: #404040;
      border-width: 1px 1px 0 0;
      content: "";
      display: inline-block;
      height: 6px;
      position: absolute;
      transform: rotate(-45deg);
      vertical-align: top;
      width: 6px;
      right: 24px;
      top: 21px;
      transform: rotate(135deg);
    }
    p {
      font-family: Lato;
      font-style: normal;
      font-weight: bold;
      font-size: 12px;
      letter-spacing: 0.01em;
      color: #404040;
      padding: 7px 9px;
      width: 160px;
    }
  }
}
</style>
