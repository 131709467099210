<template>
  <div id="uxVideoActions">
    <IconWebCamActive v-show="isWebCamActive" @click="toggleWebCam" />
    <IconWebCamInActive v-show="!isWebCamActive" @click="toggleWebCam" />
    <IconAnnotationActive
      v-show="isAnnotationActive"
      @click="toggleAnnotation"
    />
    <IconAnnotationInActive
      v-show="!isAnnotationActive"
      @click="toggleAnnotation"
    />
  </div>
</template>
<script setup>
import { ref } from "vue";
import IconAnnotationActive from "../icons/IconAnnotationActive.vue";
import IconAnnotationInActive from "../icons/IconAnnotationInActive.vue";
import IconWebCamActive from "../icons/IconWebCamActive.vue";
import IconWebCamInActive from "../icons/IconWebCamInActive.vue";

const emit = defineEmits(["toggleShowAnnotation", "toggleShowWebCam"]);

const isAnnotationActive = ref(true);
const isWebCamActive = ref(true);

const toggleAnnotation = () => {
  isAnnotationActive.value = !isAnnotationActive.value;
  emit("toggleShowAnnotation", isAnnotationActive.value);
};
const toggleWebCam = () => {
  isWebCamActive.value = !isWebCamActive.value;
  emit("toggleShowWebCam", isWebCamActive.value);
};
</script>
<style lang="scss" scoped>
#uxVideoActions {
  display: flex;
  flex-direction: row;
  gap: 20px;
  > svg {
    cursor: pointer;
  }
}
</style>
