<template>
    <div class="AnalysisTopicList">
        <h1>Selecciona el topic</h1>
        <div class="AnalysisTopicList--List">
            <div class="AnalysisTopicList--Element" :class="{'disabled':t.moderatorPending, 'selected':selectedTopics.includes(t.topicId)}" v-for="t in topics" v-on:click="selectTopic(t)">
                <p>{{ t.name }}</p>
                <span v-if="t.moderator && !t.moderatorPending">Personalizado</span>
                <span v-if="t.moderatorPending" class="pending">Analizando</span>
                <svg v-show="selectedTopics.includes(t.topicId)" width="15" height="11" viewBox="0 0 15 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                 <path fill-rule="evenodd" clip-rule="evenodd" d="M14.329 0.415223C14.5165 0.602751 14.6218 0.857059 14.6218 1.12222C14.6218 1.38739 14.5165 1.6417 14.329 1.82922L6.32899 9.82922C6.14146 10.0167 5.88715 10.122 5.62199 10.122C5.35682 10.122 5.10252 10.0167 4.91499 9.82922L0.914989 5.82922C0.732831 5.64062 0.632037 5.38802 0.634315 5.12582C0.636594 4.86363 0.741763 4.61281 0.927171 4.4274C1.11258 4.242 1.36339 4.13683 1.62559 4.13455C1.88778 4.13227 2.14039 4.23307 2.32899 4.41522L5.62199 7.70822L12.915 0.415223C13.1025 0.227752 13.3568 0.122437 13.622 0.122437C13.8872 0.122437 14.1415 0.227752 14.329 0.415223Z" fill="white"/>
                </svg>
                <i v-show="t.moderator" v-on:click="deleteTopic(t)" class="fas fa-trash"></i>
            </div>
        </div>
        <button v-on:click="$emit('add-topic')">
            <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M5.9999 0.0222168C6.21208 0.0222168 6.41556 0.106502 6.56559 0.256531C6.71562 0.40656 6.7999 0.610044 6.7999 0.822217V4.82222H10.7999C11.0121 4.82222 11.2156 4.9065 11.3656 5.05653C11.5156 5.20656 11.5999 5.41004 11.5999 5.62222C11.5999 5.83439 11.5156 6.03787 11.3656 6.1879C11.2156 6.33793 11.0121 6.42222 10.7999 6.42222H6.7999V10.4222C6.7999 10.6344 6.71562 10.8379 6.56559 10.9879C6.41556 11.1379 6.21208 11.2222 5.9999 11.2222C5.78773 11.2222 5.58425 11.1379 5.43422 10.9879C5.28419 10.8379 5.1999 10.6344 5.1999 10.4222V6.42222H1.1999C0.987729 6.42222 0.784246 6.33793 0.634217 6.1879C0.484188 6.03787 0.399902 5.83439 0.399902 5.62222C0.399902 5.41004 0.484188 5.20656 0.634217 5.05653C0.784246 4.9065 0.987729 4.82222 1.1999 4.82222H5.1999V0.822217C5.1999 0.610044 5.28419 0.40656 5.43422 0.256531C5.58425 0.106502 5.78773 0.0222168 5.9999 0.0222168Z" fill="white"/>
            </svg>
            Añadir nuevo topic
        </button>
    </div>
  </template>
  <script>
  import Swal from 'sweetalert2'
  export default {
    name: "AnalysisTopicList",
    props: {
      topics: Array
    },
    data(){
        return{
            selectedTopics:[],
            isNewTopicEnabled: false
        }
    },
    methods:{
        selectTopic(topic){
            if(topic.moderatorPending) return
            this.selectedTopics[0] = topic.topicId
            this.$emit('selected', topic.topicId)
            //if(this.selectedTopics.includes(topicId)) this.selectedTopics.splice(this.selectedTopics.indexOf(topicId),1)
            //else this.selectedTopics.push(topicId)
        },
        deleteTopic(topic){
            let vm = this
            Swal.fire({
                html: 'Se eliminará el topic <b>' + topic.name + '</b>',
                icon:'warning',
                customClass:{
                    popup:'swal-customWarning'
                },
                showCancelButton: true,
                confirmButtonText: 'Eliminar',
                cancelButtonText: vm.$t('action_cancel'),
            }).then((result) => {
                /* Read more about isConfirmed, isDenied below */
                if (result.isConfirmed) {
                    this.$emit('delete', topic.topicId)
                }
            })
            
        }
    }
  };
  </script>
  <style scoped lang="scss">
  .AnalysisTopicList{
    padding: 25px;
    gap: 14px;
    width: 334px;
    min-width: 334px;
    background: #F8F8F8;
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    height: 100%;
    &--List{
        display: flex;
        flex-direction: column;
        gap: 13px;
        overflow: auto;
        padding-right: 5px;
        &::-webkit-scrollbar-track{
            background-color: #e6e6e6;
        }
        &::-webkit-scrollbar {
            background: var(--primary-color);
            width: 3px;
        }
        &::-webkit-scrollbar-thumb{
            border-radius: 20px;
            background: var(--primary-color);
        }
    }
    &--Element{
        padding: 9px 16px;
        gap: 8px;
        background: #FFFFFF;
        border: 2px solid #FFFFFF;
        border-radius: 6px;
        font-family: 'Lato';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: space-between;
        cursor: pointer;
        &.selected{
            background: var(--primary-color);
            color:white;
            transition: 0.5s;
        }
        &.disabled{
            p{
                opacity: 0.3;
            }
            &:after{
                display: none;
            }
        }
        &:after{
            position: absolute;
            content: "";
            height: 100%;
            width: 4px;
            background: linear-gradient(90deg, var(--primary-color) 0.55%, var(--primary-color) 100%), var(--primary-color);
            border-top-left-radius: 6px;
            border-bottom-left-radius: 6px;
            top:0px;
            left:0px;
        }
    }
  }

  span{
    padding: 2px 4px;
    height: 16px;
    background: #8134FF;
    border-radius: 4px;
    font-family: 'Lato';
    font-style: normal;
    font-weight: 900;
    font-size: 10px;
    color: white;
    text-transform: uppercase;
    &.pending{
        background: #F09B2E;
    }
  }
  i{
    color: red;
    opacity: 0.7;
    cursor: pointer;
  }
  h1{
    font-family: 'Bariol';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
  }

  button{
    padding: 10px 18px;
    gap: 4px;
    height: 36px;
    background: var(--accent-color);
    border: 1px solid var(--accent-color);
    color: white;
    margin-top: 25px;
    border-radius: 20px;
    cursor: pointer;
  }

  </style>
  