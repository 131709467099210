<template>
    <div class="AnalysisComment">
        <div>
            <svg width="16" height="12" viewBox="0 0 16 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M11 11.5L13 7.5H9V0.5H16V7.5L14 11.5H11ZM2 11.5L4 7.5H0V0.5H7V7.5L5 11.5H2Z" fill="white"/>
            </svg>
        </div>
        <p style="margin:0;" v-html="comment.commentText"></p>
    </div>
  </template>
  <script>
  export default {
    name: "AnalysisComment",
    props: {
        comment:{
            type: Object,
        }
    }
  };
  </script>
  <style scoped lang="scss">
  .AnalysisComment{
    border: 1px solid #C8C8C8;
    border-radius: 4px;
    padding: 24px 16px 18px;
    position: relative;
    div{
        background: var(--primary-color);
        height: 32px;
        width: 32px;
        position: absolute;
        top: -16px;
        left: 12px;
        border-radius: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    p{
        font-family: 'Lato';
        font-style: italic;
        font-weight: 400;
        font-size: 16px;
        color: #626262;
    }
  }
  </style>
  