<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    height="20"
    viewBox="0 -960 960 960"
    width="20"
  >
    <path
      d="M778-58 669-169q-18 10-44 20t-48 14v-91q4-2 13-5.5t14-5.5l-99-101v228L266-349H102v-260h140L56-802l61-61 723 743-62 62Zm20-230-63-65q16-28 25-60t9-66q0-88-54.5-157T577-733v-91q123 24 201.5 122T857-479q0 52-15.5 99.5T798-288ZM675-414l-98-100v-129q49 22 80.5 66t31.5 98q0 17-3 33.5T675-414ZM505-589 378-721l127-127v259Z"
      fill="white"
    />
  </svg>
</template>
