<template lang="html">
  <div class="QuestionSelectionModal">
    <div class="QuestionSelectionModal--Box">
      <div class="QuestionSelectionModal--Header">
        <div class="">
          <h1>{{title}}</h1>
          <h2>{{subTitle}}</h2>
        </div>
        <div class="" v-if="multipleSelection">
          {{$t('watmonitor_select_all')}}
          <CheckBox v-model="selectAll" v-on:change="selectAllOptions($event)"/>
        </div>
      </div>
      <div class="QuestionSelectionModal--QuestionList">
        <div v-for="q in socioDemographicQuestions" v-show="showFilter(q.taskNum,q.questionNum) && showSocioDemographics" class="QuestionSelectionModal--Question" v-on:click="selectQuestion(q)">
          <div>
            <QuestionIMG class="QuestionSelectionModal--QuestionLogo" :name="q.icon" />
            <h1>{{q.text}}</h1>
          </div>
          <CheckBox v-if="multipleSelection" v-model="q.selected" />
        </div>
        <div v-show="showFilter(q.taskNum,q.id)" class="QuestionSelectionModal--Question" v-for="(q,i) in questionsList" v-on:click="selectQuestion(q)">
          <div>
            <QuestionIMG class="QuestionSelectionModal--QuestionLogo" v-if="q.type != 'RANKING'" :name="q.type" />
            <QuestionIMG class="QuestionSelectionModal--QuestionLogo" v-if="q.type == 'RANKING'" :name="q.type+'-STARS'" />
            <h1>{{i+1}}. {{q.text}}</h1>
          </div>
          <CheckBox v-if="multipleSelection" v-model="q.selected" />
        </div>
      </div>
      <div class="QuestionSelectionModal--Footer">
        <button type="button" name="button" v-on:click="selectMultipleQuestions()" v-if="multipleSelection">{{$t('watmonitor_add')}} <span> ({{getSelectedQuestions().length}} {{$t('watmonitor_questions')}})</span></button>
        <button type="button" name="button" v-on:click="close()">{{$t('watmonitor_action_close')}}</button>
      </div>
    </div>
  </div>
</template>
<script>
import { inject } from "vue";
import QuestionIMG from '#/components/Question/QuestionIMG'
import CheckBox from './CheckBox'
export default {
  name: 'QuestionSelectionModal',

  props: {
    title:String,
    subTitle: String,
    questions:Array,
    currentQuestion:Object,
    showSocioDemographics:{
      type: Boolean,
      default: false
    },
    multipleSelection:{
      type: Boolean,
      default:false,
    }
  },
  components:{
    QuestionIMG,
    CheckBox,
  },
  data(){
    return{
      socioDemographicQuestions:[
        {text:this.$t('watmonitor_gender'),taskNum:-1,questionNum:3,icon:'GENDER',selected:false,},
        {text:this.$t('watmonitor_age'),taskNum:-1,questionNum:2,icon:'AGE',selected:false},
        {text:this.$t('watmonitor_province'),taskNum:-1,questionNum:1,icon:'PROVINCE',selected:false}
      ],
      questionsList:[],
      selectAll:false
    }
  },
  created(){
    let questions = JSON.parse(JSON.stringify(this.questions))
    this.questionsList = questions.map(function(q,i){
      q.selected = false
      return q
    })
  },
  methods: {
    selectMultipleQuestions(){
      this.$emit('select-question-multiple',this.getSelectedQuestions())
    },
    selectAllOptions(e){
      let selectAll = this.selectAll
      this.questionsList = this.questionsList.map(function(q,i){
        q.selected = !selectAll
        return q
      })
      this.socioDemographicQuestions = this.socioDemographicQuestions.map(function(q,i){
        q.selected = !selectAll
        return q
      })
    },
    getSelectedQuestions(){
      let questions = []
      questions = this.questionsList.filter(function(q,i){return q.selected })
      questions = questions.concat(this.socioDemographicQuestions.filter(function(q,i){return q.selected }))
      return questions
    },
    showFilter(taskNum,questionId){
      if(this.currentQuestion == undefined) return true
      if(this.currentQuestion.taskNum == taskNum && this.currentQuestion.questionId == questionId) return false
      return true
    },
  },
  setup (props, { emit }) {
    const emitter = inject("emitter")
    const close = (event) => {
      emit("close")
      if(emitter != undefined) emitter.emit("close-question-selection-modal")
    }

    const selectQuestion = (event) => {
      let q = event
      q.selected = !q.selected
      emit('select-question', q)
      if(emitter != undefined) emitter.emit("select-question-selection-modal", q)
    }

    return {
        close,
        selectQuestion
    }
  }
}
</script>

<style lang="scss">
.QuestionSelectionModal--QuestionLogo img{
  width: 28px;
  height: 28px;
}
.QuestionSelectionModal .CheckBox span{
  width: 27px;
  height: 27px;
  &:after{
    width: 9px;
    height: 17px;
    left: 8px;
    top: 2px;
  }
}
.QuestionSelectionModal--Header .CheckBox{
  margin-bottom: 22px;
  margin-left: 10px;
}
.QuestionSelectionModal--Question .CheckBox{
  position: absolute;
  top: 20px;
  right: 20px;
}
</style>
<style lang="scss" scoped>
@import url('https://fonts.googleapis.com/css?family=Lato:400,700');
.QuestionSelectionModal{
  position: fixed;
  width: 100%;
  height: 100%;
  top:0;
  left:0;
  background: rgba(0,0,0,0.35);
  z-index: 10000;
  display: flex;
  align-items: center;
  justify-content: center;
  h1,h2{
    font-family: Lato;
    font-style: normal;
    font-weight: bold;
    letter-spacing: 0.01em;
    color: #404040;
    margin: 0 0 9px 0;
  }
  h1{
    font-size: 23px;
  }
  h2{
    font-size: 15px;
  }
  &--Header{
    display: flex;
    justify-content: space-between;
    align-items: center;
    div:last-of-type{
      font-family: Lato;
      font-style: normal;
      font-weight: normal;
      font-size: 11px;
      display: flex;
      align-items: center;
      letter-spacing: 0.02em;
      text-transform: uppercase;
      color: #939393;
      display: flex;
      align-items: center;
      margin-right: 35px;
    }
  }
  &--Box{
    background: #FFFFFF;
    padding: 20px 30px;
    width: 70%;
  }
  &--Footer{
    display: flex;
    justify-content: flex-end;
    margin-top: 10px;
    button{
      font-family: Lato;
      font-size: 12px;
      font-weight: bold;
      color: white;
      padding: 10px 20px;
      border-radius: 2px;
      letter-spacing: 0.02em;
      text-transform: uppercase;
      margin: 10px 0;
      cursor: pointer;
      background: #96C800;
      box-shadow: 2px 2px 0px rgb(0 0 0 / 14%);
      position: relative;
      transition-duration: 0.4s;
      text-transform: uppercase;
      display: flex;
      align-items: center;
      overflow: hidden;
      justify-content: center;
      border: none;
      span{
        text-transform: lowercase;
      }
      &:before {
        content:"";
        position: absolute;
        top: 0px;
        left: 0px;
        width: 0px;
        height: 0px;
        background: rgba(255, 255, 255, 0.2);
        transition: width 0.5s, height 0.5s;
      }
      &:hover:before {
        width: 100%;
        height: 100%;
      }
     &:first-of-type{
       background: #96C800;
       margin-right: 15px;
       width: fit-content;
     }
     &:last-of-type{
       background: #747474;
     }
    }
  }
  &--QuestionList{
    margin-top: 20px;
    max-height: calc(100vh - 200px);
    overflow-y: auto;
    padding-right: 10px;
    &::-webkit-scrollbar-track{
      background-color: #e6e6e6;
    }
    &::-webkit-scrollbar {
      background: linear-gradient(90deg, #FF1564 0%, #FD7328 100%);
      width: 5px;
    }
    &::-webkit-scrollbar-thumb{
      border-radius: 20px;
      background: linear-gradient(90deg, #FF1564 0%, #FD7328 100%);
    }
  }
  &--Question{
    display: flex;
    justify-content: space-between;
    background: #FFFFFF;
    border: 1px solid #F7F4F8;
    box-shadow: 0px 5px 8px rgba(0, 0, 0, 0.06);
    margin-bottom: 15px;
    padding: 20px;
    cursor: pointer;
    position: relative;
    div:nth-child(2){
      width: 100%;
    }
    div{
      display: flex;
    }
    h1{
      font-size: 20px;
      margin:0 0 0 20px;
    }
  }
  @media (max-width: 768px) {
    &--Box{
      width: 95%;
      padding: 15px 20px;
    }
  }
}
</style>
