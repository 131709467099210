<template>
  <div id="uxVideoPlayer">
    <div class="top" :class="{ webCamHidded: !showWebCam }">
      <div class="screen">
        <video
          muted
          ref="screenVideo"
          @loadeddata="handleVideoLoaded"
          @timeupdate="handleTimeUpdate"
          :src="screenVideoUrl"
          :class="{ adjusted: needsScreenVideoAdjustment }"
        ></video>
      </div>
      <div class="camera" v-if="webcamVideoUrl !== ''" v-show="showWebCam">
        <video
          ref="cameraVideo"
          @loadeddata="handleVideoLoaded"
          @timeupdate="handleTimeUpdate"
          :src="webcamVideoUrl"
        ></video>
      </div>
    </div>
    <div class="bottom">
      <UxVideoControls
        :currentTime="currentTime"
        :videoDuration="videoDuration"
        :progressPercent="progressPercent"
        :notes="notes"
        :videoTotalSeconds="videoTotalSeconds"
        @progressChanged="goToFrame"
        @play="playVideo"
        @stop="stopVideo"
        @toggleMute="toggleMute"
      />
      <UxVideoActions
        @toggleShowWebCam="toggleShowWebCam"
        @toggleShowAnnotation="toggleShowAnnotation"
      />
    </div>
  </div>
</template>
<script setup>
import UxVideoControls from "./UxVideoControls.vue";
import UxVideoActions from "./UxVideoActions.vue";
import { ref, onMounted } from "vue";

const props = defineProps({
  notes: {
    type: Array,
    default: {},
  },
  screenVideoUrl: {
    type: String,
    default: "",
  },
  webcamVideoUrl: {
    type: String,
    default: "",
  },
  isMobile: {
    type: Boolean,
    default: false
  },
  isIos: {
    type: Boolean,
    default: false
  }
});
const emit = defineEmits(["toggleShowAnnotation", "currentTimeChanged", "videoPlayed"]);

const screenVideo = ref(null);
const cameraVideo = ref(null);
const currentTime = ref("00:00:00");
const videoDuration = ref("00:00:00");
const videoTotalSeconds = ref(0);
const progressPercent = ref(0);
const showWebCam = ref(true);
const needsScreenVideoAdjustment = ref(false);

onMounted(()=>{
  screenVideo.value.addEventListener('loadedmetadata', () => {
    if (screenVideo.value.videoHeight > screenVideo.value.videoWidth) {
      needsScreenVideoAdjustment.value = true;
    }
  });
});

const toggleShowWebCam = (value) => {
  showWebCam.value = value;
};
const toggleShowAnnotation = (value) => {
  emit("toggleShowAnnotation", value);
};

const createTime = (duration) => {
  const time = new Date(duration * 1000);
  let hours = (time.getHours() - 1).toString();
  let minutes = time.getMinutes().toString();
  let seconds = time.getSeconds().toString();
  if (hours.length < 2) hours = "0" + hours;
  if (minutes.length < 2) minutes = "0" + minutes;
  if (seconds.length < 2) seconds = "0" + seconds;
  return `${hours}:${minutes}:${seconds}`;
};
const handleVideoLoaded = () => {
  videoTotalSeconds.value = screenVideo.value.duration;
  videoDuration.value = createTime(screenVideo.value.duration);
  currentTime.value = createTime(screenVideo.value.currentTime);
  emit('videoLoaded', createTime(screenVideo.value.duration));
};
const handleTimeUpdate = () => {
  currentTime.value = createTime(screenVideo.value.currentTime);
  progressPercent.value = isNaN(
    (screenVideo.value.currentTime / screenVideo.value.duration) * 100
  )
    ? 0
    : (screenVideo.value.currentTime / screenVideo.value.duration) * 100;
  emit("currentTimeChanged", screenVideo.value.currentTime);
};
const goToFrame = (progressPercent) => {
  const currentSeconds = (screenVideo.value.duration / 100) * progressPercent;
  goToSecond(currentSeconds);
};
const goToSecond = (second) => {
  screenVideo.value.currentTime = second;
  if (cameraVideo.value) {
    cameraVideo.value.currentTime = second;
  }
};
const playVideo = () => {
  emit("videoPlayed");
  screenVideo.value.play();
  screenVideo.value.muted = false; 
  if (cameraVideo.value) {
    cameraVideo.value.play();
    cameraVideo.value.muted = false;
    screenVideo.value.muted = true; 
  }
  
};
const stopVideo = () => {
  screenVideo.value.pause();
  if (cameraVideo.value) {
    cameraVideo.value.pause();
  }
};
const toggleMute = (value) => {
  screenVideo.value.muted = value;
  if (cameraVideo.value) {
    cameraVideo.value.muted = value;
  }
};
defineExpose({
  goToSecond,
});
</script>
<style lang="scss" scoped>
#uxVideoPlayer {
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  background-color: black;
  border-radius: 12px;
  height: 100%;
  .top {
    display: grid;
    grid-template-columns: 80% 20%;
    transition: grid-template-columns 0.5s;
    &.webCamHidded {
      grid-template-columns: 100% 0%;
    }
    gap: 10px;
    width: 100%;
    height: 90%;
    .screen {
      height: fit-content;
      display: flex;
      justify-content: center;
      align-items: center;
      > video {
        width: 100%;
        height: auto;
        border-radius: 10px;
        &.adjusted{
          max-width: 30%;
        }
      }
    }
    .camera {
      height: fit-content;
      display: flex;
      justify-content: center;
      align-items: flex-start;
      > video {
        width: 100%;
        height: auto;
        border-radius: 10px;
      }
    }
  }
  .bottom {
    height: 10%;
    display: flex;
    flex-direction: row;
    gap: 60px;
  }
}
</style>
