<template>
  <div id="uxTranscription">
    <div  v-if="props.transcription != null" class="header">
      <h1>Transcripción completa</h1>
      <!-- <div class="download">
        <IconDownload />
        <p>Descargar</p>
      </div> -->
    </div>
    <UxTranscripting :activityType="activityType" v-if="props.transcription === null "/>
    <template v-else>
      <UxAnnotationSearchBar
        @search="filterTranscriptionComment"
        @cleanSearch="cleanFilter"
        placeHolder="Buscar en la transcripción "
      />
      <UxTranscriptionCommentList
        :currentTime="currentTime"
        :transcription="filteredTranscription"
        @goToSecond="goToSecond"
      />
    </template>
  </div>
</template>
<script setup>
import IconDownload from '../icons/IconDownload.vue'
import UxTranscripting from './UxTranscripting.vue'
import UxTranscriptionCommentList from './UxTranscriptionCommentList.vue'
import UxAnnotationSearchBar from './UxAnnotationSearchBar.vue'
import { ref, computed } from 'vue'
const props = defineProps({
  currentTime: String,
  activityType:String,
  transcription: {
    type: Array,
    default: null,
  },
})
const emit = defineEmits(['goToSecond'])
console.log(props.transcription)

const filteredTranscription = ref(props.transcription)

const goToSecond = (second) => {
  emit('goToSecond', second)
}
const filterTranscriptionComment = (text) => {
  // Filtrar los comentarios según el texto de búsqueda
  if (!text) {
    // Si el texto está vacío, mostrar todos los comentarios
    filteredTranscription.value = props.transcription
  } else {
    filteredTranscription.value = props.transcription.filter((comment) =>
      comment.text.toLowerCase().includes(text.toLowerCase())
    )
  }
}

const cleanFilter = () => {
  // Limpiar el filtro para mostrar todos los comentarios
  filteredTranscription.value = props.transcription
}


</script>
<style lang="scss" scoped>
#uxTranscription {
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: 20px;
  .header {
    display: flex;
    flex-direction: row;
    align-items: center;
    h1 {
      font-family: 'Bariol';
      font-style: normal;
      font-weight: 700;
      font-size: 30px;
      color: #1f1f1f;
      margin: 0px;
    }
    .download {
      margin-left: auto;
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 10px;
      padding: 8px 16px;
      p {
        font-family: 'Lato';
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 19px;
        color: #8134ff;
      }
    }
  }
}
</style>
