<template lang="html">
  <div class="HeatMapResults">
    <div :id="'HeatMapActions_'+_uid" class="HeatMapResults--Actions" :class="{'show':showActions}">
      <div>
        <p :class="{'selected':selectedMap == 0}" v-on:click="selectedMap = 0;initHeatMapViewer();initHeatMapViewerFake()">{{$t('watmonitor_positive')}} <i class="fa fa-thumbs-up" style="color:green"></i></p>
        <p :class="{'selected':selectedMap == 1}" v-on:click="selectedMap = 1;initHeatMapViewer();initHeatMapViewerFake()">{{$t('watmonitor_negative')}} <i class="fa fa-thumbs-down" style="color:red"></i></p>
        <p :class="{'selected':selectedMap == index + 2}" v-on:click="selectedMap = index + 2;initHeatMapViewer();initHeatMapViewerFake()" v-for="(hmpd,index) in heatmapPointDefinitions">{{index+1}}. {{hmpd.title}}</p>
      </div>
    </div>
    <section class="Section Section--Results">
      <div class="HeatMap" :class="'HeatMap--HeatMap'+_uid">
        <img class="HeatMapImage" ref="heatMapImage" draggable="false" :src="image" @load="initHeatMapViewer" />
      </div>
    </section>
    <div class="HeatMapResults--Fake" :class="'HeatMapResults--Fake'+_uid" width="1000px" height="auto">
      <img ref="heatMapImageFake" draggable="false" :src="image" @load="initHeatMapViewerFake" />
    </div>
  </div>
</template>

<script>
 if (process.env.APP_TYPE != undefined && process.env.APP_TYPE == 'WAT') {
   var h337 = require('../heatmap.js');
 }
 else{
  var h337 = require('heatmap.js')
 }
 import { getCurrentInstance } from 'vue';
import { map } from 'lodash';
import { dragScroll } from '../../utils/dragScroll'
export default {
  name: 'HeatMapResults',

  props:{
    image:{
      type:String,
      default:""
    },
    heatMapAnswers:Object,
    heatmapPointDefinitions:Array,
    answersNum:{
      type:Number,
      default:1,
    },
  },

  data() {
    return {
      imageLoaded: false,
      stageConfig: {
        width: null,
        height: null,
      },
      heatMapViewer: null,
      fakeHeatMapViewer:null,
      selectedMap:2,
      showActions:false,
      _uid:getCurrentInstance().uid
    };
  },

  computed: {
    apiParsedPoints() {
      if(this.selectedMap <=1){
        return map(this.heatMapAnswers.hmvaluesByType[this.selectedMap == 1 ? 'RED' : 'GREEN'], (pin) => {
          return {
            x: pin.x,
            y: pin.y,
          };
        });
      }
      else{
        return map(this.heatMapAnswers.hmvaluesByPoint[this.selectedMap - 2], (pin) => {
          return {
            x: pin.x,
            y: pin.y,
          };
        });
      }

    },

    heatMapPoints() {
      if(! this.heatMapViewer) {
        return [];
      }

      const width = this.heatMapViewer._config.container.offsetWidth;
      const height = this.heatMapViewer._config.container.offsetHeight;

      return map(this.apiParsedPoints, (point) => {
        return {
          x: Math.floor((point.x * width) / 100),
          y: Math.floor((point.y * height) / 100),
          value: 1,
        };
      });
    },
  },

  watch: {
    heatMapPoints(val) {
      if(this.heatMapViewer) {
        let index = this.selectedMap - 2
        let type = "hmvaluesByPoint"
        if(this.selectedMap == 1){
          index = "RED"
          type = "hmvaluesByType"
        }
        if(this.selectedMap == 0){
          index = "GREEN"
          type = "hmvaluesByType"
        }
        this.heatMapViewer.setData({
          // min: 0,
          max: val.length / 25,
          opacity: 0.1,
          data: val,
        });
      }
    },
  },

  mounted() {
    window.addEventListener('resize', () => {
      this.reloadHeatMap()
    });
    dragScroll('#HeatMapActions_'+this._uid)
  },

  methods: {
    updateScrollPos(e,clickY){

    },
    getMapType(){
      if(this.selectedMap == 0) return "Positivos <i class='far fa-thumbs-up' style='color:green'></i>"
      else if(this.selectedMap == 1) return "Negativos <i class='far fa-thumbs-down' style='color:red'></i>"
      else{
        let icon = this.heatmapPointDefinitions[this.selectedMap - 2].type == 'RED'? "<i class='far fa-thumbs-down' style='color:red'></i>" : "<i class='far fa-thumbs-up' style='color:green'></i>"
        return this.selectedMap-1 +". " +this.heatmapPointDefinitions[this.selectedMap - 2].title+" " + icon
      }
    },
    initHeatMapViewer() {
      if(!this.heatMapViewer) {
        const container = document.querySelector('.HeatMap--HeatMap'+this._uid);

        this.heatMapViewer = h337.create({
          container: container,
          radius: Math.floor(container.offsetWidth * 0.05),
        });
      }
    },
    getDataUrl(){
      return this.heatMapViewer.getDataURL()
    },
    initHeatMapViewerFake(){
      if(this.fakeHeatMapViewer){
        this.fakeHeatMapViewer._renderer.canvas.remove()
        this.fakeHeatMapViewer = null
        this.initHeatMapViewerFake()
      }


      const container = document.querySelector('.HeatMapResults--Fake'+this._uid);
      this.fakeHeatMapViewer = h337.create({
        container: container,
        radius: Math.floor(container.offsetWidth * 0.05),
      });

      const width = this.fakeHeatMapViewer._config.container.offsetWidth;
      const height = this.fakeHeatMapViewer._config.container.offsetHeight;

      const val =  map(this.apiParsedPoints, (point) => {
        return {
          x: Math.floor((point.x * width) / 100),
          y: Math.floor((point.y * height) / 100),
          value: 1,
        };
      });

      this.fakeHeatMapViewer.setData({
        max: val.length / 25,
        opacity: 0.1,
        data: val,
      });
    },
    getDataUrlFake(){
      return this.fakeHeatMapViewer.getDataURL()
    },
    getCurrentHeatMapName(){
      let name = null
      if(this.selectedMap == 0) name = "Positivo"
      else if(this.selectedMap == 1) name = "Negativo"
      else {
        for(let index in this.heatmapPointDefinitions){
          if(this.selectedMap == parseInt(index) + 2) name = (parseInt(index)+1) + ". " + this.heatmapPointDefinitions[index].title
        }
      }
      return name
    },
    reloadHeatMap(){
      this.heatMapViewer._renderer.canvas.remove()
      this.heatMapViewer = null;
      this.initHeatMapViewer()
      this.fakeHeatMapViewer._renderer.canvas.remove()
      this.fakeHeatMapViewer = null
      this.initHeatMapViewerFake()
    }
  },
}
</script>
<style scoped lang="scss">
section{
  // max-width: 650px;
  // width: 75%;
  margin: auto;
  border: 1px solid #e4e4e4;

  max-width: 100%;
  width: auto;
  max-height: 65vh;
  height: auto;
}
.HeatMapResults{
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  margin-top: 41px;
  &--Fake{
    width: 1000px;
    z-index: -1;
    opacity: 0;
    position: absolute!important;
    img{
      width:100%;
      height: 100%;
    }
  }
  &--Actions{
    background: white;
    position: absolute;
    width: 100%;
    top: -41px;
    right: 0;
    z-index: 1;
    overflow-x: scroll;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    &::-webkit-scrollbar-track{
      background-color: #e6e6e6;
    }
    &::-webkit-scrollbar {
      background-color: #8134ff;
      height: 3px;
    }
    &::-webkit-scrollbar-thumb{
      border-radius: 10px;
      background: var(--primary-color);
    }
    div{
      display: flex;
      width: max-content;
      padding: 5px 0 0 0;
    }
    p{
      padding: 10px;
      font-size: 12px;
      font-family: Roboto Condensed;
      font-style: normal;
      margin-right: 5px;
      background: #f6f6f6;
      cursor: pointer;
      &:hover{
        background: #F2F2F2;
        color: var(--primary-color);
      }
      &.selected{
        color: var(--primary-color);
        background: white;
        font-weight: bold;
        box-shadow: 0 0 5px lightgrey;
        border: 1px solid #f6f6f6;
      }
    }
  }
}
.HeatMap {
  width: 100%;
  position: relative;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently supported by Chrome and Opera */
}

img.HeatMapImage {
  max-width: 100%;
  width: auto;
  max-height: 65vh;
  height: auto;
}
</style>
