<template>
  <div class="BoxShadow" :class="styleClasses">
    <slot />
  </div>
</template>
<script>
export default {
  props: {
    styleClasses : ""
  }
}
</script>
<style scoped lang="scss">
.BoxShadow{
  background: #FFFFFF;
  border: 1px solid #E1E1E1;
  filter: drop-shadow(3px 3px 1px rgba(0, 0, 0, 0.08));
  padding: 12px;
  display: flex;
  &.active{
    background: linear-gradient(90deg, #FF1564 0%, #FD7328 100%);
    border: 1px solid #E1E1E1;
    color: white;
    h1,p{
      color: white;
    }
    .svg{
      color:black;
      filter: brightness(0) invert(1);
    }
  }
}
.active.enableCheck{
  &:before{
    color: white;
    font-size: 21px;
    position: absolute;
    content:"\f058";
    font-family: "Font Awesome 5 Free";
    right: 15px;
    top: 14px;
  }
}
.active{
  svg{
    color:black;
    filter: brightness(0) invert(1);
  }
}
</style>
