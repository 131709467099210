<template>
  <div class="MonitorMessage">
    <div class="MonitorMessage--Logo">
      <img src="../../assets/img/WatMonitor/infoIcon.svg" alt="">
    </div>
    <div class="MonitorMessage--Info">
      <p>{{text}}</p>
      <h1 v-on:click="$emit('close')">{{$t('watmonitor_msg_hide')}}</h1>
    </div>
    <div class="MonitorMessage--Close" v-on:click="$emit('hide')">
      <img src="../../assets/img/WatMonitor/closeIcon.svg" alt="">
    </div>
  </div>
</template>

<script>
  export default {
    name: 'MonitorMessage',
    props:{
      text:String,
    },
    data(){
      return{

      }
    },
  }
</script>

<style scoped lang="scss">
@import url('https://fonts.googleapis.com/css?family=Lato:400,700');
.MonitorMessage{
  display: flex;
  position: relative;
  background: white;
  padding: 11px;
  &--Info{
    margin-left: 15px;
  }
  &--Close{
    cursor: pointer;
  }
  h1{
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 11px;
    letter-spacing: 0.02em;
    text-transform: uppercase;
    background: linear-gradient(90deg, #FF1564 0%, #FD7328 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    margin: 0;
    cursor: pointer;
  }
  p{
    font-family: Lato;
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    letter-spacing: 0.01em;
    color: #404040;
  }
}

</style>
