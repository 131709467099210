<template>
  <div class="ActivityQuestionList" >
    <div class="ActivityQuestionList--Title" v-on:click="collapse = !collapse">
      <h1>{{$t('questions')}}</h1>
      <i v-show="collapse" class="fas fa-chevron-up"></i>
      <i v-show="!collapse" class="fas fa-chevron-down"></i>
    </div>
    <div class="ActivityQuestionList--List" v-show="collapse">
      <div class="ActivityQuestionList--Question" v-for="(q,i) in questionList">
        <p class="ActivityQuestionList--QuestionTitle">{{i+1}} - <p v-html="q.text" style="display: inline-block; margin-right: 5px;"></p>
          <span v-if="q.type == 'RANKING'">{{$t('activity_survey_ranking')}}</span>
          <span v-if="q.type == 'SELECTION'">{{$t('activity_survey_selection')}}</span>
          <span v-if="q.type == 'MULTISELECTION'">{{$t('activity_survey_multi_select')}}</span>
          <span v-if="q.type == 'TEXT'">{{$t('text')}}</span>
          <span v-if="q.type == 'DIVIDE_POINTS'">{{$t('activity_survey_divide_points_distribution')}}</span>
          <span v-if="q.type == 'RATING_FACES'">{{$t('activity_survey_rating_faces')}}</span>
          <span v-if="q.type == 'SORTING'">{{$t('activity_survey_order')}}</span>
          <span v-if="q.type == 'TEXTAREA'">{{$t('activity_survey_textarea')}}</span>
          <span v-if="q.type == 'MULTITEXT'">{{$t('activity_survey_multitext')}}</span>
          <span v-if="q.type == 'IMAGE_CHOICE'">{{$t('activity_survey_image_choice')}}</span>
          <span v-if="q.type == 'MULTIPLEIMAGE_CHOICE'">{{$t('activity_survey_image_multiple')}}</span>
          <span v-if="q.type == 'IMAGE_URL'">{{$t('activity_survey_image_upload')}}</span>
          <span v-if="q.type == 'NUMBER'">{{$t('activity_survey_number')}}</span>
          <span v-if="q.type == 'DECIMAL_NUMBER'">{{$t('activity_survey_number')}}</span>
          <span v-if="q.type == 'MULTINUMBER'">{{$t('activity_survey_multi_number')}}</span>
          <span v-if="q.type == 'DATE'">{{$t('activity_survey_date')}}</span>
          <span v-if="q.type == 'MATRIX'">{{$t('activity_survey_matrix')}}</span>
        </p>
        <div class="ActivityQuestionList--Options">
          <div v-if="q.type == 'DIVIDE_POINTS'">
            {{$t('activity_survey_divide_points_to_distribute')}}  {{q.pointsNum}}
          </div>
          <div v-if="q.type == 'MULTISELECTION' || q.type == 'MULTIPLEIMAGE_CHOICE'">
            <p>{{$t('activity_survey_max_selection')}} {{q.multiselectionMax}}</p>
            <p>{{$t('activity_survey_min_selection')}} {{q.multiselectionMin}}</p>
          </div>
          <div v-if="q.type == 'RANKING' || q.type == 'RATING_FACES' || (q.type == 'MATRIX' && !q.matrixRadioButton)">
            <p>{{$t('activity_survey_ranking_max')}} {{q.rankingMax}}</p>
            <p>{{$t('activity_survey_ranking_max')}} {{q.rankingMin}}</p>
          </div>
          <div v-if="q.type == 'SELECTION' || q.type == 'MULTISELECTION' || q.type == 'SORTING' || q.type == 'MATRIX' || q.type == 'DIVIDE_POINTS'">
            <h2>{{$t('activity_survey_values')}}</h2>
            <p v-for="(v,i) in q.values">{{parseInt(i) + 1}} - {{v.value}}</p>
          </div>
          <div v-if="q.type == 'MATRIX' && q.matrixRadioButton">
            <h2>{{$t('activity_survey_options')}}</h2>
            <p v-for="(v,i) in q.radioOptionTexts">{{parseInt(i) + 1}} - {{v}}</p>
          </div>
          <div class="ActivityQuestionList--Imgs" v-if="q.type == 'IMAGE_CHOICE' || q.type == 'MULTIPLEIMAGE_CHOICE'">
            <div v-for="v in q.values">
              <img v-on:click="enlargePic(v.image)" :src="v.image">
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapGetters, mapMutations  } from 'vuex';

  export default {
    name: 'ActivityQuestionList',
    props: {
      questionList:{
        type:Array,
      },
    },
    data(){
      return{
        collapse: false,
      }
    },
    methods:{
      ...mapMutations({
        enlargePic : 'enlargePic',
      }),
      isNumberQuestion(){
        return this.question.type == "NUMBER" || this.question.type == "DECIMAL_NUMBER" || this.question.type == "MULTINUMBER"
      },
      isMultiSelection(){
        return this.question.type == "MULTISELECTION" || this.question.type == "MULTIPLEIMAGE_CHOICE"
      },
      isQuestionRanking(){
        return this.question.type == "RANKING" || this.question.type == "RATING_FACES"
      },
      isTextQuestion(){
        let valid = false
        switch (this.question.type) {
          case "TEXT":
          case "MULTITEXT":
          case "TEXTAREA":
            valid = true
            break;
        }
        return valid
      },
      isQuestionWithValues(type){
        let t = type != null && type != undefined ? type : this.question.type
        let valid = false
        switch (t) {
          case "SELECTION":
          case "MULTISELECTION":
          case "SORTING":
          case "MATRIX":
          case "IMAGE_CHOICE":
          case "MULTIPLEIMAGE_CHOICE":
          case "DIVIDE_POINTS":
            valid = true
            break;
        }
        return valid
      },
    }

  }
</script>

<style lang="scss">
</style>
<style scoped lang="scss">
.ActivityQuestionList{
  width: 100%;
  &--Title{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    cursor: pointer;
    h1{
      font-size: 20px;
      text-transform: capitalize;
    }
  }
  &--List{
    padding: 10px;
    border-top: 1px solid #f1f1f1;
  }
  &--Question{
    margin-bottom: 10px;
  }
  &--Options{
    margin-left: 20px;
    margin-top:3px;
  }
  span{
    background-color: #d1dade;
    color: #5e5e5e;
    font-family: 'Open Sans';
    font-size: 10px;
    font-weight: 600;
    padding: 3px 8px;
    text-shadow: none;
  }
  h2{
    font-size: 10px;
    margin-top: 5px;
    text-transform: uppercase;
  }
  &--QuestionTitle{
    font-weight: bold;
  }
  &--Imgs{
    display: flex;
    margin-top: 5px;
  }
  img{
    border: 1px solid #f1f1f1;
    width: 100px;
    height: 90px;
  }
}
</style>
