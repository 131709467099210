<template>
  <div class="ActivityNoAvailable center">
    <div class="ActivityNoAvailable__Finished" v-if="communityCurrentActivity.status == 'FINISHED'">
      <div class="ActivityNoAvailable--Box">
        <div class="ActivityNoAvailable--Title">
          {{$t('activity_not_available')}}
        </div>
        <div class="ActivityNoAvailable--Text">
          {{$t('activity_finished_alert')}}
        </div>
        <div class="ActivityNoAvailable--Dates">
          {{$filters.formatDate(communityCurrentActivity.dateTo)}}
        </div>
      </div>
    </div>
    <div class="ActivityNoAvailable__New" v-if="(communityCurrentActivity.status == 'NEW' || communityCurrentActivity.status == 'CONFIGURING') && communityCurrentActivity.type == 'VIDEOCHAT'">
      <div class="ActivityNoAvailable--Box">
        <div class="ActivityNoAvailable--Title">
          {{$t('activity_not_available')}}
        </div>
        <div class="ActivityNoAvailable--Text">
          {{$t('activity_not_available_date')}}
        </div>
        <div class="ActivityNoAvailable--Dates">
          {{$filters.formatDate(communityCurrentActivity.dateFrom)}}
        </div>
      </div>
    </div>
    <div class="ActivityNoAvailable__New" v-if="(communityCurrentActivity.status == 'NEW' || communityCurrentActivity.status == 'CONFIGURING') && communityCurrentActivity.type != 'VIDEOCHAT'">
      <div class="ActivityNoAvailable--Box">
        <div class="ActivityNoAvailable--Title">
          {{$t('activity_not_available')}}
        </div>
        <div class="ActivityNoAvailable--Text">
          {{$t('activity_not_available_date_2')}}
        </div>
        <div class="ActivityNoAvailable--Dates">
          {{$filters.formatDate(communityCurrentActivity.dateFrom)}}
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
export default {
  name:'ActivityNoAvailable',
  computed: {
    ...mapGetters([
      'communityCurrentActivity'
    ]),
  }
}
</script>
<style scoped lang="scss">
.ActivityNoAvailable{
  &--Box{
    background: #f1f1f1;
    display: flex;
    flex-direction: column;
    padding: 20px 15px;
    max-width: 315px;
  }
  &--Title{
    font-size: 20px;
    font-weight: 800;
    border-bottom: 1px solid black;
  }
  &--Text{
    font-size: 14px;
    margin: 5px 0;
  }
  &--Dates{
    color: var(--primary-color);
    font-weight: bold;
    font-size: 20px;
  }
}
</style>
