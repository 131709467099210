
import { defineComponent } from 'vue';
export default defineComponent({
  name : 'Input',
  props:{
    type:{
      type:String,
      default:"text"
    },
    placeholder:{
      type:String,
      default:" "
    },
    label:{
      type: String,
      default: null
    },
    disabled:{
      type: Boolean,
      default: false
    },
    modelValue:[String, Number],
    min: String
  },
  emits: ['update:modelValue', 'blur', 'updateAfter1000Ms'],
  watch: {
    value (newValue) {
     this.$emit('update:modelValue', newValue)
   }
  },
  computed: {
    value: {
      get() {
        return this.modelValue
      },
      set(newValue: any) {
        this.isUpdating = true
        this.$emit('update:modelValue', newValue)
        let vm = this
        setTimeout(function(){
          if(vm.isUpdating){
            vm.isUpdating = false
            vm.$emit('updateAfter1000Ms')
          }
        }, 1000);
      }
    }
  },
  data(){
    return{
      isUpdating: false
    }
  }
})
