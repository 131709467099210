export const getBase64Image = async function(image: any){
  let base64Image = null
  await toBase64(image)
  .then((data) => {
    base64Image = data
  })
  return base64Image
}

export const blobToBase64 = async function(blob: any){
  return new Promise((resolve, _) => {
    const reader = new FileReader();
    reader.onloadend = () => resolve(reader.result);
    reader.readAsDataURL(blob);
  });
}

export const externalUrlToBase64 = async function(url: any){
  let base64 = null
  //url = url.replace('https','http') // only for localhost
  let blobResult = await fetch(url + '?not-from-cache-please',{mode: 'cors'})
  .then((result: any) => {
    return result.blob()
  })

  await blobToBase64(blobResult).then((blob: any) => {
    base64 = blob
  })

  return base64
}

export const toBase64 = async function(url: any){
  return new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest();
    xhr.onload = function() {
      const reader = new FileReader();
      reader.readAsDataURL(xhr.response);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
    };
    xhr.open('GET', url);
    xhr.responseType = 'blob';
    xhr.send();
  });
}

export const getGoogleFontURI = async function(){
  const resp = await fetch('https://fonts.gstatic.com/s/lato/v22/S6uyw4BMUTPHjx4wXg.woff2');
  const blob = await resp.blob();
  return new Promise((resolve, reject) => {
    const f = new FileReader();
    f.addEventListener('load', () => resolve(f.result));
    f.readAsDataURL(blob);
  });
}
