<template>
  <svg
    width="14"
    height="12"
    viewBox="0 0 14 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12 0H2C1.60218 0 1.22064 0.158035 0.93934 0.43934C0.658035 0.720644 0.5 1.10218 0.5 1.5V8.5C0.5 8.89782 0.658035 9.27936 0.93934 9.56066C1.22064 9.84196 1.60218 10 2 10H6.5V11H5C4.86739 11 4.74021 11.0527 4.64645 11.1464C4.55268 11.2402 4.5 11.3674 4.5 11.5C4.5 11.6326 4.55268 11.7598 4.64645 11.8536C4.74021 11.9473 4.86739 12 5 12H9C9.13261 12 9.25979 11.9473 9.35355 11.8536C9.44732 11.7598 9.5 11.6326 9.5 11.5C9.5 11.3674 9.44732 11.2402 9.35355 11.1464C9.25979 11.0527 9.13261 11 9 11H7.5V10H12C12.3978 10 12.7794 9.84196 13.0607 9.56066C13.342 9.27936 13.5 8.89782 13.5 8.5V1.5C13.5 1.10218 13.342 0.720644 13.0607 0.43934C12.7794 0.158035 12.3978 0 12 0ZM12 9H2C1.86739 9 1.74021 8.94732 1.64645 8.85355C1.55268 8.75979 1.5 8.63261 1.5 8.5V7.5H12.5V8.5C12.5 8.63261 12.4473 8.75979 12.3536 8.85355C12.2598 8.94732 12.1326 9 12 9Z"
      fill="#8134FF"
    />
  </svg>
</template>
