<template>
  <div class="uxTranscriptionComment">
    <div class="info" @click="goToSecond">
      <div class="action" >
        <IconRotateLeft />
      </div>
      <div class="time" >{{ time }}</div>
      <div class="comment" :class="{ isCurrentComment: isCurrentComment }">
        {{ comment }}
      </div>
    </div>
  </div>
</template>
<script setup>
import { onBeforeMount, ref, watch } from 'vue'
import IconRotateLeft from '../icons/IconRotateLeft.vue'
const props = defineProps({
  
  comment: {
    type: String,
    default: '',
  },
  second: {
    type: Number,
    default: 3.481589,
  },
  isCurrentComment: {
    type: Boolean,
    default: false,
  },
  index: {
    type: Number,
  },
})
const emit = defineEmits(['goToSecond', 'scrollToComment'])

watch(
  () => props.isCurrentComment,
  (isCurrentComment) => {
    if (isCurrentComment) {
      emit('scrollToComment', `comment${props.index}`)
    }
  }
)
const time = ref('')
onBeforeMount(() => {
  time.value = createTime(props.second)
})
const createTime = (duration) => {
  const time = new Date(duration * 1000)
  let hours = (time.getHours() - 1).toString()
  let minutes = time.getMinutes().toString()
  let seconds = time.getSeconds().toString()
  if (hours.length < 2) hours = '0' + hours
  if (minutes.length < 2) minutes = '0' + minutes
  if (seconds.length < 2) seconds = '0' + seconds
  return `${hours}:${minutes}:${seconds}`
}
const goToSecond = () => {
  emit('goToSecond', props.second)
}
</script>
<style lang="scss" scoped>
.uxTranscriptionComment {
  display: flex;
  flex-direction: column;
  gap: 7px;
  .info {
    display: flex;
    flex-direction: row;
    gap: 7px;
    .action {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 5px;
      padding: 1px 5px;
      border-radius: 5px;
      transition: background-color 0.3s;
      cursor: pointer;
      .userName {
        font-family: 'Lato';
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        color: #000000;
        transition: color 0.3s;
        &:hover {
          color: #8134ff;
        }
      }
      svg {
        width: 0px;
        transition: width 0.3s;
      }
      &:hover {
        background-color: #f6f0ff;
        svg {
          width: 15px;
        }
      }
    }
    .time {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 1px 5px;
      border-radius: 10px;
      font-family: 'Lato';
      font-style: normal;
      font-weight: 700;
      font-size: 12px;
      color: #8134ff;
      background-color: #f6f0ff;
      cursor: pointer;
    }
  }
  .comment {
    margin-left: 5px;
    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: #626262;
    cursor: pointer;
    &.isCurrentComment {
      color: #8134ff;
    }
  }
}
</style>
