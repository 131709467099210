<template>
    <div class="AnalysisHeader">
        <div class="AnalysisHeader--Buttons">
            <button>
                <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M7.07952 10.4209H6.3465C6.00678 10.4209 5.7207 10.1349 5.7207 9.79514V8.49008C5.7207 8.15037 6.00678 7.86429 6.3465 7.86429H7.07952C7.41924 7.86429 7.70531 8.15036 7.70531 8.49008V9.79514C7.70519 10.1348 7.43707 10.4209 7.07952 10.4209Z" fill="white"/>
                    <path d="M10.2084 10.4209H9.4754C9.13569 10.4209 8.84961 10.1349 8.84961 9.79514V8.49008C8.84961 8.15037 9.13569 7.86429 9.4754 7.86429H10.2084C10.5481 7.86429 10.8342 8.15036 10.8342 8.49008V9.79514C10.8341 10.1348 10.548 10.4209 10.2084 10.4209Z" fill="white"/>
                    <path d="M8.49202 0.0159645C3.808 0.0159645 0 3.82396 0 8.50798C0 13.192 3.808 17 8.49202 17H15.6432C16.3941 17 16.984 16.3922 16.984 15.6592V8.50798C16.984 3.82396 13.176 0.0159645 8.49202 0.0159645ZM14.4454 9.7058C14.4454 10.26 13.9984 10.6891 13.4621 10.6891H13.0151C12.9793 10.6891 12.9257 10.6891 12.8899 10.6713C12.1748 12.1909 10.4764 13.2456 8.49193 13.2456C6.50741 13.2456 4.80907 12.1909 4.11183 10.6891H3.66488C3.1107 10.6891 2.68157 10.2422 2.68157 9.70582V8.57948C2.68157 8.02529 3.12851 7.59616 3.66488 7.59616H4.09399C4.7018 6.30895 6.06062 5.34357 7.70533 5.11112V4.03838C7.36562 3.84172 7.13317 3.4842 7.13317 3.05507C7.13317 2.42928 7.63375 1.92873 8.25951 1.92873C8.8853 1.92873 9.38585 2.42931 9.38585 3.05507C9.38585 3.46623 9.1534 3.84173 8.8137 4.03838V5.05749C10.6551 5.1827 12.1926 6.18383 12.8541 7.59616C12.8899 7.59616 12.9435 7.57832 12.9793 7.57832H13.4263C13.9804 7.57832 14.4096 8.02527 14.4096 8.56164L14.4095 9.70583L14.4454 9.7058Z" fill="white"/>
                </svg>
                WAT IA
                <svg width="14" height="13" viewBox="0 0 14 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M7.66667 9.16667H7V6.5H6.33333M7 3.83333H7.00667M13 6.5C13 7.28793 12.8448 8.06815 12.5433 8.7961C12.2417 9.52405 11.7998 10.1855 11.2426 10.7426C10.6855 11.2998 10.0241 11.7417 9.2961 12.0433C8.56815 12.3448 7.78793 12.5 7 12.5C6.21207 12.5 5.43185 12.3448 4.7039 12.0433C3.97595 11.7417 3.31451 11.2998 2.75736 10.7426C2.20021 10.1855 1.75825 9.52405 1.45672 8.7961C1.15519 8.06815 1 7.28793 1 6.5C1 4.9087 1.63214 3.38258 2.75736 2.25736C3.88258 1.13214 5.4087 0.5 7 0.5C8.5913 0.5 10.1174 1.13214 11.2426 2.25736C12.3679 3.38258 13 4.9087 13 6.5Z" stroke="white" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
            </button>
            <div>
                <button v-on:click="showLongOrShortSummary()" :disabled="isObservator">
                    <svg width="14" height="13" viewBox="0 0 14 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M1.4001 0.900002C1.18792 0.900002 0.984441 0.984287 0.834412 1.13432C0.684383 1.28435 0.600098 1.48783 0.600098 1.7C0.600098 1.91217 0.684383 2.11566 0.834412 2.26569C0.984441 2.41572 1.18792 2.5 1.4001 2.5H10.2001C10.4123 2.5 10.6158 2.41572 10.7658 2.26569C10.9158 2.11566 11.0001 1.91217 11.0001 1.7C11.0001 1.48783 10.9158 1.28435 10.7658 1.13432C10.6158 0.984287 10.4123 0.900002 10.2001 0.900002H1.4001ZM1.4001 4.1C1.18792 4.1 0.984441 4.18429 0.834412 4.33432C0.684383 4.48435 0.600098 4.68783 0.600098 4.9C0.600098 5.11217 0.684383 5.31566 0.834412 5.46569C0.984441 5.61572 1.18792 5.7 1.4001 5.7H7.0001C7.21227 5.7 7.41575 5.61572 7.56578 5.46569C7.71581 5.31566 7.8001 5.11217 7.8001 4.9C7.8001 4.68783 7.71581 4.48435 7.56578 4.33432C7.41575 4.18429 7.21227 4.1 7.0001 4.1H1.4001ZM1.4001 7.3C1.18792 7.3 0.984441 7.38429 0.834412 7.53432C0.684383 7.68435 0.600098 7.88783 0.600098 8.1C0.600098 8.31217 0.684383 8.51566 0.834412 8.66569C0.984441 8.81572 1.18792 8.9 1.4001 8.9H4.6001C4.81227 8.9 5.01575 8.81572 5.16578 8.66569C5.31581 8.51566 5.4001 8.31217 5.4001 8.1C5.4001 7.88783 5.31581 7.68435 5.16578 7.53432C5.01575 7.38429 4.81227 7.3 4.6001 7.3H1.4001ZM11.0001 4.9C11.0001 4.68783 10.9158 4.48435 10.7658 4.33432C10.6158 4.18429 10.4123 4.1 10.2001 4.1C9.98792 4.1 9.78444 4.18429 9.63441 4.33432C9.48438 4.48435 9.4001 4.68783 9.4001 4.9V9.3688L8.3657 8.3344C8.21481 8.18868 8.01273 8.10804 7.80298 8.10986C7.59322 8.11169 7.39257 8.19582 7.24424 8.34415C7.09592 8.49247 7.01178 8.69312 7.00996 8.90288C7.00813 9.11264 7.08877 9.31472 7.2345 9.4656L9.6345 11.8656C9.78452 12.0156 9.98796 12.0998 10.2001 12.0998C10.4122 12.0998 10.6157 12.0156 10.7657 11.8656L13.1657 9.4656C13.3114 9.31472 13.3921 9.11264 13.3902 8.90288C13.3884 8.69312 13.3043 8.49247 13.156 8.34415C13.0076 8.19582 12.807 8.11169 12.5972 8.10986C12.3875 8.10804 12.1854 8.18868 12.0345 8.3344L11.0001 9.3688V4.9Z" fill="#96C800"/>
                    </svg>
                    {{ summaryLongText }}
                </button>
            </div>
        </div>
        <h1>{{ $t('activity_analysis_title') }}</h1>
        <p v-show="!isLoading && !AIsummaryIsLong" style="white-space: pre-line;">
            {{ AIsummary }}
        </p>
        <p v-show="!isLoading && AIsummaryIsLong" style="white-space: pre-line;">
            {{ AIsummaryLong }}
        </p>
        <p class="isLoading" v-show="isLoading">
            <p class="spinner" v-show="isLoading"></p>
        </p>
    </div>
  </template>
  <script>
  import { mapGetters } from "vuex"
  export default {
    name: "AnalysisHeader",
    props: {
      AIsummary: {
        type: String,
        default: ''
      },
      AIsummaryLong: {
        type: String,
        default: ''
      },
      isLoading: {
        type: Boolean,
        default: false
      },
      AIsummaryIsLong: {
        type: Boolean,
        default: true
      }
    },
    computed:{
        ...mapGetters([
            'isObservator'
        ])
    },
    data() {
        return {
            summaryLongText: ""
        }
    },
    methods: {
        showLongOrShortSummary: async function() {
            this.$emit('renew-summary');
            if (!this.AIsummaryIsLong) {
                this.summaryLongText = this.$t('activity_summary');
            } else {
                this.summaryLongText = this.$t('activity_summary_long');
            }
        },
        changeButtonText: async function() {
            if (this.AIsummaryIsLong) {
                this.summaryLongText = this.$t('activity_summary');
            } else {
                this.summaryLongText = this.$t('activity_summary_long');
            }
        }
    },
    mounted() {
        this.changeButtonText();
    }
  };
  </script>
  <style scoped lang="scss">
  .AnalysisHeader{
    background: #F6F0FF;
    border-radius: 4px;
    padding: 32px 24px 24px;
    position: relative;
    margin-top: 30px;
    &--Buttons{
        width: 100%;
        position: absolute;
        left: 0;
        padding: 0 20px;
        top: -18px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        div{
            display: flex;
            gap: 10px;
            button:nth-child(1){
                background: white;
                color: black!important;
            }
        }
        div button{
            padding: 8px 14px;
            height: 37px;
        }
    }
  }
  button{
    display: flex;
    gap: 7px;
    padding: 3px 8px;
    height: 27px;
    box-shadow: 0px 4px 24px rgba(31, 31, 31, 0.05);
    border-radius: 9999px;
    border: none;
    color: white;
    font-family: 'Bariol';
    font-weight: 700;
    font-size: 18px;
    display: flex;
    align-items: center;
    color: #FFFFFF;
    cursor: pointer;
    position: relative;
    &:first-of-type{
        background: var(--primary-color);
    }
    &:disabled:hover{
        opacity: 0.5;
        cursor: not-allowed;
    }
  }
  h1{
    font-family: 'Bariol';
    font-weight: 700;
    font-size: 31px;
    color: var(--primary-color);
  }
  p{
    font-family: 'Lato';
    font-weight: 400;
    font-size: 16px;
    opacity: 0.7;
    margin-top: 8px;
  }
  .isLoading {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 80px;
    width: 100%;
  }
  .spinner {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    border: 6px solid #40bbdd;
    border-top-color: transparent;
    animation: spin 1s infinite linear;
  }

  @keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
  }

    @media screen and (max-width:1080px){
        .AnalysisHeader--Buttons{
            padding: 0;
            button{
                font-size: 15px;
            }
        }
    }
  </style>
  