<template>
  <svg
    width="14"
    height="13"
    viewBox="0 0 14 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7 0C5.71442 0 4.45772 0.381218 3.3888 1.09545C2.31988 1.80968 1.48676 2.82484 0.994786 4.01256C0.502816 5.20028 0.374095 6.50721 0.624899 7.76809C0.875703 9.02896 1.49477 10.1872 2.40381 11.0962C3.31285 12.0052 4.47104 12.6243 5.73192 12.8751C6.99279 13.1259 8.29973 12.9972 9.48744 12.5052C10.6752 12.0132 11.6903 11.1801 12.4046 10.1112C13.1188 9.04229 13.5 7.78558 13.5 6.5C13.4982 4.77665 12.8128 3.12441 11.5942 1.90582C10.3756 0.687224 8.72335 0.00181989 7 0ZM7 1C7.91538 1.00038 8.81618 1.22922 9.62075 1.66577C10.4253 2.10233 11.1082 2.73279 11.6075 3.5H7C6.36848 3.50071 5.75324 3.70036 5.24164 4.0706C4.73003 4.44084 4.34807 4.96285 4.15 5.5625L2.7075 3.0625C3.22237 2.41864 3.87555 1.89893 4.61862 1.54189C5.3617 1.18485 6.1756 0.999645 7 1ZM1.5 6.5C1.49896 5.63397 1.70376 4.78009 2.0975 4.00875L4.40188 8C4.40563 8.00625 4.41 8.01188 4.41375 8.0175C4.73135 8.55939 5.21103 8.98792 5.78514 9.24268C6.35925 9.49745 6.99885 9.56559 7.61375 9.4375L6.17 11.9375C4.87088 11.7374 3.68614 11.0793 2.82972 10.0821C1.97331 9.08497 1.50168 7.81445 1.5 6.5ZM7.29313 11.9919L9.59813 8C9.60313 7.99125 9.60688 7.9825 9.61188 7.97375C9.92111 7.42842 10.0513 6.79975 9.98398 6.17646C9.9167 5.55318 9.65537 4.96677 9.23688 4.5H12.125C12.4414 5.31057 12.5609 6.18464 12.4738 7.05039C12.3866 7.91613 12.0954 8.74886 11.6238 9.48014C11.1523 10.2114 10.514 10.8204 9.76139 11.257C9.00875 11.6937 8.16326 11.9455 7.29438 11.9919H7.29313Z"
      fill="#8134FF"
    />
  </svg>
</template>
