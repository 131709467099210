<template>
    <div class="AnalysisCommentList">
        <h1>Listado de comentarios en la conversación</h1>
        <AnalysisMessage class="AnalysisCommentList--NoContent" v-show="comments.length == 0" title="Selecciona un topic" text="Selecciona un topic del listado para poder visualizar los comentarios" />
        <div class="AnalysisCommentList--List" v-show="comments.length > 0">
            <div class="AnalysisCommentList--Comment" v-for="c in comments">
                <div class="AnalysisCommentList--CommentHeader">
                    <div :style="{ backgroundImage: 'url(\'' + c.imageUrl + '\')' }">

                    </div>
                    <span>{{ c.nickname }}</span>
                </div>
                <p>{{ c.comment }}</p>
                <ProgressBar :value="c.weight * 100" style="height:7px"/>
                <p class="AnalysisCommentList--Link" v-on:click="redirectToComment(c)"><i class="fas fa-external-link-alt"></i>{{ $t('activity_go_link') }}</p>
            </div>
        </div>
        <button v-show="comments.length > 0 && showMoreCommentsButton" v-on:click="$emit('load-more')">Cargar más comentarios</button>
    </div>
  </template>
  <script>
  import AnalysisMessage from './AnalysisMessage'
  import ProgressBar from '@/components/ProgressBar'
  export default {
    name: "AnalysisCommentList",
    components:{
        AnalysisMessage,
        ProgressBar
    },
    props: {
      comments: Array,
      topicName: String,
      showMoreCommentsButton: Boolean
    },
    methods:{
      redirectToComment(comment){
        this.$emit('redirect-comment', comment)
      }
    }
  };
  </script>
  <style scoped lang="scss">
  .AnalysisCommentList{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 25px;
    &--NoContent{
        margin: auto;
    }
    &--CommentHeader{
      display: flex;
      gap: 7px;
      align-items: center;
      justify-content: center;
      div{
        width: 32px;
        height: 32px;
        border-radius: 50%;
        background-color: lightgray;
      }
    }
    &--Link{
      display: flex;
      gap: 5px;
      align-items: center;
      cursor: pointer;
    }
    &--List{
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
      gap: 12px;
      overflow: auto;
      &::-webkit-scrollbar-track{
            background-color: #e6e6e6;
        }
        &::-webkit-scrollbar {
            background: var(--primary-color);
            width: 3px;
        }
        &::-webkit-scrollbar-thumb{
            border-radius: 20px;
            background: var(--primary-color);
        }
    }
    &--Comment{
        padding: 16px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 8px;
        border: 1px solid #EAEAEA;
        border-radius: 4px;
        width: 100%;
        p{
            font-family: 'Lato';
            font-weight: 400;
            font-size: 16px;
            color: #626262;
        }
        span{
            font-family: 'Lato';
            font-weight: 700;
            font-size: 15px; 
        }
    }
  }

  h1{
    font-family: 'Bariol';
    font-weight: 700;
    font-size: 40px;
  }

  button{
    padding: 10px 16px;
    gap: 10px;
    height: 37px;
    background: var(--accent-color);
    border-radius: 4px;
    color: white;
    font-family: 'Lato';
    font-weight: 900;
    font-size: 14px;
    text-transform: uppercase;
    cursor: pointer;
  }
</style>
  