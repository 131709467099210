<template>
  <svg
    width="47"
    height="48"
    viewBox="0 0 47 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x="0.00012207"
      y="0.319458"
      width="47"
      height="47"
      rx="23.5"
      fill="white"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M36.3001 28.6194V15.8194C36.3001 14.9708 35.9629 14.1568 35.3628 13.5567C34.7627 12.9566 33.9488 12.6194 33.1001 12.6194H13.9001C13.0514 12.6194 12.2374 12.9566 11.6373 13.5567C11.0372 14.1568 10.7001 14.9708 10.7001 15.8194V28.6194C10.7001 29.4681 11.0372 30.2821 11.6373 30.8822C12.2374 31.4823 13.0514 31.8194 13.9001 31.8194H18.7001L23.5001 36.6194L28.3001 31.8194H33.1001C33.9488 31.8194 34.7627 31.4823 35.3628 30.8822C35.9629 30.2821 36.3001 29.4681 36.3001 28.6194ZM15.5001 19.0194C15.5001 18.5951 15.6686 18.1881 15.9687 17.8881C16.2688 17.588 16.6757 17.4194 17.1001 17.4194H29.9001C30.3244 17.4194 30.7314 17.588 31.0314 17.8881C31.3315 18.1881 31.5001 18.5951 31.5001 19.0194C31.5001 19.4438 31.3315 19.8508 31.0314 20.1508C30.7314 20.4509 30.3244 20.6194 29.9001 20.6194H17.1001C16.6757 20.6194 16.2688 20.4509 15.9687 20.1508C15.6686 19.8508 15.5001 19.4438 15.5001 19.0194ZM17.1001 23.8194C16.6757 23.8194 16.2688 23.988 15.9687 24.2881C15.6686 24.5881 15.5001 24.9951 15.5001 25.4194C15.5001 25.8438 15.6686 26.2508 15.9687 26.5508C16.2688 26.8509 16.6757 27.0194 17.1001 27.0194H21.9001C22.3244 27.0194 22.7314 26.8509 23.0314 26.5508C23.3315 26.2508 23.5001 25.8438 23.5001 25.4194C23.5001 24.9951 23.3315 24.5881 23.0314 24.2881C22.7314 23.988 22.3244 23.8194 21.9001 23.8194H17.1001Z"
      fill="#1F1F1F"
    />
  </svg>
</template>
