<template lang="html">
  <div class="VideochatCommentsList">
    <div class="VideochatCommentsList--Modal">
      <div class="VideochatCommentsList--Header">
        <h1>Video {{videoName}}</h1>
        <div class="VideochatCommentsList--Close" v-on:click="close()">
          <span :style="{'background':theme.mainColor}"></span>
          <span :style="{'background':theme.mainColor}"></span>
        </div>
      </div>
      <div class="VideochatCommentsList--Content">
        <div class="VideochatCommentsList--Video">
          <video controls :controlsList="!communityInfo.allowVideosDownload?'nodownload':''" ref="Video">
            <source :src="url" type="video/mp4">
          </video>
        </div>
        <div class="VideochatCommentsList--Comments" v-show="comments != null && comments.length > 0">
          <h2>Anotaciones</h2>
          <p v-for="comment in comments" v-on:click="goToVideoSecond(comment.secondsFromStart)">
            <span :style="{'color':theme.buttonColor}"><i class="far fa-play-circle"></i> {{getTime(comment.secondsFromStart)}}</span>
            {{comment.comment}}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { inject } from "vue";
import { mapGetters } from 'vuex';

export default {
  name: 'VideochatCommentsList',
  computed:{
    ...mapGetters([
      'theme',
      'communityInfo'
    ])
  },
  props:{
    comments:{
      type: Array,
      default:null
    },
    url:{
      type: String,
      default: ''
    },
    videoName:{
      type: String,
      default: ''
    }
  },
  methods:{
    getTime(secondsFromStart){
      const date = new Date(secondsFromStart * 1000)
      const hours = parseInt(date / (1000 * 60 * 60) % 24);
      const minutes = parseInt(date / (1000 * 60) % 60);
      const seconds = parseInt(date / (1000) % 60);
      return ( hours < 10 ? '0'+hours : hours ) + ':' + ( minutes < 10 ? '0'+minutes : minutes ) + ':' + ( seconds < 10 ? '0'+seconds : seconds )
    },
    goToVideoSecond(second){
      console.log(second);
      const video = this.$refs.Video
      video.currentTime = second
      console.log(video);
    }
  },
  setup (props, { emit }) {
    const emitter = inject("emitter")
    const close = (event) => {
      emit("close")
      emitter.emit("close-videochat-comments")
    }
    return {
        close
    }
  }
}
</script>
<style lang="scss" scoped>
.VideochatCommentsList{
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  width: 100%;
  height: 100%;
  left:0;
  top:0;
  background: rgba(0,0,0,0.5);
  z-index: 9999;
  .CommunityButton{
    font-size: 15px;
  }
  &--Modal{
    width: 80%;
    max-height: 80%;
    background: white;
    padding: 10px;
  }
  &--Header{
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    border-bottom: 1px solid #E1E1E1;
    padding-bottom: 5px;
    margin-bottom: 10px;
  }
  &--Content{
    display: grid;
    grid-template-columns: auto auto;
    padding: 5px;
    justify-content: center;
    gap: 20px;
  }
  &--Comments{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 30px;
    border-left: 1px dashed lightgray;
    padding: 0 0 0 20px;
    i{
      margin: 0 5px;
    }
    p{
      cursor: pointer;
    }
    span{
      background: #f2f2f2;
      padding: 5px;
      font-family: 'Roboto Condensed';
      border-radius: 6px;
    }
  }
  &--Close{
    transition: 0.1s;
    cursor: pointer;
    &:hover{
      opacity: 0.5;
    }
    span:nth-child(1){
      transform: rotate(45deg) translate(-1px, -3px);
    }
    span:nth-child(2){
      transform: rotate(135deg) translate(-12px, 0px);
    }
    span{
      display: flex;
      width: 20px;
      height: 2px;
      margin-bottom: 5px;
      position: relative;
      border-radius: 3px;
      z-index: 1;
      transform-origin: 5px 0px;
    }
  }
  h1{
    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    font-size: 23px;
    letter-spacing: 0.01em;
    color: #404040;
  }
}
</style>
