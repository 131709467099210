<template>
    <div class="ModalChangeName">
        <div class="ModalChangeName--Content" ref="content">
            <h1>Vas a modificar el nombre de "<span :style="{ 'color': theme.mainColor }">{{ name }}</span>"</h1>
            <Input type="text" :placeholder="'Ej. Francisco'" :label="'Nuevo nombre'" v-model="newName" />
            <div>
                <button type="button" v-on:click="close()">Cancelar</button>
                <button v-on:click="save()" :style="{ 'background': theme.buttonColor, 'color': 'white' }"
                    type="button">{{ $t('action_save') }}</button>
            </div>
        </div>
    </div>
</template>
<script>
import { mapGetters } from 'vuex'
import { onClickOutside } from '@vueuse/core'
import { ref, inject } from 'vue'
import Input from '../Input/Input.vue';
export default {
    name: "ModalChangeName",
    components: {
        Input
    },
    props: {
        name: [String, Number],
        speakerNum: [String, Number]
    },
    computed: {
        ...mapGetters(['theme'])
    },
    setup(props) {
        const content = ref(null)
        let newName = ref("")
        onClickOutside(content, (event) => close())
        const emitter = inject("emitter")
        const close = (event) => {
            emitter.emit("close-modal-change-name")
        }
        const save = (event) => {
            emitter.emit("save-modal-change-name", { name: newName.value, speakerNum: props.speakerNum })
        }
        return { content, close, save, newName }
    }
};
</script>
<style scoped lang="scss">
.ModalChangeName {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.8);
    z-index: 9999;
    display: flex;
    align-items: center;
    justify-content: center;

    &--Content {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        gap: 40px;
        padding: 40px;
        background: white;
        border-radius: 20px;
        width: 500px;

        div {
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;
        }
    }

    h1 {
        font-family: 'Bariol';
        font-weight: 700;
        font-size: 24px;
        margin-bottom: -20px;
    }

    button {
        font-size: 14px;
        padding: 12px 16px;
        height: 41px;
        background: #FFFFFF;
        border: 1px solid #EAEAEA;
        border-radius: 4px;
        font-family: 'Lato';
        font-weight: 900;
        font-size: 14px;
        color: #1F1F1F;
        text-transform: uppercase;
        cursor: pointer;
        opacity: 0.9;

        &:hover {
            opacity: 1;
        }
    }
}
</style>
