<template lang="html">
  <div class="slider--flex">
    <div class="slider" :id="'slider_'+ _uid">
      <div :class="'slider--output  output_'+_uid + ' output--' + i + '_' + _uid" v-for="(v,i) in values">
        {{v}}
      </div>
      <div :class="'slider--background bg_'+_uid">

      </div>
    </div>
    <div class="slider--actions">
      <button v-on:click="add()">+</button>
      <button v-on:click="remove()" :disabled="count==2">-</button>
    </div>
  </div>

</template>
<script>
import Vue from 'vue'
import { getCurrentInstance } from 'vue';

export default {
  name: 'MultiRange',
  components: {

  },
  props: {
    min:{
      type:Number,
      default:16,
    },
    max:{
      type:Number,
      default:100,
    },
    modelValues:Array,
  },
  data() {
    return {
      count:2,
      values:[this.min,this.max],
      _uid:getCurrentInstance().uid
    }
  },
  updated: function () {
    this.$nextTick(function () {
      if(this.values.length > this.count){
        this.count ++
        this.dragable()
        this.setOutput(this.count - 1)
      }
    })
  },
  methods: {
    drag(event,ui){
      let newPosition = ui.position.left //element new position
      let sliderWidth = document.getElementById('slider_'+this._uid).offsetWidth -25 // slider size without element width
      let valuesNum = this.max - this.min
      let newValue = (newPosition / (sliderWidth / valuesNum)) + this.min // new value for element

      let elIndex = event.target.classList[2].replace("output--","").replace(this._uid,"").replace("_","") // element index in values array
      this.values[elIndex] = newValue>this.max ? this.max : Math.round(newValue) // stop value if is more than max
      document.getElementsByClassName("output--" + elIndex + '_' + this._uid)[0].innerHTML = this.values[elIndex] // change element value
      this.setBgSize()
    },
    convertInt(value){
      let returnedVal = value
      if(value.includes("px")){
        returnedVal = parseInt(value.replace("px"))
        returnedVal = (100 / (document.getElementById('slider_'+this._uid).offsetWidth -25)) * returnedVal
      }
      else{
        returnedVal = parseInt(value.replace("%",""))
      }
      return returnedVal
    },
    setBgSize(){
      let maxVal = this.values.indexOf(Math.max(...this.values))
      let minVal = this.values.indexOf(Math.min(...this.values))
      let smallSize = this.convertInt(document.getElementsByClassName("output--" + minVal + '_' + this._uid)[0].style.left)
      let bigSize = this.convertInt(document.getElementsByClassName("output--" + maxVal + '_' + this._uid)[0].style.left)
      document.getElementsByClassName("bg_"+this._uid)[0].style.width = (bigSize-smallSize) + "%"
      document.getElementsByClassName("bg_"+this._uid)[0].style.left = smallSize + "%"
    },
    setOutput(index){
      let size = (document.getElementById('slider_'+this._uid).offsetWidth -25) / (this.max - this.min);
      let left = (this.values[index] - this.min) * size
      left = (left / (document.getElementById('slider_'+this._uid).offsetWidth -25)) * 100
      document.getElementsByClassName("output--" + index + '_' + this._uid)[0].style.left = left + "%";
      this.setBgSize()
    },
    dragable(){
      let vm = this
      $( ".output_" + vm._uid ).draggable({
        containment: "#slider_"+vm._uid,
        drag: function( event, ui ) {
          vm.drag(event,ui)
        },
        stop: function () {
          var l = ( 100 * parseFloat($(this).position().left / parseFloat($(this).parent().width())) ) + "%" ;
          $(this).css("left", l);
          vm.sendValues()
        }
      });
    },
    add(){
      this.values.push(this.max)
      this.sendValues()
    },
    remove(){
      if(this.count == 2) return
      this.count--
      this.values.pop()
      this.setBgSize()
      this.sendValues()
    },
    sendValues(){
      let ranges = []
      let vals = []
      for(let i in this.values){
        vals.push(this.values[i])
      }
      vals = vals.sort(function(a, b){return a-b})
      for(let i = 0; i< vals.length - 1; i++){
        if(i > 0) ranges.push({from:vals[i]+1,to:vals[i+1]})
        else ranges.push({from:vals[i],to:vals[i+1]})
      }
      this.$emit('send-values',ranges)
    }
  },
  async mounted() {
    this.dragable()
    for(let i in this.values){
      this.setOutput(i)
    }
  },
  async created(){
    if(this.modelValues != undefined && this.modelValues.length > 0){
      this.values = this.modelValues
      this.count = this.values.length
    }
    else  this.values = [this.min,this.max]

  },
}
</script>

<style lang="scss" scoped>
@import url('https://fonts.googleapis.com/css?family=Lato:400,700');
.slider{
  width:100%;
  height: 6px;
  position:relative;
  background: lightgray;
  &--flex{
    display: flex;
  }
  &--output{
    position:absolute;
    top:-11px!important;
    left:0px;
    width: 30px;
    height: 30px;
    color:white;
    background: linear-gradient(90deg, #FF1564 0%, #FD7328 100%);
    white-space: nowrap;
    text-align: center;
    border-radius: 50%;
    z-index:1;
    cursor: pointer;
    font-family: Lato;
    font-style: normal;
    font-weight: bold;
    font-size: 11px;
    letter-spacing: 0.01em;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &--background{
    background: linear-gradient(90deg, #FF1564 0%, #FD7328 100%);
    width:100%;
    max-width: 100%;
    left:0;
    position: absolute;
    height: 6px;
  }
  &--actions{
    display: flex;
    align-items:center;
    flex-direction: column;
    justify-content: flex-end;
    margin-top: -10px;
    margin-left: 30px;
    button{
      margin:0;
      padding:0;
      font-family: Lato;
      font-style: normal;
      font-weight: bold;
      font-size: 12px;
      text-align: center;
      letter-spacing: 0.01em;
      color: #FFFFFF;
      background: #96C800;
      width: 16px;
      height: 16px;
      outline:none;
      margin-bottom: 2px;
      border:none;
    }
    button:nth-child(2){

      &:disabled{
        opacity: 0.5;
      }
    }
  }
}
</style>
