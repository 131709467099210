<template>
  <svg
    width="16"
    height="17"
    viewBox="0 0 16 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_1589_1255)">
      <path
        d="M15.4569 8.2975C15.435 8.24813 14.9056 7.07375 13.7287 5.89687C12.1606 4.32875 10.18 3.5 7.99999 3.5C5.81999 3.5 3.83937 4.32875 2.27124 5.89687C1.09437 7.07375 0.562494 8.25 0.543119 8.2975C0.51469 8.36144 0.5 8.43064 0.5 8.50062C0.5 8.5706 0.51469 8.6398 0.543119 8.70375C0.564994 8.75312 1.09437 9.92688 2.27124 11.1038C3.83937 12.6713 5.81999 13.5 7.99999 13.5C10.18 13.5 12.1606 12.6713 13.7287 11.1038C14.9056 9.92688 15.435 8.75312 15.4569 8.70375C15.4853 8.6398 15.5 8.5706 15.5 8.50062C15.5 8.43064 15.4853 8.36144 15.4569 8.2975ZM7.99999 11C7.50554 11 7.02219 10.8534 6.61107 10.5787C6.19995 10.304 5.87951 9.91352 5.6903 9.45671C5.50108 8.99989 5.45157 8.49723 5.54803 8.01227C5.64449 7.52732 5.8826 7.08186 6.23223 6.73223C6.58186 6.3826 7.02732 6.1445 7.51227 6.04804C7.99722 5.95157 8.49989 6.00108 8.9567 6.1903C9.41352 6.37952 9.80396 6.69995 10.0787 7.11107C10.3534 7.5222 10.5 8.00555 10.5 8.5C10.5 9.16304 10.2366 9.79893 9.76776 10.2678C9.29892 10.7366 8.66304 11 7.99999 11Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_1589_1255">
        <rect
          width="16"
          height="16"
          fill="white"
          transform="translate(0 0.5)"
        />
      </clipPath>
    </defs>
  </svg>
</template>
