<template>
  <div class="ActivityTabs">
    <div class="ActivityTabs--tab" :class="{ active: selected == 0 }" v-on:click="$emit('select', 0)">
      {{ $t("activity") }}
    </div>
    <div class="ActivityTabs--tab" v-if="isClient" :class="{ active: selected == 1 }" v-on:click="$emit('select', 1)">
      {{ $t("participants") }}
    </div>
    <div class="ActivityTabs--tab" v-if="isClient && communityCurrentActivity.type != 'VIDEOCHAT'"
      :class="{ active: selected == 2 }" v-on:click="$emit('select', 2)">
      {{ $t("results") }}
    </div>
    <div class="ActivityTabs--tab" v-if="isClient && seeGraphicsTab" :class="{ active: selected == 3 }" v-on:click="$emit('select', 3)">
      {{ $t("graphics") }}
    </div>
    <div @mouseenter="setShowNoGraphics(true)" @mouseleave="setShowNoGraphics(false)"
      class="ActivityTabs--tab desactivated" v-if="isClient && communityCurrentActivity.type == 'WAT_SURVEY' && !seeGraphicsTab">
      {{ $t("graphics") }}
      <div v-show="showNoGraphics">
        {{ "Aún no ha terminado de configurar la encuesta" }}
      </div>
    </div>
    <div class="ActivityTabs--tab" v-if="isClient && communityCurrentActivity.type == 'WAT_UX'"
      :class="{ active: selected == 4 }" v-on:click="$emit('select', 4)">
      Grabaciones
    </div>
    <div class="ActivityTabs--tab" v-if="isClient && communityCurrentActivity.type != 'VIDEOCHAT'"
      :class="{ active: selected == 5 }" v-on:click="$emit('select', 5)">
      Analisis
    </div>
    <div class="ActivityTabs--tab" v-if="isClient && communityCurrentActivity.type == 'VIDEOCHAT'"
      :class="{ active: selected == 6 }" v-on:click="$emit('select', 6)">
      {{ $t("results") }}
    </div>
  </div>
</template>
<script>
import { ref } from "vue";
import { mapGetters } from "vuex";
export default {
  name: "ActivityTabs",
  props: {
    selected: 0,
  },
  computed: {
    ...mapGetters(["communityCurrentActivity", "isClient"]),
    seeGraphicsTab() {
      return (
        this.communityCurrentActivity.type == 'WAT_SURVEY' && this.communityCurrentActivity.surveyId != undefined) && ((this.communityCurrentActivity.bigActivity && this.communityCurrentActivity.status == "FINISHED") || !this.communityCurrentActivity.bigActivity)
    }
  },
  setup() {
    const showNoGraphics = ref(false);

    const setShowNoGraphics = (val) => {
      showNoGraphics.value = val;
    };
    return { showNoGraphics, setShowNoGraphics };
  },
};
</script>
<style scoped lang="scss">
.ActivityTabs {
  text-transform: uppercase;
  font-size: 13px;
  font-weight: bold;
  display: flex;

  &--tab {
    background: #f6f6f6;
    cursor: pointer;
    display: flex;
    margin-right: 5px;
    padding: 7px 16px;
    font-size: 15px;
    letter-spacing: -0.01em;

    @media only screen and (max-width: 600px) {
    margin-right: 0;
    padding: 3.5px 8px;
    font-size: 3.6vw;
  }

    &:last-of-type {
      margin-right: 10px;
    }

    &.active {
      background: white;
    }

    &.desactivated {
      position: relative;
      background-color: rgba(255, 255, 255, 0.3);
      cursor: not-allowed;

      >div {
        position: absolute;
        z-index: 2;
        color: black;
        background-color: white;
        font-size: 13px;
        padding: 10px;
        width: 150px;
        top: -55px;
        left: 0;
        text-transform: initial;
        border-radius: 5px;
        font-weight: 400;

        &::after {
          content: " ";
          position: absolute;
          top: 100%;
          /* At the top of the tooltip */
          left: 50%;
          margin-left: -5px;
          border-width: 5px;
          border-style: solid;
          border-color: white transparent transparent transparent;
        }
      }
    }

    @media screen and (max-width: 768px) {
      position: relative;
      margin-top: 40px;
    }
  }
}
</style>
