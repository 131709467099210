<template>
  <div class="ActivityImage" :class="{'whiteFilter':whiteFilter}">
    <img v-if="type=='WAT_CHAT'" src="@/assets/img/activities/chat-icon.svg"/>
    <img v-if="type=='WAT_OPINION'" src="@/assets/img/activities/opinion-icon.svg"/>
    <img v-if="type=='WAT_TASK'" src="@/assets/img/activities/task-icon.svg"/>
    <img v-if="type=='WAT_POLL'" src="@/assets/img/activities/poll-icon.svg"/>
    <img v-if="type=='VIDEOCHAT'" src="@/assets/img/activities/video-icon.svg"/>
    <img v-if="type=='WAT_SURVEY'" src="@/assets/img/activities/survey-icon.svg"/>
    <img v-if="type=='EL_HEATMAP'" src="@/assets/img/activities/heatmap-icon.svg"/>
    <img v-if="type=='WAT_UX'" src="@/assets/img/activities/watux-icon.svg"/>
  </div>
</template>
<script>
export default {
  props: {
    type : "",
    whiteFilter:{
      type: Boolean,
      default: false
    }
  }
}
</script>
<style scoped lang="scss">
.ActivityImage{
  margin: 4px;
  img{
    width: 28px;
  }
  &.whiteFilter{
    filter: brightness(0) invert(1);
  }
}
</style>
