<template>
  <div id="uxAnnotationSearchBar">
    <IconSearch v-show="!searching" @click="focusInput" />
    <IconX
      class="cleanSearch"
      :width="23"
      :height="12"
      fill="#8134FF"
      v-show="searching"
      @click="clearInput"
    />
    <div class="searchBox">
      <input
        ref="input"
        type="text"
        @focus="handleOnFocusInput"
        @blur="handleOnBlurInput"
        @keydown="handleKeyDown"
        @input="handleOnChange"
        :value="searhText"
      />
      <p v-show="!searching">{{ placeHolder }}</p>
    </div>
  </div>
</template>
<script setup>
import { defineProps, defineEmits, ref, watch } from 'vue'
import IconSearch from '../icons/IconSearch.vue'
import IconX from '../icons/IconX.vue'

const props = defineProps(['placeHolder'])
const emit = defineEmits(['search', 'cleanSearch'])

const input = ref(null)
const searhText = ref('')
const searching = ref(false)
const pressedKey = ref('')

watch(searhText, async (newSearch, oldSearch) => {
  if (newSearch == '') {
    searching.value = false
    input.value.blur()
    emit('cleanSearch')
  }
})

const focusInput = () => {
  input.value.focus()
}
const handleOnFocusInput = () => {
  searching.value = true
}
const handleOnBlurInput = () => {
  if (searhText.value == '') {
    searching.value = false
  }
}
const handleKeyDown = (e) => {
  pressedKey.value = e.key
}
const handleOnChange = (e) => {
  searhText.value = e.target.value
  e.preventDefault()
  emit('search', searhText.value)
  return
}
const clearInput = () => {
  searhText.value = ''
}
</script>
<style lang="scss" scoped>
#uxAnnotationSearchBar {
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
  background-color: white;
  padding: 14px;
  border-radius: 7px;
  > svg {
    cursor: pointer;
  }
  .searchBox {
    position: relative;
    width: 100%;
    input {
      background-color: transparent;
      border: none;
      outline: none;
      display: flex;
      width: 100%;
      height: 30px;
    }
    > p {
      width: 100%;
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      margin: auto 0;
      height: fit-content;
      pointer-events: none;
    }
  }
}
</style>
