<template>
  <div class="items">
    <template v-if="items.length">
      <button
        class="item"
        :class="{ 'is-selected': index === selectedIndex }"
        v-for="(item, index) in items"
        :key="index"
        @click="selectItem(index)"
      >
        <img :src="item.picture">
        {{ item.nickname }}
        <span :style="{'background':item.online ? '#a1de5f' : 'lightgray'}"></span>
      </button>
    </template>
    <div class="item" v-else>
      Sin resultados
    </div>
  </div>
</template>

<script>
export default {
  props: {
    items: {
      type: Array,
      required: true,
    },

    command: {
      type: Function,
      required: true,
    },
  },

  data() {
    return {
      selectedIndex: 0,
    }
  },

  watch: {
    items() {
      this.selectedIndex = 0
    },
  },

  methods: {
    onKeyDown({ event }) {
      if (event.key === 'ArrowUp') {
        this.upHandler()
        return true
      }

      if (event.key === 'ArrowDown') {
        this.downHandler()
        return true
      }

      if (event.key === 'Enter') {
        this.enterHandler()
        return true
      }

      return false
    },

    upHandler() {
      this.selectedIndex = ((this.selectedIndex + this.items.length) - 1) % this.items.length
    },

    downHandler() {
      this.selectedIndex = (this.selectedIndex + 1) % this.items.length
    },

    enterHandler() {
      this.selectItem(this.selectedIndex)
    },

    selectItem(index) {
      const item = this.items[index].nickname

      if (item) {
        this.command({ id: item })
      }
    },
  },
}
</script>

<style lang="scss">
.items {
  max-height: 250px;
  overflow-y: auto;
  padding: 0.2rem;
  position: relative;
  background: #FFF;
  color: rgba(0, 0, 0, 0.8);
  font-size: 0.9rem;
  box-shadow:
    0 0 0 1px rgba(0, 0, 0, 0.05),
    0px 10px 20px rgba(0, 0, 0, 0.1),
  ;
  &::-webkit-scrollbar-track{
    background-color: #e6e6e6;
  }
  &::-webkit-scrollbar {
    background-color: #8134ff;
    width: 3px;
  }
  &::-webkit-scrollbar-thumb{
    border-radius: 10px;
    background: #b3b3b3;
  }
}

.item {
  display: block;
  margin: 0;
  width: 100%;
  text-align: left;
  background: transparent;
  border-radius: 3px;
  border: 1px solid transparent;
  padding: 0.2rem 0.4rem;
  cursor: pointer;
  font-family: Arial;
  display: flex;
  align-items: center;
  gap: 5px;

  img{
    border-radius: 50%;
    width: 20px;
    height: 20px;
  }

  span{
    padding: 1px;
    width: 10px;
    height: 10px;
    border-radius: 50%;
  }

  &:hover{
    color: white;
    background: #00acee;
  }

  &.is-selected {
    color: white;
    background: #00acee;
  }
}
</style>
