<template lang="html">
  <div class="QuestionAdvancedModal">
    <div class="QuestionAdvancedModal--Box">
      <div class="QuestionAdvancedModal--Header">
        <h1>{{title}}</h1>
        <h2 v-if="!breadcrumbs">{{subTitle}}</h2>
        <div class="QuestionAdvancedModal--Breadcrumbs" v-if="breadcrumbs">
          <p v-for="b in breadcrumbsList" v-on:click="$emit('lastStep')">{{b.title}}</p>
          <p>{{subTitle}}</p>
        </div>
      </div>
      <div class="AdvancedOptions">
        <div class="AdvancedOptions--Content" id="AdvancedOptions--Scroll" v-show="!(filter.taskNum == -1 && filter.questionNum == 2)">
          <div class="AdvancedOptions--SelectAll">
            <CheckBox :text="'Seleccionar todos'" v-model="selectAll" v-on:change="selectAllOptions()"/>
          </div>
          <div class="AdvancedOptions--Values">
            <div class="AdvancedOptions--Value" :class="'AdvancedDragIndex_'+i" v-for="(v,i) in values" draggable="true" :key="v.id">
              <div class="AdvancedOptions--ValueContent">
                <CheckBox :text="v.value" v-model="v.selected"/>
                <i v-show="!v.showGroups && groups.length > 0 && !isAddedToGroup(v.id)" v-on:click="showGroups(v)" class="fa fa-caret-down" aria-hidden="true"></i>
                <i v-show="v.showGroups && groups.length > 0 && !isAddedToGroup(v.id)" v-on:click="showGroups(v)" class="fa fa-caret-up" aria-hidden="true"></i>
              </div>
              <div v-show="v.showGroups && groups.length > 0 && !isAddedToGroup(v.id)" class="AdvancedOptions--Selector">
                <p v-for="g in groups" v-on:click="addValueToGroup(g,v)" v-show="!g.groupId">
                  {{g.groupName}}
                  <span>+</span>
                </p>
              </div>
            </div>
          </div>
          <div class="AdvancedOptions--Groups" v-for="(g,i) in groups" :id="'AdvancedOptionsDropContent_'+i">
            <h3>{{$t('watmonitor_values_group')}} <i class="fa fa-trash-o" aria-hidden="true" v-on:click="removeGroup(i)"></i></h3>
            <div class="AdvancedOptions--Group" :class="{'disabled':g.groupId}">
              <input type="text" v-model="g.groupName" placeholder="Nombre de agrupación" :disabled="g.groupId">
              <div class="AdvancedOptions--GroupValuesBox">
                <div class="AdvancedOptions--NotValuesAdded" v-show="g.values.length == 0">
                  <img src="#/assets/img/WatMonitor/dragHand.svg">
                  <p>{{$t('watmonitor_drag_answers')}}</p>
                </div>
                <div class="AdvancedOptions--GroupValues" v-show="g.values.length > 0">
                  <div v-for="(v,vIndex) in g.values" v-on:click="removeValueFromGroup(vIndex,i)">
                    <p></p>
                    {{v.value}}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="AdvancedOptions--Add">
            <p v-on:click="addGroup()">{{$t('watmonitor_add_group')}}</p>
          </div>
        </div>
        <div class="AdvancedOptions--Content" v-show="filter.taskNum == -1 && filter.questionNum == 2">
          <div class="AdvancedOptions--Range">
            <MultiRange :key="rangesKey" v-on:send-values="getRangeValues" :modelValues="rangesArray"/>
            <div class="AdvancedOptions--RangeDefault" v-show="filter.taskNum == -1">
              <div class="AdvancedOptions--Add">
                <p v-on:click="setDefaultRanges()">{{$t('watmonitor_default_ranges')}}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="QuestionAdvancedModal--Footer">
        <div>
          <button type="button" name="button"  v-on:click="lastStep()" v-if="breadcrumbs" >{{$t('watmonitor_go_back')}}</button>
        </div>
        <div>
          <button type="button" name="button" v-on:click="save({values:values,groups:groups, ranges:ranges})">{{$t('watmonitor_action_apply')}}</button>
          <button type="button" name="button" v-on:click="close()">{{$t('watmonitor_action_close')}}</button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import CheckBox from './CheckBox'
import MultiRange from './MultiRange'
import { inject } from "vue";

import Vue from 'vue'

export default {
  name: 'QuestionAdvancedModal',
  components:{
    CheckBox,
    MultiRange
  },
  props: {
    title:String,
    subTitle: String,
    filter:Object,
    breadcrumbs:{
      type:Boolean,
      default:false,
    },
    breadcrumbsList:Array,
  },
  data() {
    return {
      values:[],
      groups:[],
      ranges:[],
      rangesArray:[],
      selectAll:false,
      valuesKey:0,
      rangesKey:0
    }
  },
  methods:{
    selectAllOptions(){
      let vm = this
      this.values = this.values.map(function(v,i){
        v.selected = !vm.selectAll
        return v
      })
    },
    removeGroup(groupIndex){
      if(this.groups[groupIndex].groupId) return
      this.groups.splice(groupIndex,1)
    },
    addGroup(){
      this.groups.push({groupName:'',values:[], selected:true})
    },
    removeValueFromGroup(valueIndex,groupIndex){
      if(this.groups[groupIndex].groupId) return
      this.groups[groupIndex].values.splice(valueIndex,1)
    },
    getRangeValues(e){
      this.ranges = e
    },
    setDefaultRanges(){
      this.rangesArray = [18,30,50,100]
      this.ranges = [
        {from:18,to:30},
        {from:31,to:50},
        {from:51,to:100},
      ]
      this.rangesKey++
    },
    showGroups(v){
      v.showGroups = !v.showGroups
    },
    setDraggable(){
      let vm = this
      $(function() {
        $( ".AdvancedOptions--Value").draggable({
            helper:"clone",
            scroll:true,
            containment:'#AdvancedOptions--Scroll',
            stop: function( event, ui ) {
              let valueIndex = null
              let groupIndex = null
              for(let i in vm.groups){
                var coords = $('#AdvancedOptionsDropContent_'+i).position();
                coords.bottom = coords.top + $('#AdvancedOptionsDropContent_'+i).height() + $('#AdvancedOptions--Scroll').scrollTop();
                coords.bottomRight = coords.left + $('#AdvancedOptionsDropContent_'+i).width();
                coords.top = coords.top + $('#AdvancedOptions--Scroll').scrollTop();
                if(ui.position.top >= coords.top && ui.position.top <= coords.bottom && ui.position.left >= coords.left && ui.position.left <= coords.bottomRight){
                  valueIndex = Number.parseInt(ui.helper[0].classList[1].replace("AdvancedDragIndex_",""))
                  let added = false
                  for(let v of vm.groups[i].values){
                    if(v.id == vm.values[valueIndex].id) added = true
                  }
                  if(!added) vm.groups[i].values.push(vm.values[valueIndex])
                }
              }
              if(valueIndex!=null)vm.values[valueIndex].selected = true
            },
            // drag: function( e, ui)  {
            //   if(ui.position.top>0) {
            //     console.log(document.getElementById("AdvancedOptions--Scroll").scrollHeight);
            //     console.log(e.pageY);
            //     console.log(e.clientY);
            //     console.log("---------------------------");
            //     ui.position.top = document.getElementById("AdvancedOptions--Scroll").scrollHeight
            //     // console.log(e.pageY - $(document).scrollTop() - $('.AdvancedOptions--Content').offset().top);
            //     // ui.position.top = (e.pageY - $(document).scrollTop() - $('#AdvancedOptions--Scroll').offset().top);
            //   }
            // }
        });
      });
    },
    isAddedToGroup(valueId){
      let added = false
      for(let g of this.groups){
        for(let v of g.values){
          if(v.id == valueId) added = true
        }
      }
      return added
    },
    addValueToGroup(group,value){
      group.values.push(value)
      value.selected = true
      value.showGroups = false
    },
  },
  async created(){
    if(this.filter.values != undefined) {
      this.filter.values = this.filter.values.map(function(v,i){
      v.showGroups = false
      return v
    })
    }
    this.values = this.filter.values != undefined ? JSON.parse(JSON.stringify(this.filter.values)) : []
    this.groups = this.filter.groups != undefined ? JSON.parse(JSON.stringify(this.filter.groups)) : []
    this.ranges = this.filter.ranges != undefined ? JSON.parse(JSON.stringify(this.filter.ranges)) : []
    for(let i in this.ranges){
      if(i == 0) this.rangesArray.push(this.ranges[i].from)
      this.rangesArray.push(this.ranges[i].to)
    }
    let allSelected = true
    for(let v of this.values){
      if(!v.selected) allSelected = false
    }
    this.selectAll = allSelected
  },
  async mounted(){
    this.setDraggable()
  },
  setup (props, { emit }) {
    const emitter = inject("emitter")
    const close = (event) => {
      emit("close")
      emitter.emit("close-monitor-advanced-filters-modal")
    }

    const lastStep = (event) => {
      emit('lastStep')
      emitter.emit("lastStep-monitor-advanced-filters-modal")
    }

    const save = (event) => {
      emit('save', event)
      emitter.emit('save-monitor-advanced-filters-modal', event)
    }
    return {
        close,
        lastStep,
        save
    }
  }
}
</script>

<style lang="scss" scoped>
@import url('https://fonts.googleapis.com/css?family=Lato:400,700');
.QuestionAdvancedModal{
  position: fixed;
  width: 100%;
  height: 100%;
  top:0;
  left:0;
  background: rgba(0,0,0,0.35);
  z-index: 10000;
  display: flex;
  align-items: center;
  justify-content: center;
  h1,h2{
    font-family: Lato;
    font-style: normal;
    font-weight: bold;
    letter-spacing: 0.01em;
    color: #404040;
    margin: 0 0 9px 0;
  }
  h1{
    font-size: 23px;
  }
  h2{
    font-size: 15px;
  }
  &--Box{
    background: #FFFFFF;
    padding: 20px 30px;
    width: 60%;
  }
  &--Footer{
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
    div{
      display: flex;
    }
    button{
      font-family: Lato;
      font-size: 12px;
      font-weight: bold;
      color: white;
      padding: 10px 20px;
      border-radius: 2px;
      letter-spacing: 0.02em;
      text-transform: uppercase;
      margin: 10px 0;
      cursor: pointer;
      background: #96C800;
      box-shadow: 2px 2px 0px rgba(0, 0, 0, 0.14);
      position: relative;
      transition-duration: 0.4s;
      text-transform: uppercase;
      display: flex;
      align-items: center;
      overflow: hidden;
      justify-content: center;
      border: none;
      &:before {
        content:"";
        position: absolute;
        top: 0px;
        left: 0px;
        width: 0px;
        height: 0px;
        background: rgba(255, 255, 255, 0.2);
        transition: width 0.5s, height 0.5s;
      }
      &:hover:before {
        width: 100%;
        height: 100%;
      }
     &:first-of-type{
       background: #96C800;
       margin-right: 15px;
     }
     &:last-of-type{
       background: #747474;
     }
    }
  }
  &--Breadcrumbs{
    display: flex;
    font-family: 'Open Sans', Helvetica, Arial, sans-serif;
    p{
      margin:0;
      font-size:12px;
      cursor: pointer;
      &:hover{
        color:black;
      }
    }
    p+p{
      font-weight: 600;
      color:black;
    }
    p+p:before {
      padding: 8px;
      color: black;
      content: "/\00a0";
      font-weight: 500;
    }
  }
  @media (max-width: 768px) {
    &--Box{
      width: 95%;
      padding: 15px 20px;
    }
  }
}
.AdvancedOptions{
  background: #F7F4F8;
  padding: 20px;
  &--Content{
    background: #FFFFFF;
    border: 1px solid #E1E1E1;
    box-shadow: 2px 2px 1px rgba(0, 0, 0, 0.07);
    padding: 15px;
    max-height: calc(100vh - 200px);
    overflow-y: auto;
    overflow-x: hidden;
    position: relative;
    &::-webkit-scrollbar-track{
      background-color: #e6e6e6;
    }
    &::-webkit-scrollbar {
      background: linear-gradient(90deg, #FF1564 0%, #FD7328 100%);
      width: 5px;
    }
    &::-webkit-scrollbar-thumb{
      border-radius: 20px;
      background: linear-gradient(90deg, #FF1564 0%, #FD7328 100%);
    }
  }
  &--Range{
    margin-top: 10px;
  }
  &--Values{
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(140px, 1fr));
    grid-gap: 6px;
    max-height: 200px;
    overflow-y: auto;
    overflow-x: hidden;
    &::-webkit-scrollbar-track{
      background-color: #e6e6e6;
    }
    &::-webkit-scrollbar {
      background: linear-gradient(90deg, #FF1564 0%, #FD7328 100%);
      width: 5px;
    }
    &::-webkit-scrollbar-thumb{
      border-radius: 20px;
      background: linear-gradient(90deg, #FF1564 0%, #FD7328 100%);
    }
  }
  &--Selector{
    display: flex;
    position: absolute;
    flex-direction: column;
    background: white;
    z-index: 1;
    width: 162px;
    cursor: pointer;
    box-shadow: 2px 2px 1px rgba(0, 0, 0, 0.07);
    border: 1px solid #E1E1E1;
    p{
      font-family: Lato;
      font-style: normal;
      font-weight: normal;
      font-size: 11px;
      letter-spacing: 0.02em;
      text-transform: uppercase;
      color: #939393;
      margin: 0;
      padding: 5px 10px;
      position:relative;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: space-between;
      &:hover{
        background: #F7F4F8;
      }
    }
    span{
      font-size: 14px;
      font-weight: bold;
      color: #96C800;
    }
  }
  &--SelectAll{
    height: fit-content;
    font-size: 12px;
    margin: 8px 0;
    padding: 0 10px;
    border-bottom: 1px solid lightgray;
  }
  &--ValueContent{
    padding: 8px 10px;
    background: #F7F4F8;
    height: fit-content;
    cursor: move;
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    i{
      cursor: pointer;
    }
  }
  &--Groups{
    h3{
      font-family: Lato;
      font-style: normal;
      font-weight: normal;
      font-size: 11px;
      letter-spacing: 0.02em;
      text-transform: uppercase;
      color: #939393;
      display: flex;
      align-items: center;
      margin-top: 20px;
      margin-bottom: 10px;
      i{
        font-size: 17px;
        margin-left: 20px;
        color: #FF1564;
        cursor: pointer;
      }
    }
  }
  &--Group{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 15px;
    input{
      background: #FFFFFF;
      border: 1px solid #D8D8D8;
      height: 33px;
      outline:none;
      font-family: Lato;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      letter-spacing: 0.01em;
      &::placeholder{
        color: #BDBDBD;
      }
    }
    &.disabled{
      input,.AdvancedOptions--GroupValuesBox{
        opacity: 0.5;
      }
    }
  }
  &--GroupValuesBox{
    background: #F2F2F2;
    border: 1px dashed #939393;
    padding: 20px;
  }
  &--GroupValues{
    display: flex;
    flex-wrap: wrap;
    div{
      background: linear-gradient(90deg, #FF1564 0%, #FD7328 100%);
      border-radius: 14px;
      font-family: Roboto Condensed;
      font-style: normal;
      font-weight: bold;
      font-size: 12px;
      letter-spacing: 0.03em;
      color: #FFFFFF;
      padding: 5px 10px;
      width: fit-content;
      margin-right: 9px;
      margin-bottom: 9px;
      display: flex;
      align-items: center;
      height: fit-content;
      cursor: pointer;
    }
    p{
      background: white;
      height: 15px;
      width: 15px;
      border-radius: 50%;
      position:relative;
      margin:0 7px 0 0;
      &:before, &:after {
        position: absolute;
        content: ' ';
        height: 10px;
        width: 1.5px;
        background-color: #96C800;
        left: 7px;
        top: 2px;
      }
      &:before {
        transform: rotate(50deg);
      }
      &:after {
        transform: rotate(-50deg);
      }
    }
  }
  &--NotValuesAdded{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    p{
      font-family: 'Open Sans', Helvetica, Arial, sans-serif;
      color: #74818C;
      font-size: 14px;
    }
  }
  &--Add{
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    letter-spacing: 0.02em;
    text-transform: uppercase;
    color: #96C800;
    border-top: 1px solid #E0E0E0;
    padding-top: 10px;
    p{
      margin:0px;
      cursor: pointer;
      &:hover{
        font-weight: bold;
      }
    }
  }
  &--RangeDefault{
    p{
      width: 100%;
    }
  }
  @media (max-width: 768px) {
    &--Group{
      display: flex;
      flex-direction: column;
    }
  }
}
</style>
<style lang="scss">
.AdvancedOptions--SelectAll{
  .CheckBox p {
    font-size: 12px;
    margin: -1px 0 0 22px;
  }
}
</style>
