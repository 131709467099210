<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.0957 4.34998C5.0957 3.84239 5.29734 3.35558 5.65627 2.99666C6.01519 2.63774 6.502 2.4361 7.00959 2.4361H12.7513C13.2589 2.4361 13.7457 2.63774 14.1046 2.99666C14.4635 3.35558 14.6651 3.84239 14.6651 4.34998V17.7472L9.88042 15.3548L5.0957 17.7472V4.34998Z"
      fill="url(#paint0_linear_1725_91)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_1725_91"
        x1="5.0957"
        y1="10.0917"
        x2="14.6651"
        y2="10.0917"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#FF1564" />
        <stop offset="1" stop-color="#FD7328" />
      </linearGradient>
    </defs>
  </svg>
</template>
