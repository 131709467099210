<template>
  <svg
    width="47"
    height="48"
    viewBox="0 0 47 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x="6.10352e-05"
      y="0.819458"
      width="47"
      height="47"
      rx="23.5"
      fill="#96C800"
    />
    <g clip-path="url(#clip0_1647_492)">
      <path
        d="M27.5001 21.3195C27.5001 22.1106 27.2655 22.8839 26.8259 23.5417C26.3864 24.1995 25.7617 24.7122 25.0308 25.015C24.2999 25.3177 23.4956 25.3969 22.7197 25.2426C21.9438 25.0883 21.231 24.7073 20.6716 24.1479C20.1122 23.5885 19.7313 22.8757 19.5769 22.0998C19.4226 21.3239 19.5018 20.5196 19.8045 19.7887C20.1073 19.0578 20.62 18.4331 21.2778 17.9936C21.9356 17.5541 22.7089 17.3195 23.5001 17.3195C24.5609 17.3195 25.5783 17.7409 26.3285 18.491C27.0786 19.2412 27.5001 20.2586 27.5001 21.3195ZM36.5001 34.3195C36.5001 34.5847 36.3947 34.839 36.2072 35.0266C36.0196 35.2141 35.7653 35.3195 35.5001 35.3195H11.5001C11.2348 35.3195 10.9805 35.2141 10.793 35.0266C10.6054 34.839 10.5001 34.5847 10.5001 34.3195C10.5001 34.0542 10.6054 33.7999 10.793 33.6123C10.9805 33.4248 11.2348 33.3195 11.5001 33.3195H22.5001V31.2695C19.9474 31.0129 17.591 29.7851 15.9182 27.84C14.2454 25.8949 13.3842 23.3812 13.5126 20.819C13.641 18.2567 14.7493 15.8418 16.6083 14.0737C18.4672 12.3056 20.9346 11.3196 23.5001 11.3196C26.0656 11.3196 28.5329 12.3056 30.3919 14.0737C32.2508 15.8418 33.3591 18.2567 33.4875 20.819C33.616 23.3812 32.7547 25.8949 31.0819 27.84C29.4091 29.7851 27.0527 31.0129 24.5001 31.2695V33.3195H35.5001C35.7653 33.3195 36.0196 33.4248 36.2072 33.6123C36.3947 33.7999 36.5001 34.0542 36.5001 34.3195ZM23.5001 27.3195C24.6867 27.3195 25.8468 26.9676 26.8335 26.3083C27.8202 25.649 28.5892 24.7119 29.0433 23.6156C29.4975 22.5192 29.6163 21.3128 29.3848 20.1489C29.1533 18.985 28.5818 17.9159 27.7427 17.0768C26.9036 16.2377 25.8345 15.6663 24.6706 15.4347C23.5067 15.2032 22.3003 15.3221 21.204 15.7762C20.1076 16.2303 19.1705 16.9993 18.5112 17.986C17.852 18.9727 17.5001 20.1328 17.5001 21.3195C17.5017 22.9102 18.1344 24.4354 19.2592 25.5603C20.3841 26.6851 21.9093 27.3178 23.5001 27.3195Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_1647_492">
        <rect
          width="32"
          height="32"
          fill="white"
          transform="translate(7.50006 8.31946)"
        />
      </clipPath>
    </defs>
  </svg>
</template>
