<template lang="html">
  <GradientBackLayout
    class="Activity"
    :title="$filters.translate($filters.formatActivityType(communityCurrentActivity.type)).toUpperCase()"
    :shadowText="$filters.translate('activity').toUpperCase()">
    <BoxShadow class="Activity--Box Activity--InfoBox" :class="{'Activity--Results':activityTab == 2}">
      <ActivityTabs class="Activity__Tabs" :selected="activityTab" v-on:select="activityTab = $event"/>
      <div class="Activity--HeaderImage" v-if="communityCurrentActivity.headerImage">
        <img :src="communityCurrentActivity.headerImage" alt="header image">
      </div>
      <div class="Activity--Header" v-show="activityTab != 3">
        <div class="Activity--HeaderInfo">
          <h1>{{communityCurrentActivity.title}}</h1>
          <div class="Activity--Status">
            <p>{{$t('date_from_to2', { from: formatDateForTranslation(communityCurrentActivity.dateFrom), to: formatDateForTranslation(communityCurrentActivity.dateTo) })}}</p>
            <span></span>
            <ActivityStatus :status="communityCurrentActivity.status" />
          </div>
        </div>
        <div class="Activity--MembersNum">
          <p>{{communityCurrentActivity.membersNum}}</p>
          <p>{{$t('members_text')}}</p>
        </div>
      </div>
      <div class="Activity--Progress" v-show="activityTab != 3">
        <progress-bar
          :key="communityCurrentActivity.identifier"
          :value="(communityCurrentActivity.participatedTesterNum/communityCurrentActivity.membersNum)*100"
        />
      </div>
      <div class="Activity--Type">
          <ActivityImage :type="communityCurrentActivity.type" whiteFilter/>
      </div>
      <div class="Activity--Info" v-if="activityTab == 0">
        <div v-if="isAvailableActivityForUser()">
          <div class="Activity--Description">
            <div v-html="communityCurrentActivity.description">
            </div>
          </div>
          <div class="Activity--RaffleInfo" v-if="communityCurrentActivity.raffleData && communityCurrentActivity.raffleData.raffleEnabled">
            <h2>{{ $t('activity_info_raffle_title') }}</h2>
            <p v-html="$t('activity_info_raffle_desc')"></p>
            <p v-html="$t('activity_info_raffle_desc_2', { description: communityCurrentActivity.raffleData.prizeDescription })"></p>
            <i class="fas fa-gifts"></i>
            <img :src="communityCurrentActivity.raffleData.prizeImage" alt="">
          </div>
          <div class="Activity--VideoChatUsers" v-show="communityCurrentActivity.type == 'VIDEOCHAT'">
            <h4>{{$t('videochat_access_url_title')}}</h4>
            <div class="">
              <p><b>{{$t('videochat_access_users')}}: </b>{{getVideoChatUrl()}} <i v-on:click="copyVideochatUrl()" class="far fa-copy"></i></p>
              <p><b>{{$t('videochat_access_observers')}}: </b>{{getVideoChatUrlObs()}} <i v-on:click="copyVideochatUrlObs()" class="far fa-copy"></i></p>
              <!--<div v-for="obd of communityCurrentActivity.observerDatas">
                <p><b>{{obd.name}} {{$t('videochat_access_direct')}}</b>: {{getVideoChatUrl(obd.authToken)}} <i v-on:click="copyVideochatUrl(obd.authToken)" class="far fa-copy"></i></p>
                <p><b>{{obd.name}} {{$t('videochat_access_waiting_room')}}</b>: {{getVideoChatUrl(obd.authToken,true)}} <i v-on:click="copyVideochatUrl(obd.authToken,true)" class="far fa-copy"></i></p>
              </div>-->
            </div>
          </div>
          <div class="Activity--Media">
            <div class="Activity--MediaWrapper" v-if="communityCurrentActivity.images">
              <h4><i class="far fa-images"></i> {{$t('activity_info_images')}}</h4>
              <MediaViewer :images="$filters.formatMedia(communityCurrentActivity.images)"/>
            </div>
            <div class="Activity--MediaWrapper" v-if="communityCurrentActivity.videoDatas">
              <h4><i class="fas fa-video"></i> {{$t('activity_info_videos')}}</h4>
              <MediaViewer class="activity__Media--Videos" :allowTitle="true" :videos="$filters.formatVideo(communityCurrentActivity.videoDatas)" />
            </div>
            <div class="activity__Media" v-if="communityCurrentActivity.youTubeVideo">
              <h4><i class="fas fa-link"></i> {{$t('activity_info_videos_external')}}</h4>
              <MediaViewer class="activity__Media--Videos" :youTubeVideo="$filters.formatYouTubeVideo(communityCurrentActivity.youTubeVideo)" />
            </div>
            <div class="Activity--MediaWrapper" v-if="communityCurrentActivity.files">
              <h4><i class="fas fa-link"></i> {{$t('activity_info_files')}}</h4>
              <a v-for="f in communityCurrentActivity.files" :href="f"><i class="fas fa-file-download"></i>{{$t('comment_download_subject')}} - {{(f.substr(f.lastIndexOf("/")+1))}}</a>
            </div>
          </div>
          <div class="Activity--DebateTopics" v-if="communityCurrentActivity.debateTopics != undefined">
            <h4>{{$t('activity_debate_topics')}}</h4>
            <div v-for="dt in communityCurrentActivity.debateTopics">
              <p><span :style="{'background':dt.topicTagColor}">{{dt.topicTagText}}</span> - {{dt.topicText}}</p>
            </div>
          </div>
          <div class="center" v-if="communityCurrentActivity.type == 'WAT_SURVEY' && isUser && !communityCurrentActivity.userHasParticipated">
            <CommunityButton v-if="!communityCurrentActivity.userHasStartedSurvey" v-on:click="$router.push({ name: 'activities.survey', params: { id: communityCurrentActivityId } })">{{$t('start_survey')}}</CommunityButton>
            <CommunityButton v-if="communityCurrentActivity.userHasStartedSurvey" v-on:click="$router.push({ name: 'activities.survey', params: { id: communityCurrentActivityId } })">{{$t('continue_survey')}}</CommunityButton>
          </div>
          <div class="center" v-if="communityCurrentActivity.type == 'WAT_UX' && isUser && !watUxCompleted">
            <CommunityButton v-on:click="$router.push({ name: 'activities.watux', params: { id: communityCurrentActivityId } })">Empezar WatUx</CommunityButton>
          </div>
          <div class="center" v-if="communityCurrentActivity.type == 'WAT_POLL' && isUser && !communityCurrentActivity.userHasParticipated">
            <CommunityButton v-on:click="$router.push({ name: 'activities.survey', params: { id: communityCurrentActivityId } })">{{$t('start_poll')}}</CommunityButton>
          </div>
          <div class="center" v-if="communityCurrentActivity.type == 'EL_HEATMAP' && isUser && !communityCurrentActivity.userHasParticipated">
            <CommunityButton v-on:click="$router.push({ name: 'activities.heatmap', params: { id: communityCurrentActivityId } })">{{$t('start_activity')}}</CommunityButton>
          </div>
          <div class="Activity--PollResult" v-if="communityCurrentActivity.type == 'WAT_POLL' && ((isClient && communityCurrentActivity.participatedTesterNum > 0) || (isUser && communityCurrentActivity.userHasParticipated))">
            <h1>{{$t('poll_answers')}}</h1>
            <div class="Activity--PollResultInfo">
              <p><span>1.</span> {{pollAnswers.question.questionText}}</p>
              <p><span>{{ $t('answers_of_total',{answersNum:pollAnswers.answeredMembers, total:pollAnswers.totalMembers}) }}</span></p>
            </div>
            <div class="Activity--PollAnswers" v-if="pollAnswers.question.type =='SINGLE_SELECT' || pollAnswers.question.type=='MULTI_SELECT'">
              <div v-for="(value, key, index) in pollAnswers.resultSummatory">
                  {{key}}
                  <div class="Activity--PollResultProgress" style="height:10px">
                    <progress-bar :value="(value/pollAnswers.totalMembers)*100"/>
                  </div>
              </div>
            </div>
            <div v-if="pollAnswers.question.type == 'RANKING'">
              <p v-html="$t('answers_average',{average:(Math.ceil((pollAnswers.resultAverage + Number.EPSILON) * 100) / 100), total:pollAnswers.question.maxValue})"></p>
              <div class="Activity--PollResultProgress" style="height:10px">
                <ProgressBar :value="((pollAnswers.resultAverage)/pollAnswers.question.maxValue)*100"/>
              </div>
            </div>
          </div>
        </div>
        <ActivityNoAvailable v-if="!isAvailableActivityForUser()" />
      </div>
      <ActivityMembersPage ref="ActivityMembersPage" v-if="activityTab == 1 && activityMembers.length > 0" :tags="tags"/>
      <ActivityResults v-show="activityTab == 2" :activity="communityCurrentActivity" :result="result" :heatMapAnswers="heatMapAnswers" :videoUrl="videoUrl" :members="activityMembersResult" :currentTab="activityTab"  v-on:comment-medal="handleAddMedal" />
      <WatUxMonitor v-if="activityTab == 4" :activityTitle="communityCurrentActivity.title" :uxData="result.answerReports" :activityType="result.type" :communityTranscriptions="transcriptions" isActivity />
      <Monitor v-if="activityTab == 3 && communityCurrentActivity.surveyId != undefined" ref="Monitor" :key="communityCurrentActivity.surveyId" :testId="communityCurrentActivity.surveyId" :dashboardLink="result.dashboardLink" v-on:send-monitor-questions="monitorQuestions = $event"/>
      <!--<Analysis v-if="activityTab == 5" />-->
      <AnalysisDesign v-if="activityTab == 5" v-on:redirect-to-comment="redirectCommentFromAnalysis($event)" :status="communityCurrentActivity.status"/>
      <AnalysisFocus v-if="activityTab == 6" :videos="videoUrl" v-on:fetch-videos="fetchVideoChatRecords()"/>
      <div v-if="communityCurrentActivity.type == 'EL_SURVEY' && isUser && !surveyIsAnswered">
        <SurveyExecution
          :questions="communityCurrentActivity.communityQuestions"
          v-on:send-survey-response="sendQuestionsResponse"/>
          <div class="activity__Body__task__Survey--errors" v-if="surveyErrors.length > 0">
            <div v-for="error in surveyErrors">
              {{error}}
            </div>
          </div>
      </div>
    </BoxShadow>
    <BoxShadow class="Activity--BoxQuestions" v-if="communityCurrentActivity.type == 'WAT_SURVEY' && activityTab == 0 && isClient && communityCurrentActivity.surveyTask" >
      <ActivityQuestionList :questionList="communityCurrentActivity.surveyTask.questions" />
    </BoxShadow>
    <div v-if="isAvailableActivityForUser() && activityTab == 0">
      <div id="CommentsScroll" v-if="showComments()">
        <BoxShadow class="Activity--Box Activity--CommentsBox" v-if="pinnedComments.length > 0 && typeOpinionAndCommented" style="display:flex;flex-direction:column;">
          <h3>{{$t('comments_pinned')}}</h3>
          <CommentsList
            :comments="pinnedComments"
            :allowDelete="false"
            :allowLike="false"
            :allowDislike="false"
            :allowAttach="false"
            :allowTag="false"
            :isObservator="isObservator"
            :allowReply="false"
            :theme="theme"
            :allowAddMedals="isModerator"
            :allowPinComment="isModerator"
            :allowChatButton="isModerator"
            :fileUploadPath="fileUploadPath"
            :videoUploadPath="videoUploadPath"
            :fileUploadHeaders="uploadHeaders"
            :hideGamification="communityInfo.hideGamification || (communityInfo.privateLabs && isUser)"
            :allowUnseenCheck="isClient"
            :allowMenu="isModerator"
            :debateTopics="communityCurrentActivity.debateTopics"
            :user="user"
            v-on:pin-comment="handlePinComment"
            v-on:open-chat="handleOpenChat"
            />
        </BoxShadow>
        <CommentInput
          class="Activity--Box"
          :user="user"
          :allowAttach="true"
          :disableSubmit="communityInfo.status == 'REVISION'"
          :disableEntry="communityCurrentActivity.type == 'WAT_TASK' && isClient"
          :debateTopics="communityCurrentActivity.debateTopics"
          :fileUploadPath="fileUploadPath"
          :videoUploadPath="videoUploadPath"
          :fileUploadHeaders="uploadHeaders"
          :fetchRecomendationUsers="fetchRecomendationUsers"
          :onlineUsers="onlineUsers"
          :isLoading="isLoadingComment"
          v-on:submit-comment="handleAddComment"
          v-if="!isObservator && communityCurrentActivity.status != 'FINISHED'" />
        <BoxShadow class="Activity--Box Activity--CommentsBox" v-if="comments.objects.length > 0 && typeOpinionAndCommented" style="display:flex;flex-direction:column;">
          <div class="SearchComments" v-if="showSearchComments">
            <div class="SearchComments--Header">
              <h4>{{$t('activity_comments_search')}}</h4>
              <CommunityButton v-on:click="wordDownload()" v-if="commentsSearch.length > 0">{{$t('export_word')}}</CommunityButton>
            </div>
            <div class="SearchComments--SearchBar">
              <SearchBar :placeholder="$t('activity_comment_text')" v-on:key-up="searchComments" ref="SearchBar"/>
            </div>
            <div class="SearchComments--Select">
              <select v-model="searchCommentsUserId" v-on:change="searchComments($event,undefined)" :class="{'option-undefined':searchCommentsUserId == undefined}">
                  <option :value="undefined">{{$t('role_user')}}</option>
                  <option v-for="m in activityMembersWithComments" :value="m.identifier">{{m.nickname}}</option>
              </select>
              <i class="fa fa-caret-down"></i>
            </div>
            <div v-if="communityCurrentActivity.debateTopics != undefined">
              <p v-on:click="searchCommentsTag(dt.topicId)" :class="{'active':searchCommentsTopicId == dt.topicId}" :style="{'background':dt.topicTagColor}" v-for="dt in communityCurrentActivity.debateTopics"> {{dt.topicTagText}}</p>
            </div>
            <div class="SearchComments--Result">
              <h4 v-if="commentsSearch.length > 0">{{$t('activity_comments_found')}}</h4>
              <CommentsList
                v-if="commentsSearch.length > 0"
                :comments="commentsSearch"
                :theme="theme"
                :allowReply="false"
                :allowLike="false"
                :allowDislike="false"
                :hideGamification="communityInfo.hideGamification || (communityInfo.privateLabs && isUser)"
                :debateTopics="communityCurrentActivity.debateTopics"
                :user="user"
                />
            </div>
            <Separator/>
          </div>
          <div class="Activity--CommentsHeader">
            <div class="Activity--CommentsActions" v-if="isClient">
              <CommunityButton v-on:click="showSearchComments = true">{{$t('activity_search_comments')}}</CommunityButton>
              <CommunityButton v-on:click="printComments()">{{$t('activity_print_comments')}}</CommunityButton>
            </div>
            <paginate
            v-if="comments.pages > 1"
            v-model="currentCommentsPage"
            :page-count="comments.pages"
            :click-handler="handleCommentsPageChange"
            :container-class="'WatPagination'"
            prev-class="item-disable"
            next-class="item-disable"
            :margin-pages="3"
              />
          </div>
          <CommentsList
            v-if="comments.objects"
            :comments="comments.objects"
            :allowDelete="isModerator"
            :allowLike="!isObservator"
            :allowDislike="!isObservator"
            :allowAttach="!isObservator"
            :allowTag="isModerator"
            :isObservator="isObservator"
            :allowReply="!isObservator && !disableActivityAction()"
            :theme="theme"
            :allowAddMedals="isModerator"
            :allowPinComment="isModerator"
            :allowChatButton="isModerator"
            :fileUploadPath="fileUploadPath"
            :videoUploadPath="videoUploadPath"
            :fileUploadHeaders="uploadHeaders"
            :hideGamification="communityInfo.hideGamification || (communityInfo.privateLabs && isUser)"
            :allowUnseenCheck="isClient"
            :allowMenu="isModerator"
            :debateTopics="communityCurrentActivity.debateTopics"
            :user="user"
            :fetchRecomendationUsers="fetchRecomendationUsers"
            v-on:submit-reply="handleAddReply"
            v-on:comment-delete="handleDeleteComment"
            v-on:comment-reaction="handleCommentReaction"
            v-on:comment-addTagsNotes="handleAddTagsNotes"
            v-on:comment-medal="handleAddMedal"
            v-on:pin-comment="handlePinComment"
            v-on:open-chat="handleOpenChat"
            />
            <div class="Activity--CommentsHeader">
              <div class="">

              </div>
                <paginate
                v-if="comments.pages > 1"
                v-model="currentCommentsPage"
                :page-count="comments.pages"
                :click-handler="handleCommentsPageChange"
                :container-class="'WatPagination'"
                prev-class="item-disable"
                next-class="item-disable"
                :margin-pages="3"
                  />
            </div>
        </BoxShadow>
      </div>
      <div v-if="!typeOpinionAndCommented && showComments()" class="OpinionAlert">
        <h3>{{$t('activity_wat_opinion_alert_title')}}</h3>
        <p>{{$t('activity_wat_opinion_alert_text')}}</p>
      </div>
      <div id="printableComments">
        <h4>{{$t('activity_comments_title',{activity:communityCurrentActivity.title})}} - {{communityCurrentActivityId}}</h4>
        <CommentsList
          :comments="commentsPrintable"
          :allowReply="false"
          :allowReplyParent="false"
          :allowLike="!isObservator"
          :allowDislike="!isObservator"
          :allowAttach="!isObservator"
          :debateTopics="communityCurrentActivity.debateTopics"
          :allowTag="true"
          :forExporting="true"
          :theme="theme"
          :hideGamification="communityInfo.hideGamification || (communityInfo.privateLabs && isUser)"
          :allowUnseenCheck="isClient"
          />
      </div>
    </div>
    <MonitorActions v-show="activityTab == 3" :monitorQuestions="monitorQuestions" v-on:open-filters="$refs.Monitor.openFiltersModal()" v-on:save="$refs.Monitor.saveReport()" />
  </GradientBackLayout>
</template>

<script>
import Swal from "sweetalert2";
import { mapGetters, mapMutations } from "vuex";
import store from "@/store";
import moment from "moment-timezone";
import Paginate from "vuejs-paginate-next";
import FileSaver from "file-saver";
import { map, flatMap, intersectionWith, isEqual } from "lodash";
import ApiService from "~/services/api.service";
import CommentInput from "~/components/CommentInput";
import CommentsList from "~/components/CommentsList";
import Tag from "~/components/Tag";
import MediaViewer from "~/components/MediaViewer";
import BoxShadow from "@/components/BoxShadow.vue";
import ProgressBar from "@/components/ProgressBar.vue";
import CommunityButton from "@/components/CommunityButton.vue";
import ActivityStatus from "@/components/Activity/ActivityStatus.vue";
import ActivityResults from "@/components/Activity/ActivityResults.vue";
import ActivityQuestionList from "@/components/Activity/ActivityQuestionList.vue";
import GradientBackLayout from "@/layouts/GradientBackLayout";
import SearchBar from '~/components/SearchBar/SearchBar.vue';
import Separator from "@/components/Separator";
import CommunityUser from "@/components/CommunityUser";
import SurveyExecution from "@/components/Survey/SurveyExecution";
import Monitor from "@/components/Monitor";
import ActivityTabs from "@/components/Activity/ActivityTabs";
import ActivityImage from "@/components/Activity/ActivityImage";
import ActivityMembersPage from "@/components/Activity/ActivityMembersPage";
import ActivityNoAvailable from "@/components/Activity/ActivityNoAvailable";
import MonitorActions from "@/components/Activity/MonitorActions";
import WatUxMonitor from "~/components/WatMonitor/WatUxMonitor";
import AnalysisDesign from "@/components/Activity/AnalysisDesign";
import AnalysisFocus from "@/components/VideochatAnalysis/AnalysisFocus";
import { COMMUNITY_FETCH_STATUS } from "@/store/community/info.module";
import { COMMUNITY_FETCH_VIDEO_RECORD,COMMUNITY_UX_FETCH_TRANSCRIPTION } from "@/store/community/chat.module";
import {
  COMMUNITY_FETCH_ACTIVITIES,
  COMMUNITY_ADD_ACTIVITY_COMMENT,
  COMMUNITY_ADD_ACTIVITY_COMMENT_REPLY,
  COMMUNITY_UPDATE_ACTIVITY_COMMENT_TAGS,
  COMMUNITY_DELETE_ACTIVITY_COMMENT,
  COMMUNITY_FETCH_ACTIVITY_COMMENTS,
  COMMUNITY_ADD_ACTIVITY_COMMENT_MEDAL,
  COMMUNITY_PIN_COMMENT,
  COMMUNITY_ADD_ACTIVITY_COMMENT_REACTION,
  COMMUNITY_FETCH_COMMENTS_SEARCH,
  COMMUNITY_FETCH_CURRENT_ACTIVITY_PARTICIPANTS,
  COMMUNITY_FETCH_CURRENT_ACTIVITY_PARTICIPANTS_EL,
  COMMUNITY_FETCH_ACTIVITY_QUESTION_RESULT,
  COMMUNITY_FETCH_ACTIVITY_RESULTS,
  COMMUNITY_FETCH_ACTIVITY_QUESTION_ANSWERED,
  COMMUNITY_ADD_ACTIVITY_QUESTIONS_RESPONSE,
  COMMUNITY_FETCH_ACTIVITY,
  COMMUNITY_SET_UNSEEN_COMMENTS,
  COMMUNITY_FETCH_ACTIVITY_PINNED_COMMENTS,
  COMMUNITY_FETCH_ACTIVITY_USER_COMMENT_RECOMENDATION,
  COMMUNITY_FETCH_ACTIVITY_UNSEEN_MENTION_PER_PAGE,
  ACTIVITY_SET_RAFFLE_NOTIFICATED,
  COMMUNITY_FETCH_ACTIVITY_RESULTS_COMMENTS_PARTICIPANTS
} from "@/store/community/activities.module";
import { COMMUNITY_FETCH_TAGS } from "@/store/community/tags.module";
import { copyTextToClipboard } from "@/utils";
export default {
  name: "ActivityInfoPage",
  components: {
    GradientBackLayout,
    BoxShadow,
    ProgressBar,
    CommunityButton,
    ActivityStatus,
    CommentInput,
    CommentsList,
    Paginate,
    SearchBar,
    Separator,
    MediaViewer,
    CommunityUser,
    Tag,
    ActivityResults,
    SurveyExecution,
    ActivityQuestionList,
    Monitor,
    ActivityTabs,
    WatUxMonitor,
    ActivityImage,
    ActivityMembersPage,
    ActivityNoAvailable,
    MonitorActions,
    AnalysisDesign,
    AnalysisFocus
  },
  computed: {
    ...mapGetters([
      "theme",
      "communityInfo",
      "communityActivities",
      "communityCurrentActivity",
      "communityCurrentActivityId",
      "communityCurrentActivityComments",
      "communityCurrentActivityCommentsPage",
      "communityCurrentActivityTotalCommentsPage",
      "communityCurrentActivityParticipants",
      "communityChatMessagesObs",
      "currentActivityCommentsErrorStatus",
      "isClient",
      "isUser",
      "isObservator",
      "isModerator",
      "userProfile",
      "onlineUsers"
    ]),
    typeOpinionAndCommented() {
      const returned500 = this.currentActivityCommentsErrorStatus === 500;
      const isTypeOpinion =
        this.communityCurrentActivity.type === "WAT_OPINION";

      return returned500 ? (isTypeOpinion ? false : true) : true;
    },
  },
  props: {
    notificationCommentId: {
      type: [Number, String],
      default: -1,
    },
    notificationCommentPage: {
      type: [Number, String],
      default: -1,
    },
  },
  data() {
    return {
      isLoadingParticipants: false,
      monitorQuestions: [],
      activityTab: 0,
      user: {},
      result: {},
      videoUrl: [],
      transcriptions: [],
      activityMembers: [],
      activityMembersResult: [],
      tags: [],
      seenComments: [],
      comments: { objects: [] },
      pinnedComments: [],
      commentsPrintable: [],
      commentsSearch: [],
      isSearchingComments: false,
      showSearchComments: false,
      searchCommentsUserId: undefined,
      searchCommentsTopicId: null,
      currentCommentsPage: 1,
      pollAnswers: { question: {} },
      heatMapAnswers: {},
      fileUploadPath: process.env.VUE_APP_API_URL + "/experienceLabs/file",
      videoUploadPath: process.env.VUE_APP_API_URL + "/experienceLabs/video",
      uploadHeaders: ApiService.getConfig().headers,
      surveyErrors: [],
      surveyAnswers: [],
      surveyIsAnswered: true,
      watUxCompleted: false,
      notificationCommentIdData:-1,
      notificationCommentPageData:-1,
      mentionsCount: {},
      page:0,
      pageSize: 20,
      activityMembersWithComments: [],
        isLoadingComment: false, 
    };
  },
  watch: {
    communityCurrentActivityId: function (val) {
      this.activityTab = 0;
      this.init();
    },
  },
  methods: {
    async fetchHeatMapResults() {
      if (this.communityCurrentActivity.communityQuestions[0] == undefined)
        return;
      await store
        .dispatch(COMMUNITY_FETCH_ACTIVITY_QUESTION_RESULT, {
          questionId:
            this.communityCurrentActivity.communityQuestions[0].identifier,
        })
        .then((data) => {
          this.heatMapAnswers = data;
        });
    },
    sendQuestionsResponse: async function (questions) {
      await store
        .dispatch(COMMUNITY_ADD_ACTIVITY_QUESTIONS_RESPONSE, {
          activityId: this.communityCurrentActivityId,
          data: questions,
        })
        .then((data) => {
          this.surveyErrors = [];
          if (
            data == "" ||
            data == undefined ||
            data == null ||
            data.participatedMessage
          ) {
            Swal.fire(
              this.$t("activity_answer_saved"),
              this.$t("activity_thanks_participated")
            );
            this.fetchQuestionsResponse();
            this.fetchComments(0);
            this.surveyIsAnswered = true;
            this.fetchActivities();
            store.dispatch(COMMUNITY_FETCH_ACTIVITY, {
              activityId: this.communityCurrentActivity.identifier,
            });
          } else {
            if ((data.error = "communityAnswerException")) {
              let id = 0;
              for (let i in this.communityCurrentActivity.communityQuestions) {
                if (
                  data.data.questionId ==
                  this.communityCurrentActivity.communityQuestions[i].identifier
                )
                  id = parseInt(i) + 1;
              }
              this.surveyErrors.push(
                this.$t("msg_activity_task_answer_not_saved", {
                  questionNum: id,
                }) + "."
              );
            } else if ((data.error = "Question not found")) {
              let id = 0;
              for (let i in this.communityCurrentActivity.communityQuestions) {
                if (
                  data.data.questionId ==
                  this.communityCurrentActivity.communityQuestions[i].identifier
                )
                  id = parseInt(i) + 1;
              }
              this.surveyErrors.push(
                this.$t("msg_activity_task_answer_not_answered", {
                  questionNum: id,
                }) + "."
              );
            } else if ((data.error = "User has not answered all questions"))
              this.surveyErrors.push(this.$t("activity_task_not_answered"));
          }
        });
    },
    formatDateForTranslation(date) {
      if (date == undefined) return "-";
      return moment(date).format("DD/MM/YYYY HH:mm");
    },
    createUserObject() {
      this.user.name = this.userProfile.nickname;
      this.user.avatar =
        this.theme.moderatorLogo && this.isModerator
          ? this.theme.moderatorLogo
          : this.userProfile.image;
      this.user.labs = this.userProfile.points;
      this.user.isModerator = this.isClient;
      this.user.isObservator = this.isObservator;
    },
    handleCommentsPageChange(e) {
      this.fetchComments(this.currentCommentsPage - 1);
      $("#CommentsScroll")[0].scrollIntoView({ behavior: "smooth" });
    },
    async fetchActivities() {
      let priority = "myActivities";
      await store.dispatch(COMMUNITY_FETCH_ACTIVITIES, { priority });
    },
    async fetchActivityResultCommentsMembers() {
    await store.dispatch(COMMUNITY_FETCH_ACTIVITY_RESULTS_COMMENTS_PARTICIPANTS, { activityId: this.$route.params.id }).then((data) => {
        console.log("awaitstore.dispatch  data:", data)
        const allMembers = this.activityMembersResult.concat(data);

        this.activityMembersResult = allMembers.filter((member, index, self) => 
            index === self.findIndex((m) => (
                m.identifier === member.identifier
            ))
        );

        // Si hay resultados, poblar el array sin duplicados de miembros con comentarios
        if (this.activityMembersResult.length > 0) {
            let nicknamesList = [];
            this.activityMembersWithComments = [];

            this.activityMembersResult.forEach((amr) => {
                if (!nicknamesList.includes(amr.nickname)) {
                    this.activityMembersWithComments.push(amr);
                    nicknamesList.push(amr.nickname);
                }
            });
        } else {
            this.activityMembersWithComments = this.activityMembers;
        }
    });
}
,
    async fetchActivityMembers(fromScroll) {
      if(!fromScroll) this.activityMembers = []
      this.isLoadingParticipants = true
      if (!fromScroll && this.page > 0) {
        this.pageSize = (this.page + 1) * this.pageSize
        this.page = 0
      }
      await store
        .dispatch(
          this.isClient
            ? COMMUNITY_FETCH_CURRENT_ACTIVITY_PARTICIPANTS_EL
            : COMMUNITY_FETCH_CURRENT_ACTIVITY_PARTICIPANTS,
          {
            order: "recent",
            activityId: this.$route.params.id,
            page: this.page,
            pageSize: this.pageSize
          }
        )
        .then((data) => {
          if (data != undefined) {
            if (fromScroll) {
              this.activityMembers = this.activityMembers.concat(data.communityMembers)
              const { scrollTop, scrollHeight, clientHeight } = document.getElementsByClassName('Activity')[0];
              this.$refs.ActivityMembersPage.pageScroll(scrollTop, scrollHeight, clientHeight)
            } else {
              this.activityMembers = data.communityMembers
            }
            this.isLoadingParticipants = false
          } else {
            this.activityMembers = []
            let vm = this;
            Swal.fire({
              title: vm.$t("error_connection"),
              text: vm.$t("error_network"),
              icon: "error"
            })
          }
        }).catch((response) => {
          let vm = this;
          Swal.fire({
            title: vm.$t("error_connection"),
            text: vm.$t("error_network"),
            icon: "error"
          })
        });
    },
    async fetchTags() {
      await store.dispatch(COMMUNITY_FETCH_TAGS).then((data) => {
        if (data.publicTags == undefined && data.tags == undefined)
          this.tags = [];
        else if (data.publicTags == undefined) this.tags = data.tags;
        else if (data.tags == undefined) this.tags = data.publicTags;
        else {
          this.tags = data.publicTags.concat(data.tags);
        }
      });
    },
    async fetchCommentsPrintable() {
      await store
        .dispatch(COMMUNITY_FETCH_ACTIVITY_COMMENTS, {
          communityId: this.theme.communityId,
          activityId: this.$route.params.id,
          page: -1,
          noSave: true,
        })
        .then((data) => {
          this.commentsPrintable = data.objects;
        });
    },
    async fetchPinnedComments() {
      await store
        .dispatch(COMMUNITY_FETCH_ACTIVITY_PINNED_COMMENTS, {
          communityId: this.theme.communityId,
          activityId: this.$route.params.id,
        })
        .then((data) => {
          this.pinnedComments = data.objects;
        });
    },
    async fetchComments(page) {
      await store
        .dispatch(COMMUNITY_FETCH_ACTIVITY_COMMENTS, {
          communityId: this.theme.communityId,
          activityId: this.$route.params.id,
          page: page,
        })
        .then((data) => {
          if (
            data.data ==
              "no root comment for this tester in a OPINION activity" ||
            data.data == "this tester hasnt answered the poll question yet"
          )
            return;
          else {
            data.objects = data.objects.filter(function(comment){
              if(!comment.text.includes('<script') && !comment.text.includes('<style')) {
                return comment
              }
            })
            this.comments = data;
          }
          
        });
    },
    async goToNotificationComment(comment = false) {
      let commentId = null;
      let commentPage = null;

      if (!comment) {
        commentId = this.$route.params.notificationCommentId;
        commentPage = this.$route.params.notificationCommentPage;
      } else {
        commentId = Math.floor(Number(comment.commentId) / 1e6);
        commentPage = comment.commentPage;
        console.log("goToNotificationComment  commentId:", commentId)
        console.log("goToNotificationComment  commentPage:", commentPage)
      }

      const result= await this.fetchComments(commentPage);
      console.log("goToNotificationComment  result:", result)
    this.$nextTick(() => {
      this.scrollToComment(commentId);
    });
  
    },

  scrollToComment(commentId) {
    const commentElement = document.querySelector(`.comment_${commentId}`);
    if (commentElement) {
        commentElement.scrollIntoView({ block: 'center', behavior: 'smooth' });
      commentElement.style.backgroundColor = 'rgba(255, 215, 0, 0.3)'; 
      commentElement.style.padding = '5px'; 
      commentElement.style.borderRadius = '5px'; 
          commentElement.style.transition = 'all 0.5s ease'; 
        setTimeout(() => {
          commentElement.style.backgroundColor = 'transparent'; 
          commentElement.style.padding = '0'; 
          commentElement.style.borderRadius = '0'; 
        }, 5000);
        }
  },
    redirectCommentFromAnalysis(comment){
      this.activityTab = 0
      this.$nextTick(() => {
        this.goToNotificationComment(comment);
      });    
    },
    async handleAddComment(e) {
      let videos = [];
      let images = [];
      let files = [];
      if (e.video) {
        videos[0] = e.video;
      }

      if (e.images) {
        images = e.images.map((image) => image.filePath);
      }

      if (e.files) {
        files = e.files.map((file) => file.filePath);
      }
      const payload =
        this.communityCurrentActivity.type === "WAT_OPINION"
          ? {
              activityId: this.communityCurrentActivityId,
              isRootComment: !this.typeOpinionAndCommented,
              text: e.comment,
              images: images.length > 0 ? images : undefined,
              videos: videos.length > 0 ? videos : undefined,
              files: files.length > 0 ? files : undefined,
            }
          : {
              activityId: this.communityCurrentActivityId,
              text: e.comment,
              images: images.length > 0 ? images : undefined,
              videos: videos.length > 0 ? videos : undefined,
              files: files.length > 0 ? files : undefined,
              topicId: e.topicId != null ? e.topicId : undefined,
            };

        // Mostrar el loader
        this.isLoadingComment = true;

        try {
            // Realizar la operación de guardar el comentario
            const data = await store.dispatch(COMMUNITY_ADD_ACTIVITY_COMMENT, payload);

            // Verificar si se guardó correctamente y mostrar mensaje
            if (data.participatedMessage) {
                Swal.fire(
                    this.$t("activity_comment_registered"),
                    this.$t("activity_thanks_participated_2")
                );
                this.fetchActivities();
            }

            // Volver a cargar los comentarios
            await this.fetchComments(this.currentCommentsPage - 1);
        } catch (error) {
            console.error("Error al guardar el comentario:", error);
        } finally {
            // Ocultar el loader
            this.isLoadingComment = false;
        }
    },
    async handleAddReply(e) {
      let files = [];
      let videos = [];
      let images = [];

      if (e.video) {
        videos[0] = e.video;
      }

      if (e.images) {
        images = e.images.map((image) => image.filePath);
      }

      if (e.files) {
        files = e.files.map((file) => file.filePath);
      }

      await store
        .dispatch(COMMUNITY_ADD_ACTIVITY_COMMENT_REPLY, {
          activityId: this.communityCurrentActivityId,
          text: e.comment,
          parentCommentId: e.commentId,
          files: files.length > 0 ? files : undefined,
          images: images.length > 0 ? images : undefined,
          videos: videos.length > 0 ? videos : undefined,
        })
        .then((data) => {
          this.fetchComments(this.currentCommentsPage - 1);
        });
    },
    async handleDeleteComment(e) {
      let vm = this;
      Swal.fire({
        title: vm.$t("msg_confirmation"),
        text: vm.$t("msg_activity_comment_confirm_delete"),
        icon: "warning",
        cancelButtonText: vm.$t("action_cancel"),
        confirmButtonText: vm.$t("msg_confirm_delete"),
        showCancelButton: true,
        customClass: {
          popup: "swal-customWarning",
        },
      }).then((result) => {
        if (result.isConfirmed) {
          store
            .dispatch(COMMUNITY_DELETE_ACTIVITY_COMMENT, {
              commentId: e.commentId,
            })
            .then((data) => {
              this.fetchComments(this.currentCommentsPage - 1);
            });
        }
      });
    },
    async handleCommentReaction(e) {
      if (this.isUser) {
        await store.dispatch(COMMUNITY_ADD_ACTIVITY_COMMENT_REACTION, {
          activityId: this.communityCurrentActivityId,
          commentId: e.commentId,
          reactionType:
            e.currentReaction == e.newReaction
              ? COMMENT_REACTION_NONE
              : e.newReaction,
        });
        this.fetchComments(this.currentCommentsPage - 1);
      }
    },
    async handleAddTagsNotes(e) {
      let tags = {
        text: e.comment.moderatorNote,
        tags: e.comment.moderatorTags,
      };

      store
        .dispatch(COMMUNITY_UPDATE_ACTIVITY_COMMENT_TAGS, {
          activityId: this.communityCurrentActivityId,
          commentId: e.comment.identifier,
          tags: tags,
        })
        .then((data) => {
          this.fetchComments(this.currentCommentsPage - 1);
        });
    },
    async handleAddMedal(e) {
  const result = await Swal.fire({
    title: this.$t("msg_confirmation"),
    text: this.$t("msg_medal_add"),
    icon: 'warning',
    showCancelButton: true,
    confirmButtonText: this.$t("action_accept"),
    cancelButtonText: this.$t("action_cancel")
  });

  if (result.isConfirmed) {
    try {
      await store.dispatch(COMMUNITY_ADD_ACTIVITY_COMMENT_MEDAL, {
        commentId: e.commentId,
        medal: e.medal,
      });
      this.fetchComments(this.currentCommentsPage - 1);

      Swal.fire({
        title: '¡Añadido!',
        text: 'La medalla se ha añadido correctamente.',
        icon: 'success',
        confirmButtonText: 'OK'
      });
    } catch (error) {
      Swal.fire({
        title: 'Error',
        text: 'Hubo un problema al añadir la medalla. Por favor, inténtalo de nuevo.',
        icon: 'error',
        confirmButtonText: 'OK'
      });
    }
  }
},
    async handlePinComment(e) {
      const activityId = this.communityCurrentActivityId;
      const pinnedComments =
        this.communityCurrentActivity.communityPinnedComments;
      const isPinned =
        pinnedComments.length > 0
          ? !pinnedComments.some((o) => e.commentId === o.identifier)
          : true;

      await store.dispatch(COMMUNITY_PIN_COMMENT, {
        activityId,
        commentId: e.commentId,
        data: { pin: isPinned },
      });

      await store.dispatch(COMMUNITY_FETCH_ACTIVITY, { activityId });

      this.fetchComments(this.currentCommentsPage - 1);
      this.fetchPinnedComments();
    },
    async searchComments(i, text) {
      this.searchCommentsTopicId = null;
      if (this.isSearchingComments) return;
      this.isSearchingComments = true;
      if (
        this.$refs.SearchBar.text == "" &&
        this.searchCommentsUserId == undefined
      ) {
        this.commentsSearch = [];
        this.isSearchingComments = false;
        return;
      }
      let data = {
        searchTxt: this.$refs.SearchBar.text,
        activityId: this.communityCurrentActivityId,
        userId: this.searchCommentsUserId,
        page: 0,
        pageSize: 10,
      };
      await store
        .dispatch(COMMUNITY_FETCH_COMMENTS_SEARCH, data)
        .then((data) => {
          for (let c of data.objects) {
            if (c.text.includes(this.$refs.SearchBar.text)) {
              c.text = c.text
                .split(this.$refs.SearchBar.text)
                .join(
                  "<span style='background:var(--primary-color);color:white'>" +
                    this.$refs.SearchBar.text +
                    "</span>"
                );
            }
          }
          this.commentsSearch = data.objects;
        });
      this.isSearchingComments = false;
    },
    async wordDownload() {
      var vm = this;
      Swal.fire({
        title: vm.$t("msg_confirmation"),
        text: vm.$t("msg_file_download"),
        icon: "warning",
        cancelButtonText: vm.$t("action_cancel"),
        confirmButtonText: vm.$t("action_accept"),
        showCancelButton: true,
      }).then((result) => {
        if (result.isConfirmed) {
          (async () => {
            let data = {
              searchTxt: vm.$refs.SearchBar.text,
              activityId: vm.communityCurrentActivityId,
              userId: vm.searchCommentsUserIdsearchCommentsUserId,
              page: 0,
              pageSize: 10,
            };
            await store
              .dispatch(COMMUNITY_FETCH_COMMENTS_SEARCH_WORD, data)
              .then((data) => {
                var file = new Blob([data], {
                  type: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
                });
                FileSaver.saveAs(
                  file,
                  vm.$t("excel_title_activity_comments", {
                    activity: vm.communityCurrentActivityId,
                  }) + ".docx"
                );
              });
          })();
        }
      });
    },
    async fetchPollResults() {
      if (this.communityCurrentActivity.communityQuestions[0] == undefined)
        return;
      await store
        .dispatch(COMMUNITY_FETCH_ACTIVITY_QUESTION_RESULT, {
          questionId:
            this.communityCurrentActivity.communityQuestions[0].identifier,
        })
        .then((data) => {
          this.pollAnswers = data;
        });
    },
    async fetchQuestionsResponse() {
      let answers = [];
      if (this.communityCurrentActivity.communityQuestions != undefined) {
        for (let q of this.communityCurrentActivity.communityQuestions) {
          await store
            .dispatch(COMMUNITY_FETCH_ACTIVITY_QUESTION_RESULT, {
              questionId: q.identifier,
            })
            .then((data) => {
              answers.push(data);
            });
        }
      }
      this.surveyAnswers = answers;
    },
    checkQuestionsAnswered: async function () {
      for (let q of this.communityCurrentActivity.communityQuestions) {
        await store
          .dispatch(COMMUNITY_FETCH_ACTIVITY_QUESTION_ANSWERED, {
            activityId: this.communityCurrentActivityId,
            questionId: q.identifier,
          })
          .then((data) => {
            if (data.status == 404) {
              this.surveyIsAnswered = false;
            }
          });
      }
    },
    checkCheckpointsCompleted: async function () {
      var dataValidationUrl =
        "/tester/communities/" +
        this.communityCurrentActivity.communityId +
        "/activity/" +
        this.communityCurrentActivity.identifier +
        "/question/" +
        this.communityCurrentActivity.communityQuestions[0].identifier +
        "/uxDataValidation";

      store
        .dispatch("VALIDATE_DATA_COMPLETION", {
          testId: this.communityCurrentActivity.identifier,
          url: dataValidationUrl,
        })
        .then((response) => {
          if(!response.pendingCheckpoints){
            this.watUxCompleted = false
            return
          }
          this.watUxCompleted =
            response.pendingCheckpoints.length < 1 &&
            response.screenVideoReady &&
            response.webcamVideoReady
              ? true
              : false;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    handleOpenChat(e) {
      this.$router.push({ name: "chat", params: { chatSelected: e.memberId } });
    },
    disableActivityAction() {
      return this.communityCurrentActivity.status == "FINISHED" && this.isUser;
    },
    showComments() {
      if(this.communityCurrentActivity.type == "WAT_SURVEY" && this.communityCurrentActivity.bigActivity) return false
      return (
        !(
          (this.communityCurrentActivity.type == "WAT_POLL" ||
            this.communityCurrentActivity.type == "WAT_SURVEY" ||
            this.communityCurrentActivity.type == "EL_SURVEY" ||
            this.communityCurrentActivity.type == "EL_HEATMAP" ||
            this.communityCurrentActivity.type == "WAT_UX") &&
          this.isUser &&
          !this.communityCurrentActivity.userHasParticipated
        ) && this.communityCurrentActivity.type != "VIDEOCHAT"
      );
    },
    isAvailableActivityForUser() {
      return (
        (this.communityCurrentActivity.status == "ACTIVE" && this.isUser) ||
        this.isClient
      );
    },
    printComments() {
      var printContents =
        document.getElementById("printableComments").innerHTML;
      var originalContents = document.body.innerHTML;
      document.body.innerHTML = printContents;
      window.print();
      document.body.innerHTML = originalContents;
      location.reload();
    },
    async fetchActivityResult() {
      await store
        .dispatch(COMMUNITY_FETCH_ACTIVITY_RESULTS, {
          activityId: this.$route.params.id,
        })
        .then((data) => {
          this.result = data;
        })
      if (this.communityCurrentActivity.type == "VIDEOCHAT") this.fetchVideoChatRecords()
      if (this.communityCurrentActivity.type == "WAT_UX") this.fetchTranscription()
    },
    async fetchVideoChatRecords(){
      await store
          .dispatch(COMMUNITY_FETCH_VIDEO_RECORD, { id: this.$route.params.id })
          .then((data) => {
            this.videoUrl = [];
            this.videoUrl = data;
          })
          .catch((error) => {
            this.videoUrl = [];
          });
    },
    async fetchTranscription(){
      await store.dispatch(COMMUNITY_UX_FETCH_TRANSCRIPTION,{activityId: this.$route.params.id})
      .then((data) => {
        this.transcriptions = data
      })
    },
    async fetchActivity() {
      await store
        .dispatch(COMMUNITY_FETCH_ACTIVITY, {
          activityId: this.$route.params.id,
        })
        .then((data) => {
          if (
            data.type == "VIDEOCHAT" &&
            (data.status == "NEW" || data.status == "CONFIGURING")
          )
            setTimeout(this.fetchActivity, 6000);
          else if (data.type == "VIDEOCHAT" && data.status == "ACTIVE")
            this.$router.push({
              name: "activities.videochat",
              params: { id: data.identifier },
            });
        });
    },
    handleScroll(ev) {
      if (this.isClient && !this.isObservator) this.setUnseenComments(ev);
      if (this.isUser) this.setUnseenCommentsUser();
      if(this.activityTab == 1) this.participantsTabScroll()
    },
    participantsTabScroll(e){
      if(this.isLoadingParticipants) return
      const { scrollTop, scrollHeight, clientHeight } = document.getElementsByClassName('Activity')[0];
      if (scrollTop + clientHeight >= scrollHeight - 5){
        if(this.page == 0 && this.pageSize > 10){
          this.page = this.pageSize / 10
          this.pageSize = 10
        }
        else this.page++
        this.fetchActivityMembers(true)
      }
    },
    canSetUnseenComment(commentId){
      var element = document.querySelector(".comment_" + commentId);
      if (element == null || element == undefined) return false
      var position = element.getBoundingClientRect();
      if (position.top >= 0 && position.bottom <= window.innerHeight) return true
      return false
    },
    async setUnseenCommentsUser(){
      if (Object.keys(this.mentionsCount).length > 0){
        let data = []
        for (let c of this.comments.objects) {
          if (c.mentionedUserIds != undefined && c.mentionedUserIds[this.userProfile.id] == false && !this.seenComments.includes(c.identifier) && this.canSetUnseenComment(c.identifier)) {
            this.seenComments.push(c.identifier);
            data.push(c.identifier)
          }
          if (c.answers) {
            for (let a of c.answers) {
              if (a.mentionedUserIds != undefined && a.mentionedUserIds[this.userProfile.id] == false && this.canSetUnseenComment(a.identifier)) {
                this.seenComments.push(c.identifier);
                data.push(c.identifier);
              }
            }
          }
        }
        if (data.length > 0){
          await store.dispatch(COMMUNITY_SET_UNSEEN_COMMENTS, {
            commentIdsToSee: { commentIdsToSee: data },
            activityId: this.$route.params.id,
          });
        }
      }
    },
    async setUnseenComments(ev) {
      let data = [];
      let isThereNewComment = JSON.stringify(this.comments.objects);
      
      if (isThereNewComment.includes('"moderatorSeen":false')) {
        for (let c of this.comments.objects) {
          if (!c.moderatorSeen && !this.seenComments.includes(c.identifier) && this.canSetUnseenComment(c.identifier)) {
            data.push(c.identifier);
            this.seenComments.push(c.identifier);
          }

          if (c.answers) {
            for (let a of c.answers) {
              if (!a.moderatorSeen && !this.seenComments.includes(a.identifier) && this.canSetUnseenComment(a.identifier)) {
                data.push(a.identifier);
                this.seenComments.push(a.identifier);
              }
            }
          }
        }
      }
      if (data.length == 0) return;
      await store.dispatch(COMMUNITY_SET_UNSEEN_COMMENTS, {
        commentIdsToSee: { commentIdsToSee: data },
        activityId: this.$route.params.id,
      });
      await store.dispatch(COMMUNITY_FETCH_STATUS);
      let priority = "myActivities";
      await store.dispatch(COMMUNITY_FETCH_ACTIVITIES, { priority });
    },
    getVideoChatUrl(authToken, accesToWaitingRoom) {
      let url =
        location.origin +
        "/c/" +
        this.communityInfo.urlToken +
        "/activities/" +
        this.communityCurrentActivity.videochatPublicHash +
        "/video-chat-public";
      if (authToken != undefined) url += "?auth=" + authToken;
      if (accesToWaitingRoom) url += "&waitingRoom=true";
      return url;
    },
    getVideoChatUrlObs(){
      let url =
        location.origin +
        "/c/" +
        this.communityInfo.urlToken +
        "/activities/" +
        this.communityCurrentActivity.videochatPublicHash +
        "/video-chat-public-observer";
      return url;
    },
    copyVideochatUrl(authToken, accesToWaitingRoom) {
      copyTextToClipboard(this.getVideoChatUrl(authToken, accesToWaitingRoom));
    },
    copyVideochatUrlObs() {
      copyTextToClipboard(this.getVideoChatUrlObs());
    },
    async fetchRecomendationUsers(event){
      return await store.dispatch(COMMUNITY_FETCH_ACTIVITY_USER_COMMENT_RECOMENDATION, {
        page:0,
        limit: 10,
        nickname:event
      })
    },
    async fetchMentionsUnseenPerPage(){
      const mentionedPagesToRemoveClass = document.querySelectorAll('.MentionedPage');

      mentionedPagesToRemoveClass.forEach(element => {
        element.classList.remove('MentionedPage');
      });
      
      await store.dispatch(COMMUNITY_FETCH_ACTIVITY_UNSEEN_MENTION_PER_PAGE, { activityId: this.$route.params.id }).then((data) => {
        this.mentionsCount = data.mentionsPerPage
        const paginations = $('.WatPagination')
        for(let paginationIndex in paginations){
          const pagination = paginations[paginationIndex]
          if(pagination != undefined){
            for(let index in this.mentionsCount){
              if( pagination.children != undefined && pagination.children[this.mentionsCount[index].pageNum+1] != undefined)pagination.children[this.mentionsCount[index].pageNum+1].classList.add('MentionedPage')
            }
          }
        }
      });
    },
    checkActivityRaffleFinished(){
      const userId = this.userProfile.id
      const raffleData = this.communityCurrentActivity.raffleData
      if(raffleData == undefined || raffleData == null) return
      const usersList = raffleData.winnerListNicknames != undefined ? raffleData.winnerListNicknames.join(", ") : null
      if(!this.communityCurrentActivity.showRaffleResult) return
      if(raffleData.raffleFinished && raffleData.winnerList.includes(userId)){
          Swal.fire({
            title: this.$t('redeem_raffle_winner'),
            html:'<img width="350px" src='+raffleData.prizeImage+' /><p style="font-size:30px">'+raffleData.prizeDescription+'</p><br>' + this.$t('redeem_raffle_winner_mod'),
            icon:"success",
            customClass:{popup:"swal-customSuccess"}
          })
        store.dispatch(ACTIVITY_SET_RAFFLE_NOTIFICATED, {activityId: this.$route.params.id})
      }
      else {
        const loserTranslation = usersList.length == 1 ? 'redeem_raffle_loser' : 'redeem_raffle_loser_more'
        Swal.fire({
            title:this.$t(loserTranslation, {users: usersList}),
            text:this.$t('redeem_raffle_loser_2'),
            icon:"info",
            customClass:{popup:"swal-customInfo"}
          })
        store.dispatch(ACTIVITY_SET_RAFFLE_NOTIFICATED, {activityId: this.$route.params.id})
      }
    },
      async init() {
          $(".Activity").scroll(this.handleScroll);

          // Crear un array para almacenar las promesas
          let promises = [];

          // Dependiendo del tipo de actividad, agregar llamadas a la API correspondientes
          if (this.communityCurrentActivity.type == "WAT_POLL") {
              promises.push(this.fetchPollResults());
          }
          if (this.communityCurrentActivity.type == "EL_HEATMAP") {
              promises.push(this.fetchHeatMapResults());
          }
          if (this.communityCurrentActivity.type == "EL_SURVEY") {
              let surveyPromises = [];
              if (this.isUser) {
                  surveyPromises.push(this.checkQuestionsAnswered());
              }
              if (this.isClient) {
                  surveyPromises.push(this.fetchQuestionsResponse());
              }
              promises.push(Promise.all(surveyPromises));
          }

          // Si es cliente, obtener las etiquetas (tags)
          if (this.isClient && (!this.tags || this.tags.length == 0)) {
              promises.push(this.fetchTags());
          }

          // Obtener comentarios, comentarios fijados y menciones no vistas
          // Si hay un comentario de notificación, obtener comentarios específicos
          if (this.$route.params.notificationCommentId) {
              await this.goToNotificationComment();
          } else {
              promises.push(this.fetchComments(0));
          }
          promises.push(this.fetchPinnedComments());
          promises.push(this.fetchMentionsUnseenPerPage());

          // Obtener miembros de la actividad
          const fetchActivityMembersPromise = this.fetchActivityMembers();
          promises.push(fetchActivityMembersPromise);

          // Manejar miembros de la actividad con comentarios
          if (this.isClient) {
              const fetchActivityResultCommentsMembersPromise = this.fetchActivityResultCommentsMembers();
              promises.push(fetchActivityResultCommentsMembersPromise);

              // Después de que ambas promesas se resuelvan
              Promise.all([fetchActivityMembersPromise, fetchActivityResultCommentsMembersPromise]).then(() => {
                  // Combinar miembros sin duplicados
                  const allMembers = this.activityMembersResult.concat(this.activityMembers);
                  this.activityMembersWithComments = allMembers.filter((member, index, self) =>
                      index === self.findIndex((m) => (
                          m.identifier === member.identifier
                      ))
                  );
              });

              // Obtener resultados de la actividad
              promises.push(this.fetchActivityResult());
          } else {
              // Para usuarios no clientes, esperar a que los miembros de la actividad se carguen
              fetchActivityMembersPromise.then(() => {
                  this.activityMembersWithComments = this.activityMembers;
              });
          }

          // Si el tipo de actividad es VIDEOCHAT, obtener detalles de la actividad
          if (this.communityCurrentActivity.type == "VIDEOCHAT") {
              promises.push(this.fetchActivity());
          }

          // Si el tipo de actividad es WAT_UX y es usuario, verificar puntos de control completados
          if (this.communityCurrentActivity.type == "WAT_UX" && this.isUser) {
              promises.push(this.checkCheckpointsCompleted());
          }

          // Esperar a que todas las promesas se resuelvan
          try {
              await Promise.all(promises);
          } catch (error) {
              console.error("Error during initialization:", error);
              // Maneja el error según corresponda
          }
          // Después de que todas las promesas se resuelvan, verificar si hay resultados de rifas
          this.checkActivityRaffleFinished();
      }

  },
  
  async mounted() {
    this.createUserObject();
    this.init();
  },
};
</script>

<style lang="scss">
.Activity {
  &--MediaWrapper {
    display: flex;
    flex-direction: column;
    .MediaItem--VideoTitle {
      width: 32%;
      min-width: 180px;
      margin-right: 5px;
      margin-bottom: 5px;
      min-height: 200px;
      background: var(--primary-color);
    }
    .MediaItem--Video {
      width: 100%;
      height: 160px;
    }
    h4 {
      margin: 10px 0;
    }
    a {
      margin-bottom: 3px;
      color: black;
    }
  }
  &--RaffleInfo{
    width: 100%;
    margin: 10px 0;
    border: 1px solid var(--primary-color);
    padding: 12px 15px;
    position: relative;
    
    i{
      position: absolute;
      opacity: 0.03;
      transform: rotate(45deg);
      right: 0;
      font-size: 170px;
      top: 37px;
    }
    img{
      margin-top: 20px;
      border: 1px solid lightgray;
      max-width: 300px;
      padding: 5px;
    }
    h2{
      font-size: 20px;
      margin-bottom: 10px;
    }
    p{

    }
  }
  &--Description {
    margin-bottom: 10px;
    div {
      ul,
      ol {
        margin-left: 30px;
      }
      .ql-indent-1 {
        margin-left: 35px;
      }
      // -?[_a-zA-Z]+[_a-zA-Z0-9-]*
    }
    img{
      width: -webkit-fill-available;
      max-width: fit-content;
    }
  }
  &--VideoChatUsers {
    p {
      border: 1px solid #f6f6f6;
      background: #f6f6f6;
      padding: 5px;
      margin-top: 10px;
      border-radius: 10px;
      position: relative;
      i {
        color: var(--primary-color);
        cursor: pointer;
      }
    }
  }
}
</style>
<style scoped lang="scss">
.Activity {
  width: 100%;
  &--HeaderImage {
    width: 100%;
    background-color: #e9e9e9;
    border-top: 1px solid #f1f1f1;
    box-shadow: 0 3px 2px #f1f1f1;
    img {
      width: 100%;
    }
  }
  &--Box {
    position: relative;
    padding: 50px!important;
    margin-bottom: 15px;
    @media only screen and (max-width: 600px) {
      padding-top: 50px!important;
      padding-left: 5px!important;
      padding-right: 5px!important;
      padding-bottom: 50px!important;
    
  }
    &.CommentInput {
      padding: 25px 50px;
      z-index: 1;
    }
  }
  &--BoxQuestions {
    padding: 5px;
    margin-bottom: 15px;
  }
  &--InfoBox {
    display: flex;
    flex-direction: column;
  }
  &--CommentsBox {
    padding: 25px;
  }
  &--CommentsActions {
    button {
      font-size: 13px;
      margin-right: 10px;
    }
  }
  &--CommentsHeader {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
  }
  &__Tabs {
    position: absolute;
    top: -32px;
    right: 0;
    justify-content: flex-end;
    overflow: scroll;
    width: -webkit-fill-available;
    &::-webkit-scrollbar {
      display: none;
    }
  }
  &--Progress {
    height: 10px;
    margin: 5px 0 10px 0;
    width: calc(100% - 45px);
  }
  &--DebateTopics {
    margin-top: 10px;
    span {
      display: inline-flex;
      align-items: center;
      background-color: #eee;
      line-height: 1.5em;
      padding: 0 0.6em;
      border-radius: 20px;
      font-size: 12.8px;
      color: white;
    }
  }
  &--Type {
    width: 52px;
    height: 52px;
    position: absolute;
    border: 1px solid white;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    background: linear-gradient(90deg, #ff1564 0%, #fd7328 100%);
    box-shadow: -5px 6px 27.84px 1.16px rgba(0, 0, 0, 0.17);
    top: 50px;
    left: -13px;
  
  }
  &--Info {
    width: 100%;
  }
  &--Header {
    display: flex;
    justify-content: space-between;
    h1 {
      font-size: 25px;
      line-height: 36px;
    }
  }
  &--HeaderInfo {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    @media only screen and (max-width: 768px) {
      h1{
        padding-left: 50px!important;
        font-size: 20px;
      }
    }
  }
  &--MembersNum {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-weight: bold;
    p:first-of-type {
      color: var(--primary-color-text);
      font-size: 40px;
      height: 40px;
    }
    p:last-of-type {
      font-size: 10px;
      letter-spacing: -0.01em;
    }
  }
  &--Status {
    display: flex;
    color: #b8b8b8;
    font-size: 14px;
    align-items: center;
    span {
      border: 1px solid #b8b8b8;
      margin: 0 5px;
      height: 11px;
    }
  }
  &--PollResult {
    display: flex;
    flex-direction: column;
    h1 {
      font-size: 19px;
    }
  }
  &--PollResultInfo {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
    span {
      color: var(--primary-color);
    }
  }
  &--PollAnswers {
    margin-left: 20px;
  }
  &--PollResultProgress {
    width: 100%;
  }
}
.center {
  width: 100%;
  margin-top: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.SearchComments {
  &--Header {
    display: flex;
    align-items: center;
    padding-bottom: 10px;
    h4 {
      margin-right: 10px;
    }
  }
  &--SearchBar {
    border: 1px solid lightgray;
    padding: 0 5px;
    background: white;
  }
  button {
    font-size: 13px;
  }
  &--Select {
    height: 40px;
    margin-top: 10px;
    position: relative;
    select {
      height: 40px;
      -webkit-appearance: none;
      width: 100%;
      padding: 0 5px;
      border: 1px solid lightgray;
      font-size: 15px;
      outline: none;
      &.option-undefined {
        color: #c3c3c3;
      }
      option {
        color: black;
      }
    }
    i {
      position: absolute;
      right: 16px;
      top: 12px;
      color: var(--primary-color);
    }
  }
  &--Result {
    h4 {
      margin: 10px 0;
    }
  }
}
.OpinionAlert {
  text-align: center;
  background-color: rgb(255, 255, 255);
  padding: 1em;
  width: 100%;
  margin-bottom: 10px;
  border-top: 3px solid #f8b656;
  h3 {
    font-size: 25px;
    margin-bottom: 10px;
    color: #f8b656;
  }
}
#printableComments {
  display: none;
}
</style>
