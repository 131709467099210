<template lang="html">
  <div class="MonitorAllFiltersModal">
    <div class="MonitorAllFiltersModal--Box">
      <div>
        <h1>{{$t('watmonitor_filters')}}</h1>
        <h2>{{$t('watmonitor_filter_by_answer')}}</h2>
        <div class="MonitorAllFiltersModal--Scroll">
          <ValuesBox
            v-for="(f,i) in questions"
            :title="getFilteredRealQuestion(f).id+'. '+ getFilteredRealQuestion(f).text"
            :showFilterInput="false"
            :values="f.values"
            :groups="f.groups"
            v-on:openAdvancedOptions="openAdvancedOptions({filter:f,index:i})"
            v-on:selectAllOptions="selectAllOptions($event,f.values)"/>
        </div>
      </div>
      <div class="MonitorAllFiltersModal--Footer">
        <button type="button" name="button" v-on:click="save(questions)">Aplicar filtros</button>
        <button type="button" name="button" v-on:click="close()">Cerrar</button>
      </div>
    </div>
  </div>

</template>
<script>

import ValuesBox from './ValuesBox'
import { inject } from "vue";

export default {
  name: 'MonitorAllFiltersModal',
  components:{
    ValuesBox
  },
  props: {
    filtersQuestion:Array,
    questionsToFilter:Array,
  },
  data() {
    return {
      questions:[],
    }
  },
  methods:{
    getFilteredRealQuestion(filter){
      for(let q of this.questionsToFilter){
        if(filter.taskNum == q.taskNum && filter.questionNum == q.id) return q
      }
    },
    selectAllOptions(selectAll,values){
      let vm = this
      values = values.map(function(v,i){
        v.selected = selectAll
        return v
      })
    },
    openAdvancedOptions(f,i){
      this.$emit('open-advanced-options',{filter:f,index:i})
    }
  },

  async created(){
    this.questions = JSON.parse(JSON.stringify(this.filtersQuestion))
  },
  setup (props, { emit }) {
    const emitter = inject("emitter")
    const close = (event) => {
      emit("close")
      emitter.emit("close-monitor-filters-modal")
    }
    const save = (event) => {
      emit('save', event)
      emitter.emit('save-monitor-filters-modal', event)
    }
    const openAdvancedOptions = (event) => {
      emit('open-advanced-options', event)
      emitter.emit('open-advanced-options', event)
    }

    return {
        close,
        save,
        openAdvancedOptions
    }
  }
}
</script>

<style lang="scss" scoped>
.MonitorAllFiltersModal{
  position: fixed;
  width: 100%;
  height: 100%;
  top:0;
  left:0;
  background: rgba(0,0,0,0.35);
  z-index: 10000;
  display: flex;
  align-items: center;
  justify-content: center;
  &--Scroll{
    margin-top: 20px;
    max-height: calc(100vh - 200px);
    overflow-y: auto;
    padding-right: 10px;
    &::-webkit-scrollbar-track{
      background-color: #e6e6e6;
    }
    &::-webkit-scrollbar {
      background: linear-gradient(90deg, #FF1564 0%, #FD7328 100%);
      width: 5px;
    }
    &::-webkit-scrollbar-thumb{
      border-radius: 20px;
      background: linear-gradient(90deg, #FF1564 0%, #FD7328 100%);
    }
  }
  &--Box{
    background: #FFFFFF;
    padding: 20px 30px;
    width: 60%;
  }
  h1{
    font-family: Lato;
    font-style: normal;
    font-weight: bold;
    font-size: 23px;
    letter-spacing: 0.01em;
    color: #404040;
    margin-bottom: 10px;
  }
  h2{
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 11px;
    letter-spacing: 0.02em;
    text-transform: uppercase;
    color: #939393;
    margin-bottom: 5px;
  }
  &--Footer{
    display: flex;
    justify-content: flex-end;
    margin-top: 10px;
    button{
      box-shadow: 2px 2px 0px rgba(0, 0, 0, 0.14);
      border-radius: 5px;
      height: 37px;
      width: 130px;
      text-transform: uppercase;
      font-family: Roboto Condensed;
      font-style: normal;
      font-weight: bold;
      font-size: 17px;
      text-align: center;
      color: #FFFFFF;
      border: none;
      position: relative;
      &:before {
        content:"";
        position: absolute;
        top: 0px;
        left: 0px;
        width: 0px;
        height: 0px;
        background: rgba(255, 255, 255, 0.2);
        transition: width 0.5s, height 0.5s;
      }
      &:hover:before {
        width: 100%;
        height: 100%;
      }
     &:first-of-type{
       background: #96C800;
       margin-right: 15px;
     }
     &:last-of-type{
       background: #747474;
     }
    }
  }
}
</style>
<style lang="scss">

</style>
