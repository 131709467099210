<template>
  <div id="uxVideosFilter" ref="target">
    <div class="device">
      <p>{{$t('watmonitor_device')}}</p>
      <div class="desktop">
        <input
          type="checkbox"
          name="desktop"
          id="desktop"
          v-model="filter.platformType.DESKTOP"
        />
        <label for="desktop">Desktop</label>
      </div>
      <div class="tablet">
        <input
          type="checkbox"
          name="tablet"
          id="tablet"
          v-model="filter.platformType.TABLET"
        />
        <label for="tablet">Tablet</label>
      </div>
      <div class="mobile">
        <input
          type="checkbox"
          name="mobile"
          id="mobile"
          v-model="filter.platformType.MOBILE"
        />
        <label for="mobile">Mobile</label>
      </div>
    </div>
    <div class="system">
      <p>{{$t('watmonitor_S_O')}}</p>
      <div class="windows">
        <input
          type="checkbox"
          name="windows"
          id="windows"
          v-model="filter.platform.WINDOWS"
        />
        <label for="windows">Windows</label>
      </div>
      <div class="macOS">
        <input
          type="checkbox"
          name="macOS"
          id="macOS"
          v-model="filter.platform.OSX"
        />
        <label for="macOS">MacOS</label>
      </div>
      <div class="linux">
        <input
          type="checkbox"
          name="linux"
          id="linux"
          v-model="filter.platform.LINUX"
        />
        <label for="linux">Linux</label>
      </div>
      <div class="android">
        <input
          type="checkbox"
          name="android"
          id="android"
          v-model="filter.platform.ANDROID"
        />
        <label for="android">Android</label>
      </div>
      <div class="iOS">
        <input
          type="checkbox"
          name="iOS"
          id="iOS"
          v-model="filter.platform.IOS"
        />
        <label for="iOS">iOS</label>
      </div>
    </div>
    <div class="browser">
      <p>{{$t('watmonitor_navigator')}}</p>
      <div class="chrome">
        <input
          type="checkbox"
          name="chrome"
          id="chrome"
          v-model="filter.browser.CHROME"
        />
        <label for="chrome">Chrome</label>
      </div>
      <div class="edge">
        <input
          type="checkbox"
          name="edge"
          id="edge"
          v-model="filter.browser.EDGE"
        />
        <label for="edge">Edge</label>
      </div>
      <div class="firefox">
        <input
          type="checkbox"
          name="firefox"
          id="firefox"
          v-model="filter.browser.MOZILLA"
        />
        <label for="firefox">Firefox</label>
      </div>
      <div class="safari">
        <input
          type="checkbox"
          name="safari"
          id="safari"
          v-model="filter.browser.SAFARI"
        />
        <label for="safari">Safari</label>
      </div>
      <div class="opera">
        <input
          type="checkbox"
          name="opera"
          id="opera"
          v-model="filter.browser.OPERA"
        />
        <label for="opera">opera</label>
      </div>
    </div>
  </div>
</template>
<script setup>
import { ref } from 'vue'
import { onClickOutside } from '@vueuse/core'
const emit = defineEmits(['closeFilterModal'])

const target = ref(null)
onClickOutside(target, (event) => {
  if (event.target !== null && event.target.id !== 'filter') {
    emit('closeFilterModal')
  }
})
const props = defineProps(['filter'])
</script>
<style lang="scss" scoped>
#uxVideosFilter {
  display: flex;
  flex-direction: row;
  gap: 32px;
  width: fit-content;
  height: fit-content;
  background-color: white;
  border: 1px solid #eaeaea;
  border-radius: 4px;
  padding: 32px;
  > div {
    display: flex;
    flex-direction: column;
    gap: 10px;
    > p {
      font-family: 'Bariol';
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      color: #8134ff;
      white-space: nowrap;
    }
    > div {
      display: flex;
      flex-direction: row;
      gap: 8px;
      > label {
        font-family: 'Lato';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        display: flex;
        align-items: center;
        color: #000000;
      }
    }
  }
}
</style>
